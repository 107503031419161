<div class="chat-row px-n3 align-items-center" [class.hide]="chat.hide" *ngFor="let chat of sortedChats()" (click)="select(chat);">
    <div class="avatar-container">
        <sc-avatar [user]="chat?.Users[0]"></sc-avatar>
    </div>
    <div class="content-container">
        <div class="content-header">
            <div class="content-header-title">
                <h4 *ngIf="chat.Users?.length" class="mb-0 oneline">{{ chat.Users[0].DisplayName }}</h4>
                <h4 *ngIf="!chat.Users?.length" class="mb-0 oneline">Chat mit skillconomy</h4>
            </div>
            <div class="content-header-date">
                <sc-chatbot-chat-date [date]="chat.Changed"></sc-chatbot-chat-date>
            </div>
        </div>
        <div class="content-body align-items-center">
            <div class="content-body-text">
                <p class="text-muted mb-0">
                    {{ htmlToPlainText(chat.LastMessage?.Payload?.Body) }}
                </p>
            </div>
            <div class="content-body-badge" *ngIf="chat?.UnreadMessageCount">
                <span class="badge">
                    {{ chat.UnreadMessageCount }}
                </span>
            </div>
        </div>
    </div>
</div>