<div class="comment">
  <div class="row">

    <div class="col-auto">
      <sc-avatar size="sm" [user]="activity.Author"></sc-avatar>
    </div>

    <div class="col ms-n2">
      <div class="comment-header">
        <div class="row">
          <div class="col">
            <sc-activity-item-header [activity]="activity" [showProjectName]="showProjectName"></sc-activity-item-header>
            <ng-content select=".comment-label"></ng-content>
          </div>
        </div>
      </div>

      <div class="comment-body-container" *ngIf="!condensed">
        <ng-content select=".comment-body"></ng-content>
      </div>
    </div>

  </div>
</div>