import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  invalid = false;

  gender = "";

  firstName = "";

  lastName = "";

  eMail = "";

  password = "";

  secret = "";

  error = "";

  target = "/dashboard";

  isLoaded = false;

  isSending: boolean = false;

  constructor(
    private api : ScApiService,
    private route : ActivatedRoute,
    private router : Router) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.secret = params["secret"];

      this.api.get('register/' + this.secret).subscribe((d : any) => {
        
        if (d.IsRegistered)
        {
          var userId = d.Id;
          var isLoggedIn = this.api.userId == userId;
          if (isLoggedIn) {
            console.log('Registered and logged in');
            this.navigate();
            return;
          }
          
          console.log('Registered and not logged in');
          this.router.navigateByUrl('/login?eMail=' + d.EMail + '&target=' + encodeURIComponent(this.target))
          return;          
        }
        
        if (d.IsInvalid)
        {
          this.invalid = true;
        }
        
        this.eMail = d.EMail;
        this.firstName = d.FirstName;
        this.lastName = d.LastName;
        this.gender = d.Gender;
        this.isLoaded = true;
      })
    }); 
    
    this.route.queryParams.subscribe(params => {
      if (params.target) this.target = params.target;
    })
  }

  register() {   

    if (!this.gender)
    {
      this.error = "Bitte geben Sie eine bevorzugte Anrede an";
      return;
    }

    if (!this.firstName)
    {
      this.error = "Bitte geben Sie Ihren Vornamen ein";
      return;
    }

    if (!this.lastName)
    {
      this.error = "Bitte geben Sie Ihren Nachnamen ein";
      return;
    }

    if (!this.eMail)
    {
      this.error = "Bitte geben Sie ein E-Mail-Adresse ein";
      return;
    }

    if (!this.password)
    {
      this.error = "Bitte geben Sie ein Passwort ein";
      return;
    }

    this.isSending = true;

    var args = {
      FirstName : this.firstName,
      LastName : this.lastName,
      EMail : this.eMail,
      Password : this.password,
      Gender: this.gender,
      Secret: this.secret
    }

    this.api.register(args).subscribe(
      (data: any) => {
        
        this.isSending = false;

        if (data.Succeeded) {
            this.navigate();            
        }
        else {
          this.error = data.Error;
        }
      },
      error => {
        console.log(error);

        this.isSending = false;

        if (error.message)
          this.error = error.message;
        else
          this.error = "Ein Fehler ist aufgetreten";
      });   
  }

  navigate() {    

    this.router.navigateByUrl(this.target);
  }

  goToLogin()
  {
    this.router.navigateByUrl('/login?secret=' + this.secret);
  }

}
