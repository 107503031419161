<sc-modal-wizard #briefingModal size="lg">
  <sc-modal-header>
    <i class="fa-sharp fa-solid fa-stars me-2"></i> KI-Briefing
  </sc-modal-header>

  <div class="modal-wizard-body">
    <sc-modal-wizard-page #chatPage>
      <sc-modal-body style="height: 75vh;" class="chat-modal-body">
        <div id="sc-chatbot-container" style="height: 100%; width: 100%;" *ngIf="chat">
          <sc-chatbot-stream *ngIf="chat" #chatbotStream style="z-index: 1000;" theme="light" q delayInitialServerMessage="0" [showHeader]="true" [showInitialLoader]="false" [chat]="chat" (onEndOfChat)="onBriefingComplete($event)">
          </sc-chatbot-stream>
        </div>
      </sc-modal-body>
    </sc-modal-wizard-page>
	<sc-modal-wizard-page #demoPage>
		<sc-modal-body style="height: 75vh;">
			<p>
				Du hast einen Demo-Zugang, mit dem du noch keine neuen Briefings durchführen kannst. 
				Du möchtest noch einmal sehen, wie ein Briefing-Chat abläuft? In den Projektansichten
				findest du unter 'Anforderungen' einen Button 'Briefing'. Dort kannst du dir den
				Chat des jeweiligen Projekts noch einmal anschauen.
			</p>
		</sc-modal-body>
		<sc-modal-footer>
			<button class="btn btn-secondary btn-round" (click)="briefingModal.dismiss()">Ok</button>
		</sc-modal-footer>
	  </sc-modal-wizard-page>
  </div>
</sc-modal-wizard>
