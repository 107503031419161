<div class="row" [scAnchorTarget]="'Einschätzungen'" *ngIf="feedback">
    <div class="col-12 section-title">
        <h3 class="mb-4">Einschätzungen</h3>
    </div>
    <div class="col-12">

        <section class="">

            <p class="mb-5" *ngIf="!feedback?.Items?.length">
                Es liegen noch keine Einschätzungen vor
            </p>

            <div class="row">
                <div class="col-12 col-lg-6 mb-4" *ngFor="let i of feedback.Items">
                    <div class="feedback-card card card-hover mb-0 h-100" (click)="selectedFeedback = i; feedbackModal.show();">
                        <div class="card-body">

                            <div class="row">

                                <div class="col-12">
                                    <sc-star-rating isReadOnly="true" [rating]="i.Ratings[0].Payload.Value"></sc-star-rating>
                                </div>
                                <div class="col">
                                    <!--<h4></h4>-->
                                    <p class="card-text">
                                        {{ i.Ratings[0].Payload.Comment }}
                                    </p>

                                </div>
                            </div>
                        </div>

                        <div class="card-footer pt-0 border-0">
                            <div class="row align-items-center">
                                <div class="col">
                                    <div>
                                        <h4 class="h6 mb-0 d-inline-block">
                                            {{ i.Author.FirstName }} {{ i.Author.LastName }}
                                        </h4>

                                        <time class="d-inline-block small ms-2 text-muted" placement="right">
                                            {{ i.Ratings[0].Timestamp | humanizedDate }}
                                        </time>
                                    </div>

                                </div>
                                <div class="col-auto">

                                    <sc-avatar [user]="i.Author" size="sm"></sc-avatar>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>

        <section>
            <div class="row">  
                
                <div class="col-auto" *ngIf="!feedback.UserHasFeedback">
                    <a class="btn btn-secondary btn-sm" (click)="editFeedback()">
                        Einschätzung abgeben
                    </a>
                </div>  
                <div class="col-auto" *ngIf="feedback.UserHasFeedback">
                    <a class="btn btn-secondary btn-sm" (click)="editFeedback()">
                        Einschätzung bearbeiten
                    </a>
                </div>     

                <div class="col-12 mt-6" *ngIf="assignment.ApplicationStatus == 'None' && !assignment.IsRejected && !assignment.IsAccepted && !assignment.IsRevoked && !assignment.IsHired">                    
                    <app-notification ctaLabel="Jetzt entscheiden" (click)="decide()">
                        Wie soll es mit der Bewerbung weitergehen?
                    </app-notification>
                </div>

                <div class="col-12 mt-6" *ngIf="!hideStatusUpdateNotification && !assignment.IsRejected && assignment.IsAccepted && !assignment.IsRevoked && !assignment.IsHired">                    
                    <app-notification ctaLabel="Jetzt Status aktualisieren" (click)="updateStatus()">
                        Es gibt einen neuen Stand?
                    </app-notification>
                </div>
                           
            </div>            
        </section>

    </div>
</div>

<sc-modal class="feedback-modal" #feedbackModal>

    <sc-modal-header>
        <div class="row align-items-center">
            <div class="col-auto">
                <sc-avatar [user]="selectedFeedback?.Author" size="xs"></sc-avatar>
            </div>
            <div class="col">
                <h4 class="h5 modal-title mb-0">Feedback von {{ selectedFeedback?.Author?.FirstName }} {{ selectedFeedback?.Author?.LastName }}</h4>
            </div>
        </div>
 
    </sc-modal-header>

    <div class="modal-body" *ngIf="selectedFeedback">

        <div *ngFor="let rating of selectedFeedback.Ratings; let i = index;">

            <div class="card mb-3">

              <div class="card-body">

                <div class="row">
                  <!--<div class="col-12">
                      <sc-star-rating isReadOnly="true" [rating]="rating.Payload.Value"></sc-star-rating>
                  </div>-->
                  <div class="col">
                      <p>
                          {{ rating.Payload.Comment }}
                      </p>
                  </div>
              </div>
              <div class="row align-items-center">
                  <div class="col">
                      <div>
                          <time class="d-inline-block small text-muted" placement="right">
                              {{ rating.Timestamp | humanizedDate }}
                          </time>
                      </div>
                  </div>
              </div>
  

              </div>

            </div>
            <!-- <hr *ngIf="i < selectedFeedback.Ratings.length - 1"> -->

        </div>
    </div>

</sc-modal>