<sc-activity-item-simple [activity]="activity" [showProjectName]="showProjectName" *ngIf="condensed">
    hat 
    <a *ngIf="activity.Candidate" routerLink="/projects/{{ activity.Project?.Id }}/candidates/{{ activity.Candidate?.Id }}">
        <strong>{{ activity.Candidate?.FirstName }} {{ activity.Candidate?.LastName }}</strong>        
    </a> 
    <strong *ngIf="!activity.Candidate">eine unbekannte Kandidat:in</strong>
    für ein Kennenlernen ausgewählt
</sc-activity-item-simple>

<sc-activity-item-highlight *ngIf="!condensed" [showProjectName]="showProjectName" icon="fa-sharp fa-solid fa-circle-check text-success" [activity]="activity">
    hat 
    <a *ngIf="activity.Candidate" routerLink="/projects/{{ activity.Project?.Id }}/candidates/{{ activity.Candidate?.Id }}">
        <strong>{{ activity.Candidate?.FirstName }} {{ activity.Candidate?.LastName }}</strong>
    </a> 
    <strong *ngIf="!activity.Candidate">eine unbekannte Kandidat:in</strong>
    für ein Kennenlernen ausgewählt
</sc-activity-item-highlight>
