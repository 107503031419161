<div class="row" [scAnchorTarget]="'Kandidat:innen'" *ngIf="pool">

    <div class="col-12 section-title">
        <h3>Kandidat:innen</h3>
    </div>

    <div class="col-12 col-md-8 col-lg-4">
        <input class="form-control form-control-sm form-control-search mb-4" [(ngModel)]="q" (ngModelChange)="searchSoon()" placeholder="Suchen" />
    </div>

    <div class="col-12">
        <div class="card">
            <div class="list-group list-group-lg list-group-flush list">

                <a *ngFor="let candidate of candidates"
                    class="list-group-item list-group-item-action candidate-list-item">

                    <div class="candidate-row clickable" routerLink="/pools/{{pool.Id}}/candidates/{{candidate.Id}}">
                        <div class="avatar-col">
                            <sc-avatar [user]="candidate"></sc-avatar>
                        </div>
                        <div class="body-col">

                            <h5 class="mb-1 oneline">
                                {{candidate.FirstName}} {{ candidate.LastName }}
                            </h5>

                            <p class="card-text small mb-0 oneline">
                                <span *ngIf="candidate.JobTitle">{{candidate.JobTitle}}</span>
                                <span *ngIf="candidate.JobTitle && candidate.Company">, </span>
                                <span *ngIf="candidate.Company">{{ candidate.Company}}</span>
                                <span *ngIf="!candidate.JobTitle && !candidate.Company" class="text-muted">keine Angaben zur aktuellen Position</span>
                            </p>
                        </div>

                        <div class="status-col">
                            <app-candidate-pool-status-badge [status]="candidate.Assignment.Status"></app-candidate-pool-status-badge>
                        </div>

                        <div class="actions-col" (click)="$event.stopPropagation()">
                            <div ngbDropdown >
                                <div ngbDropdownToggle>
                                    <div class="file me-2 ms-2">
                                        <i class="fa-regular fa-ellipsis"></i>
                                    </div>
                                </div>
                                <div ngbDropdownMenu>
                                    <a ngbDropdownItem (click)="updateStatus(candidate, 'Added')">
                                        Vorgestellt
                                    </a>
                                    <a ngbDropdownItem (click)="updateStatus(candidate, 'Qualified')">
                                        Qualifiziert
                                    </a>
                                    <a ngbDropdownItem (click)="updateStatus(candidate, 'Hired')">
                                        Eingestellt
                                    </a>
                                    <a ngbDropdownItem (click)="updateStatus(candidate, 'Removed')">
                                        Entfernt
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>

                </a>

            </div>

            <div class="row d-flex justify-content-center mt-5 mb-5" *ngIf="hasMoreItems">
                <div class="col-auto">
                  <a class="btn btn-secondary btn-sm" [class.loading]="isLoading" (click)="loadMore()">Weitere laden</a>
                </div>
              </div>
        </div>
    </div>

</div>