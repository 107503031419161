<div class="content">

  <div class="container mb-5">
    <div class="row">
      <div class="col-12">
        <h1 class="heading-serif">Impressum</h1>
      </div>
    </div>
  </div>

  <div class="container">

    <section>
      <h3>skillconomy GmbH</h3>
      <p>
        Werkstättenstr. 18<br>
        51379 Leverkusen
      </p>
      
      <p>
        <a class="phone" href="tel:+4921717767670">+49-2171-77 67 67 0</a><br>
        <a class="email" href="mailto:info@skillconomy.com">info@skillconomy.com</a>
      </p>
    </section>
    
    <section>
      <h3>Geschäftsführer</h3>
      <p>Lars Branscheid, Marc Branscheid, Jonas Krömer<br>
      Amtsgericht Bochum | HRB 15517</p>
    </section>

    <section>
      <h3>Verantwortlich für den Inhalt nach §55 Abs. 2 RStV</h3> 
      <p>Lars Branscheid</p>
    </section>

    <section>
      <h3>1. Haftungsbeschränkung</h3>
      <p>Die Webseite wurde mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite 
        übernimmt dennoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der 
        bereitgestellten Inhalte und Informationen. Die Nutzung der Webseiteninhalte erfolgt 
        auf eigene Gefahr. Allein durch die Nutzung der Website kommt keinerlei Vertragsverhältnis 
        zwischen dem Nutzer und dem Anbieter zustande.</p>
    </section>

    <section>
      <h3>2. Verlinkungen</h3>
      <p>Die Webseite enthält Verlinkungen zu anderen Webseiten ("externe Links"). Diese Webseiten 
        unterliegen der Haftung der jeweiligen Seitenbetreiber. Bei Verknüpfung der externen 
        Links waren keine Rechtsverstöße ersichtlich. Auf die aktuelle und künftige Gestaltung 
        der verlinkten Seiten hat der Anbieter keinen Einfluss. Die permanente überprüfung 
        der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße 
        nicht zumutbar. Bei Bekanntwerden von Rechtsverstößen werden die betroffenen 
        externen Links unverzüglich gelöscht.</p>
    </section>

    <section>
      <h3>3. Urheberrecht / Leistungsschutzrecht</h3>
      <p>Die auf dieser Webseite durch den Anbieter veröffentlichten Inhalte unterliegen dem 
        deutschen Urheberrecht und Leistungsschutzrecht. Alle vom deutschen Urheber- und 
        Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen 
        Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt vor allem für 
        Vervielf#ltigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. 
        Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und 
        Systemen. Dabei sind Inhalte und Rechte Dritter als solche gekennzeichnet. 
        Das unerlaubte Kopieren der Webseiteninhalte oder der kompletten Webseite ist 
        nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads 
        für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.</p>

      <p>Diese Website darf ohne schriftliche Erlaubnis nicht durch Dritte in Frames 
        oder iFrames dargestellt werden.</p>
    </section>

  </div>

</div>





