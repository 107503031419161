import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfettiService, EventService, ScApiService } from '@sc-ui';
import { AppChatsService } from 'src/services/app-chats.service';
import { NotificationsService } from 'src/services/notifications.service';
import { NotificationsModalComponent } from '../notifications-modal/notifications-modal.component';
import { UserService } from 'src/services/user.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {  

  projectCount = 1;

  poolCount = 0;

  @ViewChild(NgbDropdown)
  notificationsDropdown: NgbDropdown;

  @ViewChild(NotificationsModalComponent)
  notificationsModal: NotificationsModalComponent;

  constructor(
    public notifications: NotificationsService,
    public userService: UserService,
    public appChats : AppChatsService,
    private api: ScApiService,
    private events: EventService,    
    private confetti: ConfettiService,
    private router: Router
    ) { }

  ngOnInit() {

    this.notifications.load();

    this.events.channel('users/' + this.api.userId + '/ui').subscribe((e: any) => {
      console.log(e);
      if (e.Name == 'navigate') {
        var route = e.Payload.Route;
        this.router.navigateByUrl(route);
      }
      if (e.Name == 'confetti') {
        this.confetti.start(5);
      }
    });

    this.api.get('projects/count')
      .subscribe((p : any) => {
        this.projectCount = p.Total;
      });

      this.api.get('pools/count')
      .subscribe((p : any) => {
        this.poolCount = p.Total;
      });
  }

  showNotifications() {
    this.notifications.showModal();
  }

  logout() {
    this.api.logout().subscribe();
    this.events.unsubscribeAll();
    this.appChats.stop();
    this.router.navigateByUrl('/login');
  }
}
