import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventService } from '@sc-ui';

@Component({
  selector: 'sc-chatbot-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent {

  @Input()
  chats: any = [];

  @Input()
  selected: any;

  @Output()
  selectedChange = new EventEmitter<any>();

  constructor(private events: EventService) { }

  select(chat) {
    this.selected = chat;
    this.selectedChange.next(chat);
  }

  sortedChats() {
    if (!this.chats) return [];
    
    return this.chats.sort((chat1, chat2) => {

      var date1 = new Date(chat1.LastMessage?.Timestamp);
      var date2 = new Date(chat2.LastMessage?.Timestamp);

      let same = date1.getTime() === date2.getTime();
      if (same) return 0;

      // Check if the first is greater than second
      if (date1 > date2) return -1;

      // Check if the first is less than second
      if (date1 < date2) return 1;
    })
  }

  htmlToPlainText(text: string ) {

    if (!text) { return ''; }

    var result = 
      text.replace(/<(?:.|\n)*?>/gm, ' ')
      .replace(/ +(?= )/g,'')
      .replace(/&nbsp;/gi," ") 
      .replace(/(?:(?:\r\n|\r|\n)\s*){2,}/gim, " ");
    
    return result;
}


}
