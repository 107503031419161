<div id="backdrop" class="sc-chatbot {{ view }}" [class.is-active]="isActive" #chatbotOverlay (click)="hide()">

    <div id="overlay" (click)="$event.stopPropagation()">
        <div id="overview">

            <sc-chatbot-chat-list-header [shadow]="!overViewScrolledToTop" [showHide]="true" (hide)="hide()"></sc-chatbot-chat-list-header>

            <div #chatList class="chat-list-container" (scroll)="checkOverviewScroll(chatList)">
                <input placeholder="Suchen" class="form-control search-input" />
                <sc-chatbot-chat-list [chats]="appChats.chats" (selectedChange)="showDirectChat($event)"></sc-chatbot-chat-list>
            </div>
        </div>

        <div id="chat">
            <sc-chatbot-chat-header [chat]="selectedChat" [isTitleClickable]="canNavigateToCandidate" (titleClick)="navigateToCandidate()" [showHide]="true" (hide)="hide()" [showBack]="true" (back)="showOverview()"></sc-chatbot-chat-header>
            <sc-chatbot-stream #candidateChatStream *ngIf="selectedChat" [chat]="selectedChat"></sc-chatbot-stream>
        </div>

        <div id="chatbot">
            <sc-chatbot-chat-header [chat]="botChat" [showHide]="true" (hide)="hide()" [showBack]="false"></sc-chatbot-chat-header>
            <sc-chatbot-stream #botChatStream *ngIf="botChat" style="z-index: 1000;" [showHeader]="false" [chat]="botChat"> </sc-chatbot-stream>
        </div>
    </div>
</div>

<div id="avatar-list" [class.trigger-active]="chatbotTrigger?.show">
    <div class="avatar-container" [class.show]="chat.show" *ngFor="let chat of appChats.activeChats">   
        <sc-avatar (click)="showDirectChat(chat)" [user]="chat.Users[0]">
        </sc-avatar>     
        <div class="badge" *ngIf="chat.UnreadMessageCount > 0">{{ chat.UnreadMessageCount }}</div>
    </div>
</div>

<sc-chatbot-intelligence-trigger-button #chatbotTrigger (click)="toggleChatbot()"></sc-chatbot-intelligence-trigger-button>