import { AfterContentInit, AfterViewInit, Component, ContentChildren, ElementRef, EventEmitter, Host, Input, OnChanges, OnInit, Output, QueryList } from '@angular/core';
import { NgModel } from '@angular/forms';
import { debug } from 'console';
import { AutosizeTextareaComponent } from '../autosize-textarea/autosize-textarea.component';
import { DatepickerInlineComponent } from '../date-picker-inline/date-picker-inline.component';

@Component({
  selector: 'sc-form-row',
  templateUrl: './form-row.component.html',
  styleUrls: ['./form-row.component.scss']
})
export class FormRowComponent implements OnInit, AfterContentInit, OnChanges {

  @Input()
  label = '';

  private _lastDisabledState = false;

  @Input()
  size: 'sm' | 'md' | 'lg' = 'md';

  @Input()
  disabled = false;

  formDisabled = true;

  @Output()
  change = new EventEmitter<any>();

  @ContentChildren(NgModel, { descendants: true })
  models: QueryList<NgModel>;

  @ContentChildren(AutosizeTextareaComponent, { descendants: true })
  autosizeTextareas: QueryList<AutosizeTextareaComponent>;

  @ContentChildren(DatepickerInlineComponent, { descendants: true })
  datePickers: QueryList<DatepickerInlineComponent>;

  form: 'horizontal' | 'default' = 'default';

  labelMaxLength = 20;

  constructor(private host: ElementRef) { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.models.forEach(m => m.update.subscribe(() => {
      this.change.next();
    }));

    this.autosizeTextareas.forEach(m => {
      m.textChange.subscribe(() => {
        this.change.next();
      });
    });

    this.datePickers.forEach(m => {
      m.dateChange.subscribe(() => {
        this.change.next();
      });
    });

    this.updateSizes();
  }

  ngOnChanges(changes: any) {
    if (changes.disabled) {
      this.updateDisabledState();
    }

    if (changes.size) {
      this.updateSizes();
    }
  }

  public updateSizes() {
    this.autosizeTextareas.forEach(m => {
      m.size = this.size;
    });

    this.datePickers.forEach(m => {
      m.size = this.size;
    });
  }

  public updateDisabledState() {

    var disabledState = this.disabled || this.formDisabled;
    var disabledStateChanged = this._lastDisabledState != disabledState;
    if (!disabledStateChanged) return;

    this._lastDisabledState = disabledState;

    setTimeout(() => {
      var disableChildrenRecursive = (node: any) => {
        var nodes = node.getElementsByTagName('*');
        for (var i = 0; i < nodes.length; i++) {
          if (disabledState) {
            nodes[i].setAttribute('disabled', true);
          }
          else {
            nodes[i].removeAttribute('disabled');
          }

          disableChildrenRecursive(nodes[i]);
        }
      }
      var nativeHost = this.host.nativeElement;
      disableChildrenRecursive(nativeHost);
    });
  }
}