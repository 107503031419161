import { animate, state, style, transition, trigger } from "@angular/animations";

   // the fade-in/fade-out animation.
 export const fadeIn =  trigger('fadeIn', [

    // the "in" style determines the "resting" state of the element when it is visible.
    state('in', style({opacity: 1})),

    state('out', style({opacity: 0})),

    // fade in when created. this could also be written as transition('void => *')
    transition('out => in', [
      style({opacity: 0}),
      animate('600ms ease-in')
    ]),

    // fade out when destroyed. this could also be written as transition('void => *')
    transition(':leave',
      animate(400, style({opacity: 0})))
  ])
  
