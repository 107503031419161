<div class="row" [scAnchorTarget]="'Skills'" *ngIf="candidate">
  <div class="col-12 section-title">
    <h3 class="mb-5">Skills</h3>
  </div>
  <div class="col-12">

    <section>
      <p *ngIf="!candidate.Skills?.length">
        Es liegen keine Angaben vor
      </p>
      <ul class="tag-list list-inline mb-0">
        <li class="list-inline-item" *ngFor="let tag of candidate.Skills"><span class="tag tag-clip-text tag-light">{{tag}}</span></li>
      </ul>
    </section>

  </div>
</div>
