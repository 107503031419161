<sc-modal-wizard #modal>
    <sc-modal-header>
        <h5 class="modal-title mb-0">
            <i class="fa-sharp fa-solid fa-alarm-clock me-3"></i>
            <span *ngIf="modal.currentPage == overviewPage">Erinnerungen</span>
            <span *ngIf="modal.currentPage == editPage && selectedReminder?.isNew">Erinnerungen einstellen</span>
            <span *ngIf="modal.currentPage == editPage && !selectedReminder?.isNew">Erinnerung bearbeiten</span>
        </h5>
    </sc-modal-header>

    <div class="modal-wizard-body">
        <sc-modal-wizard-page #overviewPage>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12" *ngIf="!reminders?.length">
                        <p>Es wurden noch keine Erinnerungen eingestellt</p>
                    </div>
                    <div class="col-12" *ngFor="let reminder of reminders">
                      <app-candidate-base-reminder [reminder]="reminder" (click)="select(reminder)"></app-candidate-base-reminder>
                    </div>
                  </div>                
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary btn-sm" (click)="new()">Neue Erinnerung einstellen</button>
            </div>
        </sc-modal-wizard-page>

        <sc-modal-wizard-page [definesHeight]="true" #editPage>
            <div class="modal-body">

                <div class="row mb-5">
                    <div class="col-auto">
                        <sc-date-picker [(date)]="selectedReminder.Due" (dateChange)="save()" [minDate]="minDate" [startDate]="selectedReminder.Due"></sc-date-picker>
                    </div>
                    <div class="col d-none d-md-block">
                        <button class="btn btn-block btn-sm btn-secondary d-block mb-3" (click)="setDateInMonth(selectedReminder, 1); save();">in 1 Monat</button>
                        <button class="btn btn-block btn-sm btn-secondary d-block mb-3" (click)="setDateInMonth(selectedReminder, 2); save();">in 2 Monaten</button>
                        <button class="btn btn-block btn-sm btn-secondary d-block mb-3" (click)="setDateInMonth(selectedReminder, 3); save();">in 3 Monaten</button>
                        <button class="btn btn-block btn-sm btn-secondary d-block mb-3" (click)="setDateInMonth(selectedReminder, 6); save();">in 6 Monaten</button>
                        <button class="btn btn-block btn-sm btn-secondary d-block mb-3" (click)="setDateInMonth(selectedReminder, 12); save();">in 1 Jahr</button>
                    </div>
                </div>

                <div class="row mb-5">
                    <div class="col-12">
                        <label class="form-label">Anmerkungen</label>
                    </div>
                    <div class="col-12">
                        <input class="form-control" [(ngModel)]="selectedReminder.Description" (ngModelChange)="saveSoon()" />
                    </div>
                </div>

                <div class="row mb-5">
                    <div class="col-12">
                        <label class="form-label">Wer soll erinnert werden?</label>
                    </div>
                    <div class="col-12">
                        <div class="avatar-container me-1" *ngFor="let user of selectedReminder.Users">
                            <sc-avatar [user]="user"></sc-avatar>
                            <div class="delete-user-hover bg-negative-base" (click)="removeUser(user)">
                              <i class="fa-sharp fa-solid fa-xmark text-white fa-2xs"></i>
                            </div>
                        </div>

                        <button class="btn btn-secondary btn-circle btn-sm" (click)="modal.navigateTo(addUserPage)">
                            <i class="fa-sharp fa-solid fa-plus fa-xs"></i>
                        </button>
                    </div>
                </div>

            </div>

            <div class="modal-footer">
              <button *ngIf="!selectedReminder.isNew" class="btn btn-secondary btn-sm" (click)="delete()">Löschen</button>
              <button class="btn btn-dark btn-sm" (click)="modal.navigateTo(overviewPage)">Zur Übersicht</button>
              <button *ngIf="selectedReminder.isNew" class="btn btn-primary btn-sm" [disabled]="!selectedReminder.Description || !selectedReminder.Due" (click)="create()">Erinnerung einstellen</button>
            </div>
        </sc-modal-wizard-page>

        <sc-modal-wizard-page #addUserPage>
            <div class="modal-body">
                <sc-user-list-selection *ngIf="candidate" [users]="selectedReminder.Users" (addUser)="addUser($event);" uri="candidates/{{candidate?.Id}}/users" loadInitially="true"></sc-user-list-selection>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary btn-sm" (click)="modal.navigateTo(editPage)">Fertig</button>
            </div>
        </sc-modal-wizard-page>

    </div>
</sc-modal-wizard>