import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { ChatsService } from '@sc-ui-chatbot';
import { AppChatsService } from 'src/services/app-chats.service';

@Component({
  selector: 'app-candidate-base',
  templateUrl: './candidate-base.component.html',
  styleUrls: ['./candidate-base.component.scss']
})
export class CandidateBaseComponent implements OnChanges {

  @Input()
  candidate: any;

  @Input()
  project: any;

  assignment : any;

  dueRemindersCount = 0;

  reminders : any = [];

  isLoadingDirectChat = true;

  directChat: any;

  constructor(private api: ScApiService, private chatsService : ChatsService, private chatbot: AppChatsService) { }

  ngOnChanges() {
    if (!this.candidate) return;

    this.assignment = this.candidate.Assignments.find(a => a.Project.Id == this.project.Id);

    this.api.get(`candidates/${this.candidate.Id}/reminders`).subscribe(d => {
      this.reminders = d;

      var now = new Date();
      var dueCount = 0;
      this.reminders.forEach(r => {
        var due = new Date(r.Due);
        if (due < now) dueCount++;
      });

      this.dueRemindersCount = dueCount;
      console.log(dueCount);
    })
  }

  updateStatusChat() {
    this.chatbot.candidateStatusChat(this.project.Id, this.candidate.Id);
  }

  startDirectChat() {
    this.chatbot.candidateDirectChat(this.candidate.Id);
  }

}
