import { Component, OnInit, Input, ViewChild, ContentChild, AfterViewInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Subject } from 'rxjs';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';

@Component({
  selector: 'sc-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ScuiModalComponent implements OnInit, AfterViewInit {

  @Input()
  size: 'sm' | 'lg' | 'xl' = null;

  onResult = new Subject<any>();

  @ViewChild('modal')
  modal: any;

  @ContentChild(ModalHeaderComponent)
  header: ModalHeaderComponent;

  isOpen = false;

  modalRef: NgbModalRef;

  constructor(private modals: NgbModal) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.header) this.header.closeClick.subscribe(() => this.dismiss());
  }

  show(): Subject<any> {


    this.onResult = new Subject<any>();

    if (this.modals.hasOpenModals)
      this.modals.dismissAll();

    this.modalRef = this.modals.open(this.modal, { size: this.size, centered: true, windowClass: 'modal-window' });
    
    this.modalRef.closed.subscribe((r: any) => {
      this.onResult.next(r);
      this.onResult.complete();
      this.isOpen = false;
    });

    this.modalRef.dismissed.subscribe((r : any) => {
      this.onResult.complete();
      this.isOpen = false;
    });

    this.isOpen = true;

    return this.onResult;
  }

  complete(result: any) {
    if (this.modalRef) this.modalRef.close(result);
  }

  dismiss() {
    if (this.modalRef) this.modalRef.dismiss();    
  }
}
