import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-forgot-pw',
  templateUrl: './forgot-pw.component.html',
  styleUrls: ['./forgot-pw.component.scss']
})
export class ForgotPwComponent implements OnInit {

  isResetting = false;

  eMail = '';

  resetError = false;

  resetSuccess = false;

  constructor(private api: ScApiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.eMail) this.eMail = params.eMail;
    })
  }

  reset() {

    this.resetError = false;
    this.isResetting = true;

    var arg = {
      EMail: this.eMail
    };

    this.api.post('account/resetpassword', arg).subscribe((d: any) => {
      
      this.isResetting = false;

      if (d.Succeeded) {
        this.resetSuccess = true;        
      }
      else {
        if (d.IsRegistered == false && d.CanRegister) {
          this.router.navigateByUrl(`/register-send-link?eMail=${this.eMail}`);
          return;
        }

        if (d.IsRegistered == false && d.CanRegister == false) {
          this.router.navigateByUrl(`/register-forbidden?eMail=${this.eMail}`);
          return;
        }

        this.resetError = true;   
      }


    },
      e => {
        this.resetError = true;
        this.isResetting = false;
      })
  }

  goToLogin() {
    this.router.navigateByUrl(`/login?eMail=${this.eMail}`);
  }

}
