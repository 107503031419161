
<app-topbar></app-topbar>


<main>
  <router-outlet #outlet></router-outlet>
</main>

<app-footer></app-footer>

<app-candidate-chats></app-candidate-chats>