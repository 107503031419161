import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[scOnEnter]'
})
export class ScOnEnterDirective {

  @Output("scOnEnter")
  scOnEnter: EventEmitter<any> = new EventEmitter();

  @HostListener('keyup', [ "$event" ])
  onKeyUp(ev) {
    if (ev.key !== "Enter") return;
    if (!this.scOnEnter) return;
    this.scOnEnter.emit(ev);
  }
  
  constructor(el: ElementRef) {}
}
