<sc-activity-item-simple [activity]="activity" [showProjectName]="showProjectName" *ngIf="condensed">
    hat die Jobsite für kommentiert
</sc-activity-item-simple>

<sc-activity-item-comment [activity]="activity" [showProjectName]="showProjectName" *ngIf="!condensed">
    <div class="comment-label">
        <p class="mb-0 activity">
            hat die Jobsite
            <span *ngIf="context?.Project?.Id != activity.Project?.Id">
                für 
                <a routerLink="/projects/{{ activity.Project.Id }}">
                    <strong>{{ activity.Project.Name }} m|w|d</strong>
                </a>
            </span>
             kommentiert
        </p>
    </div>
    <div class="comment-body">
        <p [innerHTML]="activity.Payload.Body"></p>
    </div>
</sc-activity-item-comment>