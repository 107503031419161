import { Component, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {

  constructor(private api : ScApiService) { }

  ngOnInit() {
  }

}

