import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sc-chatbot-answer-file-upload',
  templateUrl: './answer-file-upload.component.html',
  styleUrls: ['./answer-file-upload.component.css']
})
export class ScChatbotAnswerFileUploadComponent implements OnInit {

  @Input()
  message : any;
  
  constructor() { }

  ngOnInit() {
  }

}
