/// <reference types="@types/googlemaps" />

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'sc-chatbot-answer-map',
  templateUrl: './answer-map.component.html',
  styleUrls: ['./answer-map.component.scss']
})
export class ScChatbotAnswerMapComponent implements OnInit {

  @Input()
  message: any;

  @ViewChild('gmap', { static: true }) gmapElement: any;


  constructor(private mapsApiLoader: MapsAPILoader) { }

  ngOnInit() {

    this.mapsApiLoader.load().then(() => {

      var location = new google.maps.LatLng(this.message.Payload.Latitude, this.message.Payload.Longitude);
      var radius = this.message.Payload.Radius;

      var mapOptions = {
        zoom: 13,
        center: location,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true
      };

      var map = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);

      if (radius) {
        var cityCircle = new google.maps.Circle({
          strokeColor: '#0065a4',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#0065a4',
          fillOpacity: 0.35,
          map: map
        });

        cityCircle.setCenter(location);
        cityCircle.setRadius(radius * 1000);

        var bounds = cityCircle.getBounds();
        map.panTo(location);
        map.fitBounds(bounds, 0);
      }
    });
  }

}
