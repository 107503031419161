import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-candidate-activities',
  templateUrl: './candidate-activities.component.html',
  styleUrls: ['./candidate-activities.component.scss']
})
export class CandidateActivitiesComponent implements OnInit, OnChanges {

  @Input()
  candidate: any;

  @Input()
  project: any;

  @Input()
  pool: any;

  activitiesUri: string;

  mentionsUri: string;

  isReadOnly = false;

  constructor(public userService: UserService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (!this.candidate) return;

    if (!this.project && !this.pool) 
    {
      this.isReadOnly = true;
    }

    if (this.project) {
      this.isReadOnly = false;
      this.activitiesUri = `projects/${this.project.Id}/candidates/${this.candidate.Id}/activities`;
      this.mentionsUri = `projects/${this.project.Id}/mentions`;
    }
    else if (this.pool) {
      this.isReadOnly = false;
      this.activitiesUri = `pools/${this.pool.Id}/candidates/${this.candidate.Id}/activities`;
      this.mentionsUri = `pools/${this.pool.Id}/mentions`;
    }
  }

}
