import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import { ScApiService } from 'refs/sc-ui/services/api.service';

@Component({
  selector: 'sc-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.scss']
})
export class ActivityFeedComponent implements OnInit, OnChanges {

  @ContentChild(TemplateRef)
  itemTemplate: TemplateRef<any>;

  @Input()
  user: any;

  @Input()
  pageSize = 10;

  @Input()
  readOnly = false;

  @Input()
  canLoadMore = true;

  @Input()
  uri;

  @Input()
  mentionsUri;

  @Input()
  headline = 'Kommentare und Aktivitäten';

  @Input()
  condensed = true;

  page = 1;

  activities: any = [];

  mentionItems: any = [];

  @Output()
  submitted = new EventEmitter<any>();

  @Output()
  itemsChanged = new EventEmitter<any>();

  isLoading = false;

  hasMoreItems = true;

  isSending = false;

  input = '';

  inputPlaceholder = 'Kommentieren';

  mentionConfig = {
    triggerChar: '@',
    labelKey: 'FirstName',
    dropUp: true,
    mentionSelect: (item: any) => {
      return item.InsertionValue;
    }
  }

  constructor(private api: ScApiService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any) {
    if (changes.uri && changes.uri != this.uri) {
      this.reloadItems();
    }

    if (changes.mentionsUri && changes.mentionsUri != this.mentionsUri) {
      this.loadMentionItems();
    }
  }

  submit() {

    this.isSending = true;

    var activity = {
      Content: '#commented',
      Payload: {
        Body: this.input
      }
    };

    this.api.post(this.uri, activity).subscribe(d => {

      this.isSending = false;
      this.activities.splice(0, 0, d);

      if (this.activities.length >= this.pageSize)
        this.activities.splice(this.activities.length - 1, 1);
      this.input = '';

      this.itemsChanged.emit(this.activities);
    });

    this.submitted.emit(this.input);
  }

  reloadItems() {
    this.loadItems(true);
  }

  loadItems(clear = false) {

    if (!this.uri) return;

    if (clear)
    {
      this.page = 1;
    }

    this.isLoading = true;
    var page = this.page;
    var pageSize = this.pageSize;

    var uri = `${this.uri}?page=${page}&pageSize=${pageSize}`;

    this.api.get(uri).subscribe((d: any) => {
      if (clear)
      {
        this.activities = [];
      }
      
      this.activities.push.apply(this.activities, d.Items);
      this.isLoading = false;
      this.hasMoreItems = d.TotalCount > this.activities.length;
      this.page++;
      this.itemsChanged.emit(this.activities);
    })
  }

  loadMentionItems() {
    if (!this.mentionsUri) return;
    this.api.get(this.mentionsUri).subscribe((d: any) => {
      this.mentionItems = d;
      if (this.mentionItems?.length) this.inputPlaceholder = '@ eingeben, um Personen zu erwähnen'
    });
  }

}
