<div class="container px-0 h-100">
	<div class="row h-100 align-items-center" *ngIf="!isUploading">
		<div class="col"> 
			<small class="text-danger d-block mt-3 fw-bold text-center text-sm" *ngIf="fileTooLarge==true">
				Die ausgewählte Datei ist zu groß. Bitte wählen Sie eine Datei mit Dateigröße kleiner als
				{{ message.Payload.MaximumFileSize / 1000 | number:'1.0-0' }} MB.
			</small>
		</div>
		<div class="col-auto">
			<sc-file-button (filesChanged)="upload($event)" #fileinput [accept]="message.Payload.FileType">
				<div class="btn btn-secondary">
					<span *ngIf="message.Payload.Label">{{ message.Payload.Label }}</span>
					<span *ngIf="!message.Payload.Label">Datei auswählen</span>
				</div>
			</sc-file-button>
		</div>
	</div>
	<div class="row h-100 align-items-center" *ngIf="isUploading">
		<div class="col-12">
			<sc-progressbar [value]="uploadProgress" [success]="uploadSuccess" [error]="uploadError" (retryClick)="retryAfterError()"></sc-progressbar>
		</div>
	</div>
</div>