<div class="row align-items-center mb-4" *ngFor="let user of users">

    <div class="col-auto">
        <div class="avatar-with-badge" [class.is-project-manager]="user.IsManager" [class.is-moderator]="user.IsAdmin">
            <sc-avatar [user]="user"></sc-avatar>
            <span class="badge badge-pill badge-dark" *ngIf="user.IsManager">PM</span>
            <span class="badge badge-pill badge-dark" *ngIf="user.IsAdmin && !user.IsDecider">M</span>
            <span class="badge badge-pill badge-dark" *ngIf="user.IsDecider && !user.IsAdmin">E</span>
            <span class="badge badge-pill badge-dark" *ngIf="user.IsDecider && user.IsAdmin">E/M</span>
        </div>
    </div>

    <div class="col ms-n2 user-name-email">
        <h6 class="mb-0 user-name" *ngIf="user.FirstName || user.LastName">{{ user.FirstName }} {{ user.LastName }}
            <span *ngIf="user.IsManager" class="small text-muted fw-normal"> (Projektmanager)</span>
            <span *ngIf="user.IsAdmin && !user.IsDecider" class="small text-muted fw-normal"> (Moderator)</span>
            <span *ngIf="user.IsDecider && !user.IsAdmin" class="small text-muted fw-normal"> (Entscheider)</span>
            <span *ngIf="user.IsDecider && user.IsAdmin" class="small text-muted fw-normal"> (Entscheider & Moderator)</span>
        </h6>
        <h6 class="mb-0 user-name" *ngIf="!user.FirstName && !user.LastName">Nicht registriert</h6>
        <p class="mb-0 user-mail small text-muted">{{ user.EMail }}</p>
    </div>

    <div class="col-auto" *ngIf="user.canRemove && user.isAdded">
        <button class="btn btn-secondary btn-sm btn-circle" title="Teilnehmer entfernen" [disabled]="user.isRemoving" (click)="remove(user)">
            <span class="icon" *ngIf="!user.isRemoving">
                <i class="fa-sharp fa-regular fa-xmark"></i>
            </span>
            <span *ngIf="user.isRemoving" class="spinner-border spinner-border-sm"></span>
        </button>
    </div>

    <div class="col-auto" *ngIf="user.canAdd && !user.isAdded">
        <button class="btn btn-secondary btn-sm btn-circle" (click)="add(user)" [disabled]="user.isAdding">
            <span class="icon" *ngIf="!user.isAdding">
                <i class="fa-sharp fa-solid fa-user-plus"></i>
            </span>
            <span *ngIf="user.isAdding" class="spinner-border spinner-border-sm"></span>
        </button>
    </div>

    <div class="col-auto" *ngIf="!user.canRemove && user.isAdded">       
        <button class="btn btn-dark btn-sm btn-circle" *ngIf="user.isAdded">
            <span class="icon">
                <i class="fa-sharp fa-solid fa-check"></i>
            </span>
        </button>
    </div>

</div>