<div class="row">
    <div class="col-auto pe-0">
        <div class="admin-manager-container">
            <app-admin-manager-avatars (avatarClick)="modal.show(); modal.navigateTo(usersPage, 'none');" [users]="poolUsers"></app-admin-manager-avatars>
        </div>
    </div>

    <div class="col-auto user-menu pl-2 pe-0">

        <button class="btn btn-secondary btn-sm" (click)="modal.show(); modal.navigateTo(addUserPage, 'none');">
            <i class="fa-sharp fa-solid fa-user-plus me-2"></i> Einladen
        </button>

    </div>
</div>

<sc-modal-wizard #modal>
    <sc-modal-header>
        <h5 class="modal-title mb-0">
            <i class="fa-sharp fa-solid fa-users me-3"></i>
            Teilnehmer
        </h5>
    </sc-modal-header>

    <div class="modal-wizard-body">

        <sc-modal-wizard-page #usersPage>
            <div class="modal-body">
                <sc-user-list [users]="poolUsers" (removeUser)="removeUser($event);"></sc-user-list>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary btn-sm" (click)="modal.navigateTo(addUserPage)"><i class="fa-sharp fa-solid fa-user-plus me-2"></i> Teilnehmer einladen</button>
            </div>
        </sc-modal-wizard-page>

        <sc-modal-wizard-page [definesHeight]="true" #addUserPage>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <input class="form-control form-control-search mb-4" [(ngModel)]="q" (ngModelChange)="searchSoon()" placeholder="Name oder E-Mail" />
                    </div>
                    <div class="col-12 user-list-container">
                        <button *ngIf="showAddEMail" (click)="addByEMail()" [disabled]="isAddingByEMail" class="btn btn-block btn-primary">
                            <i class="fa-sharp fa-solid fa-user-plus me-2" *ngIf="!isAddingByEMail"></i> 
                            <span *ngIf="isAddingByEMail" class="spinner-border spinner-border-sm"></span>
                            Neuen Teilnehmer einladen
                        </button>

                        <p *ngIf="!isSearching && !users?.length && !showAddEMail" class="text-muted">
                            Es wurde kein Benutzer gefunden. Bitte versuchen Sie es erneut oder geben Sie eine gültige E-Mail-Adresse an, um einen neuen Teilnehmer einzuladen.
                        </p>

                        <sc-user-list [users]="users" (removeUser)="removeUser($event);" (addUser)="addUser($event);"></sc-user-list>
                    </div>
                </div>
                
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary btn-sm" (click)="modal.navigateTo(usersPage)">Zur Übersicht</button>
            </div>
        </sc-modal-wizard-page>

    </div>
</sc-modal-wizard>