import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

declare var gsap: any;

@Component({
  selector: 'sc-chatbot-intelligence-trigger-button',
  templateUrl: './intelligence-trigger-button.component.html',
  styleUrls: ['./intelligence-trigger-button.component.scss']
})
export class IntelligenceTriggerButtonComponent implements OnInit, AfterViewInit {


  @ViewChild("chatbotTrigger", { static: true })
  trigger: ElementRef;

  public state: 'chat' | 'close' = 'chat';

  public isPulsating = false;

  public badge: number = 0;

  public show = false;

  constructor() { }

  ngOnInit() {
  }

  slideIn() {
    this.show = true;
  }

  slideOut() {
    this.show = false;
  }

  startPulse() {
    this.isPulsating = true;
  }

  stopPulse() {
    this.isPulsating = false;
  }

  ngAfterViewInit() {

    if (!gsap)
    {
      console.error('gsap is not defined. Add the library via npm or as files and add to "scripts" section of angular.json');
    }

    // @benni: Gibt es eine gsap Abfrage, ob das plugin registriert ist?
    // @marc: !window.MorphSVGPlugin müsste klappen
    if (!window["MorphSVGPlugin"])
    {
      console.error('gsap morphSVG plugin does not exist. Please add plugin file to  "scripts" section of angular.json');
    }

    var blobAnim = gsap.timeline({ repeat: -1 });
    var botAnim = gsap.timeline({ repeat: -1 });

    var blob = document.querySelector('#chatbot-blob-trigger .blob path');
    var eyes = document.getElementById('eyes');
    var eyesOpen = document.getElementById('eyes-open');

    blobAnim
      .to(blob, { duration: .9, morphSVG: "M54.1,-64.2C70.1,-51,83.2,-34,86.3,-15.3C89.5,3.3,82.7,23.6,72.3,41.7C61.8,59.9,47.6,76,29.9,82.7C12.1,89.4,-9.2,86.7,-29.2,79.7C-49.1,72.6,-67.6,61.2,-77.6,44.5C-87.5,27.9,-88.8,6,-84.8,-14.7C-80.9,-35.4,-71.7,-55,-56.7,-68.4C-41.7,-81.8,-20.8,-89.1,-0.9,-88C19,-87,38,-77.5,54.1,-64.2Z" })
      .to(blob, { duration: .9, morphSVG: "M51,-69.2C66.5,-59,79.5,-44.6,85.9,-27.4C92.2,-10.1,91.8,9.8,85,26.6C78.2,43.5,64.9,57.3,49.7,68.8C34.4,80.2,17.2,89.4,-0.7,90.4C-18.7,91.4,-37.3,84.2,-52.6,72.8C-67.8,61.3,-79.7,45.5,-86.1,27.6C-92.4,9.6,-93.3,-10.5,-87.8,-28.8C-82.2,-47,-70.1,-63.5,-54.4,-73.5C-38.7,-83.6,-19.3,-87.3,-0.8,-86.2C17.8,-85.2,35.6,-79.3,51,-69.2Z" }, "-=.2")
      .to(blob, { duration: .9, morphSVG: "M49.7,-68.6C64.3,-57.7,76.2,-43.2,81.5,-26.6C86.8,-10,85.5,8.7,79.5,25.5C73.6,42.4,63.1,57.4,49.1,68C35.1,78.6,17.5,84.8,-0.4,85.3C-18.3,85.8,-36.5,80.6,-51.4,70.3C-66.4,60,-77.9,44.5,-83.3,27.2C-88.8,9.9,-88.1,-9.2,-82.3,-26.7C-76.6,-44.2,-65.9,-60.1,-51.4,-71.1C-36.8,-82.1,-18.4,-88.1,-0.5,-87.5C17.5,-86.9,35,-79.6,49.7,-68.6Z" }, "-=.2")
      .to(blob, { duration: .9, morphSVG: "M45,-76.3C59.4,-69.7,72.8,-59.7,81.3,-46.4C89.8,-33.2,93.4,-16.6,92.3,-0.6C91.2,15.3,85.4,30.6,76.7,43.6C68.1,56.6,56.6,67.4,43.3,74.4C30.1,81.4,15,84.8,-0.6,85.8C-16.2,86.8,-32.4,85.6,-45.3,78.3C-58.2,71.1,-67.8,57.9,-74.4,43.8C-81,29.8,-84.6,14.9,-84.9,-0.2C-85.2,-15.2,-82.1,-30.4,-75.4,-44.3C-68.7,-58.2,-58.4,-70.7,-45.2,-77.9C-32,-85.2,-16,-87.3,-0.3,-86.7C15.3,-86.1,30.7,-82.9,45,-76.3Z" }, "-=.2")
      .to(blob, { duration: .9, morphSVG: blob }, "-=.2")

    botAnim.to(eyes, {
      duration: .1,
      opacity: 0,
      delay: gsap.utils.random(3, 6),

    }).to(eyes, {
      duration: .1,
      opacity: 1,
    }, "+=.2");
  }
}
