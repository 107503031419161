import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-item-highlight',
  templateUrl: './item-highlight.component.html',
  styleUrls: ['./item-highlight.component.scss']
})
export class ItemHighlightComponent implements OnInit {

  @Input()
  icon : any = 'fa-sharp fa-solid fa-star';
  
  constructor() { }

  ngOnInit(): void {
  }

}
