import { Component, ElementRef, ViewChild, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environment.prod';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScChatbotTriggerButtonComponent } from '../trigger-button/trigger-button.component';


@Component({
  selector: 'sc-chatbot-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class ScChatbotOverlayComponent implements OnChanges {

  constructor() { }

  @ViewChild("chatbotOverlay", { static: true })
  chatbotOverlay: ElementRef;

  @ViewChild(ScChatbotTriggerButtonComponent, { static: true })
  public trigger: ScChatbotTriggerButtonComponent;

  @Input()
  chat = null;

  chatbotOverlayOpenedOnce = false;

  private requestShowDestroy = new Subject<any>();

  isActive = false;

  @Input()
  requestShow: Observable<any>;

  @Output()
  chatEnded = new EventEmitter<any>();


  ngOnChanges(changes: any) {
    if (changes.requestShow) {
      this.requestShowDestroy.next();
      if (!this.requestShow) return;

      this.requestShow.pipe(takeUntil(this.requestShowDestroy)).subscribe((chat: any) => {
        this.chat = chat;
        this.show();
      });
    }
  }

  toggle() {

    if (this.isActive) 
    {
      this.hide();
    }
    else
    {
      this.show();
    }
  }  

  show() {
    this.trigger.stopPulse();
    this.trigger.state = 'close';

    let chatbotOverlay = this.chatbotOverlay.nativeElement; 
    chatbotOverlay.classList.add('is-active');
    this.chatbotOverlayOpenedOnce = true;
    this.isActive = true;
  }

  hide() {
    let chatbotOverlay = this.chatbotOverlay.nativeElement;
    this.trigger.state = 'chat';
    this.trigger.badge = 0;
    chatbotOverlay.classList.remove('is-active');    
    this.isActive = false;
  }  

  handleEndOfChat() {
    this.chatEnded.emit(this.chat);
  }
}
