import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sc-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit { 

  @Input()
  user: any;

  @Input()
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' = 'sm';

  imageError = false;

  imageSizes = {
    xs: 30,
    sm: 36,
    md: 40,
    lg: 64,
    xl: 82,
    xxl: 176
  };

  constructor() { }

  ngOnInit() {
  } 

  getInitials() {
    if (!this.user) return '';
    if (!this.user.FirstName && !this.user.LastName) return '?';
    if (!this.user.FirstName && this.user.LastName) return this.user.LastName[0];
    if (this.user.FirstName && !this.user.LastName) return this.user.FirstName[0];
    return this.user.FirstName[0] + this.user.LastName[0];
  }

  getColor() {

    var colors = {

      pastelblue: {
        textColor: "light",
        bgColor: "#93DDEA"
      },

      pastelorange: {
        textColor: "light",
        bgColor: "#EFD6AC"
      },

      pastelpurple: {
        textColor: "light",
        bgColor: "#ACADEF"
      },

      pastelrose: {
        textColor: "light",
        bgColor: "#F2C5CB"
      },

      pastelgreen: {
        textColor: "light",
        bgColor: "#BCF1D4"
      },

      pastellime: {
        textColor: "light",
        bgColor: "#E2EFAC"
      },

      pastelyellow: {
        textColor: "light",
        bgColor: "#EFE8AC"
      }
    }

    var nullColor = {
      textColor: 'light',
      bgColor: '#E4E6E8'
    };

    if (!this.user)
    {
      return nullColor;
    }

    var charIndex = 0;
    if (this.user?.FirstName) charIndex +=this.user.FirstName.charCodeAt(0);
    if (this.user?.LastName) charIndex +=this.user.LastName.charCodeAt(0);

    var keys = Object.keys(colors).reverse();
    var colorIndex = charIndex % keys.length;

    var key = keys[colorIndex];
    var value = colors[key];
    
    return value;
  }

  tooltip()
  {
    return this.user.FirstName + ' ' + this.user.LastName;
  }

}
