import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { EMailValidation, ScApiService } from '@sc-ui';

@Component({
  selector: 'app-pool-base-user-modal',
  templateUrl: './pool-base-user-modal.component.html',
  styleUrls: ['./pool-base-user-modal.component.scss']
})
export class PoolBaseUserModalComponent implements OnInit, OnChanges {

  @Input()
  pool: any;

  users: any = [];

  poolUsers: any = []

  q = "";

  showAddEMail = false;

  isAddingByEMail = false;

  isSearching = false;

  constructor(private api: ScApiService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.users = [];

    if (!this.pool) return;

    this.api.get(`users`).subscribe(d => {
      this.users = d;
      this.updateUserStates();
    })

    this.api.get(`pools/${this.pool.Id}/users`).subscribe(d => {
      this.poolUsers = d;
      this.updateUserStates();
    })
  }

  updateUserStates() {
    this.users.forEach(u => {
      u.isAdded = false;
      u.canAdd = true;

      var poolUser = this.poolUsers.find(u2 => u2.Id == u.Id);
      if (poolUser) {
        u.isAdded = true;
        u.canRemove = !poolUser.IsManager && !poolUser.IsDecider && !poolUser.IsSelf;
      }
    });

    this.poolUsers.forEach(u => {
      u.isAdded = true;
      u.canRemove = !u.IsManager && !u.IsDecider && !u.IsSelf;
    });
  }

  searchSoon() {
    this.showAddEMail = false;

    if (this['searchTimeout']) clearTimeout(this['searchTimeout']);
    this['searchTimeout'] = setTimeout(() => {

      this.isSearching = true;
      
      this.api.get('users?q=' + encodeURIComponent(this.q)).subscribe((d: any) => {
        this.users = d;
        this.updateUserStates();
        // this.searchPending = false;    
        if (d?.length == 0)
        {
          this.validateEMail();
        }  

        this.isSearching = false;
      })
    }, 500);

  }

  validateEMail()
  {
    console.log('validating ail');
    this.showAddEMail = EMailValidation.validate(this.q);
  }

  removeUser(args: any) {
    var user = args.user;

    this.api.delete('pools/' + this.pool.Id + '/users/' + user.Id).subscribe(() => {
      var i = this.poolUsers.indexOf(user);
      this.poolUsers.splice(i, 1);
      args.confirm();
      this.updateUserStates();
    });
  }

  addUser(args: any) {
    var user = args.user;

    this.api.post('pools/' + this.pool.Id + '/users', user).subscribe((d: any) => {
      user.isAdded = true;
      user.canRemove = true;
      this.poolUsers.push(user);
      args.confirm();
      this.updateUserStates();
    });
  }

  addByEMail()
  {
    this.isAddingByEMail = true;

    var user : any = {
      EMail : this.q
    };
    
    this.api.post('pools/' + this.pool.Id + '/users', user).subscribe((u: any) => {
      u.isAdded = true;
      u.canRemove = true;
      this.poolUsers.push(u);
      this.users.push(u);     
      this.showAddEMail = false; 
      this.isAddingByEMail = false;
    });
  }

}
