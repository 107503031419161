import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'sc-chatbot-input-yes-no',
  templateUrl: './input-yes-no.component.html',
  styleUrls: ['./input-yes-no.component.scss']
})
export class ScChatbotInputYesNoComponent {

  @Input() 
  message : any;

  @Output() 
  submit: EventEmitter<any> = new EventEmitter();

  send(value) {
    
    var valueMessage = value ? this.message.Payload.YesLabel : this.message.Payload.NoLabel;

    var message = {
      Direction: "UserToBot",
      Type: 'YesNo',
      Payload: {
        Yes: value,
        SelectedLabel: valueMessage,
        Body: valueMessage
      }
    };

    this.submit.emit(message);
  }

}
