import { Component, ChangeDetectionStrategy, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'sc-chatbot-input-choice',
  templateUrl: './input-choice.component.html',
  styleUrls: ['./input-choice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScChatbotInputChoiceComponent {

  selectionRequired: boolean = false;

  resultListView: boolean = true;  

  @Input() 
  message : any;

  @Output() 
  submit: EventEmitter<any> = new EventEmitter();

  lockRemainingChoices = false;

  choiceClicked(choice) {   

    if (this.message.Payload.MaximumSelectedChoices > 1) {      
      choice.Checked = !choice.Checked;
      var currentSelectedCount = this.message.Payload.Choices.filter(c => c.Checked).length;
      this.lockRemainingChoices = currentSelectedCount >= this.message.Payload.MaximumSelectedChoices;      

    } 
    else {
      choice.Checked = true;
      this.send();
    }
  }

  send() {

    var choices = this.message.Payload.Choices;

    var SelectedIndices = choices
      .filter(c => c.Checked)
      .map(c => choices.indexOf(c));

    var selectedLabels = choices
      .filter(c => c.Checked)
      .map(c => c.Label);

    var selectedValues = choices
      .filter(c => c.Checked)
      .map(c => c.Value);

    var message = {
      Direction: "UserToBot",
      Type: 'Choice',
      Payload: {
        SelectedIndices: SelectedIndices,
        SelectedLabels: selectedLabels,
        SelectedValues: selectedValues
      }
    };

    this.submit.emit(message);
  }

  getDefactoMaxOptions()
  {
    if (!this.message?.Payload?.Choices) return 0;
    var choiceCount = this.message.Payload.Choices.length;
    var maxSelections = this.message.Payload.MaximumSelectedChoices;
    return Math.min(choiceCount, maxSelections);
  }

  getSelectedCount()
  {
    return this.message.Payload.Choices.filter(c => c.Checked).length;
  }
}
