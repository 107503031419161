import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanizedFileSize'
})
export class HumanizedFileSizePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (!value) return '';

    console.log('hfs');

    var multiple = 1024;
    var units = [ "Byte", "KB", "MB", "GB", "TB" ];
    var unitIndex = 0;
    var sizeInUnit = value; // value in Byte

    while (unitIndex < units.length - 1) {      
      if (sizeInUnit / 1024 < 1) break;
      if (sizeInUnit <= 1) break;
      sizeInUnit /= 1024;
      unitIndex++;      
    }

    var unit = units[unitIndex];
    var decimals = 0;
    if (sizeInUnit < 10) decimals = 1;
    if (sizeInUnit < 1) decimals = 2;
    var roundedSize = sizeInUnit.toFixed(decimals);

    return roundedSize + " " + unit;
  }
}
