import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sc-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnChanges {

  @Output()
  dateChange = new EventEmitter<any>();

  @Input()
  date: any;

  @Input()
  startDate: any;

  @Input()
  minDate: any;

  @Input()
  maxDate: any;

  ngbDate: any = {};

  currentPage = {};

  get ngbMinDate()
  {
    if (!this.minDate) return null;
    return this.toNgbDate(this.minDate);
  }

  get ngbMaxDate()
  {
    if (!this.maxDate) return null;
    return this.toNgbDate(this.maxDate);
  }

  get ngbStartDate()
  {
    if (!this.startDate) return null;
    return this.toNgbDate(this.startDate);
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any) {
    if (changes.date) {
      if (this.date)
      {
        this.ngbDate = this.toNgbDate(this.date);
        this.currentPage = this.ngbDate;
      }      
    }
  }

  onChange(value) {
    this.ngbDate = value;
    this.date = this.fromNgbDate(value);    
    this.dateChange.next(this.date);
  }

  public toNgbDate(isoDate) {
    var date = new Date(isoDate);

    var ngbDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };

    return ngbDate;
  }

  private fromNgbDate(ngbDate)
  {
    var date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    return date.toISOString();
  }
}