<a>  
    <div class="notification card">
  
      <div class="card-body">
        <div class="row align-items-center">
  
          <div class="col-auto">
    
            <span class="icon circle-danger" [class.circle-danger]="!badge">
              <fa [class]="'text-primary'" class="fa-sharp fa-solid fa-bell"></fa>
            </span>    
            
            <span class="notification-counter badge rounded-pill bg-danger" *ngIf="badge">{{ badge }}</span>
    
          </div>
    
          <div class="col">
            <strong><ng-content></ng-content></strong>
          </div>
    
          <div class="col-12 col-md-auto d-flex justify-content-center">
            <button class="btn btn-primary btn-round btn-sm mt-3 mt-md-0">{{ buttonLabel }}</button>
          </div>
            
        </div>
      </div>
  
    </div>
  
  </a>