import { Directive, Input, HostBinding, OnInit, OnChanges } from '@angular/core'
@Directive({
  selector: 'img[fallbackSrc]',
  host: {
    '(error)': 'fallback()',    
    '[src]': 'src'
  }
})
export class FallbackSrcDirective implements OnInit, OnChanges {

  src : string;

  @Input() preferredSrc: string;

  @Input() fallbackSrc: string;

  fallback() {
    this.src = this.fallbackSrc;
  }

  setInnerSrc() {
    if (!this.preferredSrc) 
      this.src = this.fallbackSrc;
    else
      this.src = this.preferredSrc;
  }

  ngOnInit() {
    this.setInnerSrc();
  }

  ngOnChanges() {
    this.setInnerSrc();
  }
}
