<div class="comment has-alert-item">
    <div class="row">

        <div class="col-auto">
            <sc-avatar size="sm" [user]="activity.Author"></sc-avatar>
        </div>

        <div class="col ms-n2">

            <div class="comment-header mb-2">
                <div class="row">
                    <div class="col">
                        <sc-activity-item-header [activity]="activity" [showProjectName]="showProjectName"></sc-activity-item-header>
                    </div>
                </div>
            </div>

            <div class="comment-body">
                <sc-item-highlight [icon]="icon">
                    <ng-content></ng-content>
                </sc-item-highlight>
            </div>

        </div>
    </div>
</div>