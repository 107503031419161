import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-item-link',
  templateUrl: './item-link.component.html',
  styleUrls: ['./item-link.component.scss']
})
export class ItemLinkComponent implements OnInit {

  @Input()
  name;

  @Input()
  uri;

  @Input()
  preview;

  constructor() { }

  ngOnInit(): void {
  }

  open()
  {    
      window.open(this.uri, "_blank");
  }

}
