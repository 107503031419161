import { query, style, animate, } from '@angular/animations';
import { Component, OnInit } from '@angular/core';


const fadeIn = [
  query(':leave', animate('10s', style({ opacity: 0 })), { optional : true })
]
@Component({
  selector: 'app-topnavigation',
  templateUrl: './topnavigation.component.html',
  styleUrls: ['./topnavigation.component.scss'] 
})
export class TopnavigationComponent implements OnInit {  
  
  isCollapsed: boolean;

  constructor() {
    this.isCollapsed = true;
   }

  ngOnInit() {

    
  }
}
