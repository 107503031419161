<!-- HEADER -->
<div class="header" #header>

  <div class="header-img-container no-padding">

    <div class="container-fluid">

      <app-image-reveal #banner>
        <img [src]="job.Image| imageSize : 1200 : 395" class="img-fluid">
      </app-image-reveal>

      <!-- <picture *ngIf="job.Image; else headerImgPlaceholder">
		  <img src="{{ job.Image | imageSize : 1200 : 395 }}" class="header-img-top" alt="Banner {{job.Company.Name}}">
		</picture> -->

      <ng-template #headerImgPlaceholder>
        <div class="header-img-top-placeholder"></div>
      </ng-template>

      <div class="company-logo d-lg-none">

        <div class="company-logo-fit" [style.background-image]="'url(' + job.Company.LogoUri + ')'">

          <img src="{{job.Company.LogoUri}}" />

        </div>

      </div>

    </div>

  </div>

  <div class="header-body">

    <div class="container">

      <div class="row align-items-start">

        <div class="col-12 col-md-8 col-lg-6 offset-lg-1">

          <h6 class="header-pretitle">
            <span>{{ 'Ihr Jobangebot' | localize }}</span>
          </h6>

          <app-image-reveal #headlineReveal>
            <h1 class="header-title mt-2 mt-md-4 mb-5 mb-md-4">
              {{job.Name}}
              <span class="lead job-title-suffix">{{jobTitleSuffix}}</span>
            </h1>

            <div class="job-title-subline d-none d-md-block">
              {{job.Company.Name}}
            </div>
          </app-image-reveal>

        </div>

        <div class="col-12 col-md-4 offset-lg-1 mt-lg-2">

          <app-image-reveal #logoReveal>

            <div class="company-logo mb-5 d-none d-lg-block">

              <div class="company-logo-fit" [style.background-image]="'url(' + job.Company.LogoUri + ')'">

                <img src="{{job.Company.LogoUri}}" />

              </div>

            </div>

            <ul class="list-group list-group-flush list-type-icon">

              <li class="list-group-item" *ngIf="job.Locations.length > 0" (click)="scrollToMap()">

                <span class="fa-icon">
                  <i class="fa-sharp fa-solid fa-location-dot"></i>
                </span>
  
                <span>{{ job.Locations[0].Zip }} {{ job.Locations[0].City }}</span>
                
                <span *ngIf="job?.Locations.length > 1">
                  + {{ job?.Locations.length - 1 }} {{ 'weitere' | localize }}
                </span>
                
                <!-- <a class="badge badge-pill badge-light" (click)="scrollToMap()">+
                  </a> -->
              </li>

              <li class="list-group-item" *ngIf="job.Locations.length == 0">
                <span class="fa-icon">
                  <i class="fa-sharp fa-solid fa-location-dot"></i>
                </span>
                <span class="mr-3">{{ 'Deutschlandweit' | localize }}</span>
              </li>

              <li class="list-group-item">
                <span class="fa-icon">
                  <i class="fa-sharp fa-solid fa-clock"></i>
                </span>
                <span *ngIf="!job.IsPartTime">{{ 'Vollzeit' | localize }}</span>
                <span *ngIf="!job.IsFullTime && job.IsPartTime">{{ 'Teilzeit' | localize }}</span>
                <span *ngIf="job.IsFullTime && job.IsPartTime">{{ 'Voll- oder Teilzeit' | localize }}</span>
              </li>

              <li class="list-group-item">
                <span class="fa-icon">
                  <i class="fa-sharp fa-solid fa-file-signature"></i>
                </span>
                <span *ngIf="job.EmploymentType == 'Permanent'">{{ 'Festanstellung' | localize }}</span>
                <span *ngIf="job.EmploymentType == 'Interim'">{{ 'Befristete Anstellung' | localize }}</span>
                <span *ngIf="job.EmploymentType == 'Freelancer'">{{ 'Freelancer' | localize }}</span>
              </li>

              <li class="list-group-item" *ngIf="job.MaxRemote > 0">
                <span class="fa-icon">
                  <i class="fa-sharp fa-solid fa-house-laptop"></i>
                </span>
                {{ 'Remoteanteil bis' | localize}} {{ job.MaxRemote }}%
              </li>

              <li class="list-group-item" *ngIf="job?.Compensation?.MinSalary && job?.Compensation?.MaxSalary">
                <span class="fa-icon">
                  <i class="fa-sharp fa-solid fa-circle-euro"></i>
                </span>
                <span>{{ job.Compensation.MinSalary | number:'1.0-0':'de' }} -
                  {{ this.job.Compensation.MaxSalary | number:'1.0-0':'de' }} {{ job.Compensation.SalaryCurrency }}</span>
              </li>

            </ul>

          </app-image-reveal>
        </div>
      </div>
    </div>
  </div>
</div>
