import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'sc-chatbot-input-none',
  templateUrl: './input-none.component.html',
  styleUrls: ['./input-none.component.css']
})
export class ScChatbotInputNoneComponent implements OnInit, OnChanges {

  @Input()
  message: any;

  @Output()
  submit: EventEmitter<any> = new EventEmitter();

  buttonText = 'Weiter';

  ngOnInit() {
  }

  ngOnChanges(changes) {    

    if (!this.message.Payload.AskToProceed) {
      var proceedDelay = this.message.Payload.ProceedDelay ? this.message.Payload.ProceedDelay : 1;
      setTimeout(() => {
        this.send();
      }, proceedDelay);
    }
    if (this.message.Payload.ButtonText) {
      this.buttonText = this.message.Payload.ButtonText;
    }
  }

  send() {

    var message = {
      Direction: "UserToBot",
      Type: 'None'
    };
    this.submit.emit(message);
  }

}
