<div id="sc-chatbot-overlay" #chatbotOverlay>

    <div id="sc-chatbot-container">
      <sc-chatbot-chat-header [chat]="chat" [showHide]="true" (hide)="hide()" [showBack]="false"></sc-chatbot-chat-header>

      <sc-chatbot-stream *ngIf="chat" #chatbotStream style="z-index: 1000;" theme="light" q
        delayInitialServerMessage="0" [showHeader]="true" [showInitialLoader]="false"
        [chat]="chat"
        (onEndOfChat)="handleEndOfChat($event)">
      </sc-chatbot-stream>
    </div>

    <div id="sc-chatbot-backdrop" (click)="hide()"></div>
  
  </div>
  
  <sc-chatbot-intelligence-trigger-button #chatbotTrigger [class.d-none]="isOpen" class="d-md-block" (click)="toggle()"></sc-chatbot-intelligence-trigger-button>