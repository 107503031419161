<div class="card mb-3 card-hover" (click)="open()">


    <div class="card-body">

      <img *ngIf="preview" [src]="preview | imageSize : 600 : 200" class="card-img-top" />
  
      <div class="row">
        
        <div class="col-auto d-flex align-items-center">  
          <div class="file me-n2 ms-2">
            <i class="fa-sharp fa-solid fa-link"></i>
          </div>  
        </div>  
  
        <div class="col-auto">          
          <div class="date-name">
             <h6 class="mb-0">{{ name }}</h6>
            <div class="small text-muted uri">{{ uri }}</div>
          </div>
  
        </div> 
  
      </div>  
    </div>
  </div>
  