import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-admin-manager-avatars',
  templateUrl: './admin-manager-avatars.component.html',
  styleUrls: ['./admin-manager-avatars.component.scss']
})
export class AdminManagerAvatarsComponent implements OnInit {

  @Input()
  users: any = [];

  @Input()
  size = 'sm';

  @Output()
  avatarClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(user) {
    this.avatarClick.next(user);
  }

}
