import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-activity-item-project-jobsite-ready',
  templateUrl: './activity-item-project-jobsite-ready.component.html',
  styleUrls: ['./activity-item-project-jobsite-ready.component.scss']
})
export class ActivityItemProjectJobsiteReadyComponent implements OnInit {

  @Input()
  activity : any;

  @Input()
  condensed = false;

  @Input()
  showProjectName = false;
  
  constructor() { }

  ngOnInit(): void {
  }

}
