import { Directive, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { ScScrollService } from '../services/scroll.service';

@Directive({
  selector: '[scScrollSpy]'
})
export class ScScrollSpyDirective implements OnInit {

  isInViewport : boolean;  

  @Output('onViewportEnter')
  onViewportEnter = new EventEmitter<any>();  

  @Output('onViewportLeave')
  onViewportLeave = new EventEmitter<any>();  

  constructor(
    private scrollService: ScScrollService,
    private el: ElementRef) {
  } 

  ngOnInit() {

    this.scrollService.requestUpdate();    

    this.scrollService.$spy.subscribe(e => {      
      
      if (e.element == this.el.nativeElement && e.event === 'enteredViewport') {
        this.isInViewport = true;
        this.onViewportEnter.emit();
      }

      if (e.element == this.el.nativeElement && e.event === 'leftViewport') {
        this.isInViewport = false;
        this.onViewportLeave.emit();
      }

      if (this.isInViewport)
        this.el.nativeElement.classList.add("in-view");
      else
        this.el.nativeElement.classList.remove("in-view");
    });

    this.scrollService.requestUpdate();
  }

}
