import { Directive, ElementRef } from '@angular/core';
import { OnInit } from '@angular/core';
import { ScScrollService } from "../services/scroll.service";
import { Input } from '@angular/core';

@Directive({
  selector: '[scAnchorTarget]'
})
export class ScAnchorTargetDirective implements OnInit {

  @Input('scAnchorTarget') id : string;
  
  constructor(private scrollService: ScScrollService, private host : ElementRef) {}

  ngOnInit() {
    this.host.nativeElement.setAttribute("anchor", this.id);
    this.scrollService.requestUpdate();
  }

}
