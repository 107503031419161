import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-activity-item-comment',
  templateUrl: './activity-item-comment.component.html',
  styleUrls: ['./activity-item-comment.component.scss']
})
export class ActivityItemCommentComponent implements OnInit {

  @Input()
  activity : any;

  @Input()
  condensed = false;

  @Input()
  showProjectName = false;

  @Input()
  label : string = null;

  constructor() { }

  ngOnInit(): void {
  }

}
