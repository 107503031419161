import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FontAwesomeService {

  private _cache = {};

  private _loading = {};

  constructor(private http: HttpClient) { }

  getIcon(dir, file): Observable<string> {
    // console.log(`https://fontawesome.blob.core.windows.net/${dir}/${file}.svg`);
    var key = dir + '_' + file; 
    
    if (this._cache[key]) {
      return new Observable(observer => { observer.next(this._cache[key]) });
    }

    if (this._loading[key]) {
      return this._loading[key];
    }

    this._loading[key] = new Subject<string>();
    
    this.http
      .get(`https://fontawesome.blob.core.windows.net/${dir}/${file}.svg`, { responseType: 'text' })
      .subscribe(d => {
        this._cache[key] = d;
        this._loading[key].next(d);
        this._loading[key] = null;
      });

    return this._loading[key];
  }
}
