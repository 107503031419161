import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sc-chatbot-answer-choice',
  templateUrl: './answer-choice.component.html',
  styleUrls: ['./answer-choice.component.scss']
})
export class ScChatbotAnswerChoiceComponent implements OnInit {

  @Input()
  message : any;

  get selectedLabels()
  {
    if (!this.message) return [];
    if (!this.message.Payload) return [];
    if (!this.message.Payload.SelectedLabels) return [];
    return this.message.Payload.SelectedLabels;
  }
  
  constructor() { }

  ngOnInit() {
  }

}
