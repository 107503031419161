import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-pool-activities',
  templateUrl: './pool-activities.component.html',
  styleUrls: ['./pool-activities.component.scss']
})
export class PoolActivitiesComponent implements OnInit {

  @Input() 
  pool : any;

  activitiesUri : string;

  mentionsUri : string;

  constructor(public userService : UserService) { }

  ngOnInit(): void {
  }

  ngOnChanges()
  {
    if (!this.pool) return;
    this.activitiesUri = `pools/${this.pool.Id}/activities`;
    this.mentionsUri = `pools/${this.pool.Id}/mentions`;
  }

}
