<div class="comment has-file-item">
    <div class="row">
      <div class="col-auto">

        <sc-avatar size="sm" [user]="activity.Author"></sc-avatar>

      </div>
      <div class="col ms-n2">
        
        <div class="comment-header mb-2">

          <div class="row">
            
            <div class="col">

              <sc-activity-item-header [activity]="activity"></sc-activity-item-header>

              <p class="mb-0 activity">
                hat eine Datei hochgeladen
              </p>

            </div>

          </div> <!-- / .row -->

        </div>

        <!-- Body -->
        <div class="comment-body">

          <sc-item-file></sc-item-file>

        </div>

      </div>
    </div> <!-- / .row -->
  </div>
