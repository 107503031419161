<div class="card mb-3" [class.open-on-click]="openOnClick" (click)="onClick()">

  <div class="card-body">

    <div class="row">
      <div class="col-auto d-flex align-items-center">
        <div class="file me-2 ms-2" [ngSwitch]="type">
          <fa *ngSwitchCase="'pdf'" class="fa-sharp fa-solid fa-file-pdf"></fa>
          <fa *ngSwitchDefault class="fa-sharp fa-solid fa-file"></fa>
        </div>
      </div>

      <div class="col-auto" *ngIf="icon">
        <div class="avatar">
          <img [src]="icon" class="avatar-img rounded-circle">
        </div>
      </div>

      <div class="col">
        <div class="date-name">
          <h6 class="mb-0">{{ name }}</h6>
          <span class="small">{{ size }}</span>
        </div>
      </div>
    </div>
  </div>
</div>