<div class="card clickable card-hover mb-3" *ngIf="assignment" routerLink="/projects/{{ assignment.Project.Id }}">
  <div class="card-body">
    <div class="item-row">
      <div class="image-col">
        <div class="image-container">
          <img *ngIf="assignment.Project.Image" [src]="assignment.Project.Image | imageSize : 80 : 50" />
          <img *ngIf="!assignment.Project.Image" src="/assets/project-hero-placeholder/hero-placeholder-{{assignment.Project.Id % 3 + 1}}.jpg" class="card-img-top" />
        </div>
      </div>

      <div class="body-col">
        <h5 class="mb-0 h5 oneline">{{ assignment.Project.Name}} <span class="small fw-normal">m|w|d</span></h5>
        <div class="status-badge">
          <app-candidate-status-badge class="mt-4" [assignment]="assignment"></app-candidate-status-badge>
        </div>
      </div>
      <div class="actions-col">
        <div class="file me-2 ms-2">
          <i class="fa-regular fa-chevron-right"></i>
        </div>
      </div>
    </div>
  </div>
</div>
