import { Component, HostBinding, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { ChatsService } from '@sc-ui-chatbot';
import { TypewritingParagraphComponent } from 'src/components/typewriting-paragraph/typewriting-paragraph.component';

@Component({
  selector: 'app-project-briefing',
  templateUrl: './project-briefing.component.html',
  styleUrls: ['./project-briefing.component.scss']
})
export class ProjectBriefingComponent implements OnInit, OnChanges {

  briefingChat : any;
  
  @HostBinding('class.hide')
  get hidden() {
    return !this.project?.MustHaves &&
      !this.project?.MinYearsOfExperience &&
      !this.project?.MaxYearsOfExperience &&
      !this.project?.Responsibility &&
      !this.project?.MinGermanLevel &&
      !this.project?.MinEnglishLevel;
  }

  @Input()
  project: any;

  @ViewChild("descriptionParagraph", { static : false})
  descriptionParagraph : TypewritingParagraphComponent;

  @ViewChild("companyDescriptionParagraph", { static : false})
  companyDescriptionParagraph : TypewritingParagraphComponent;
  
  @ViewChild("tagsParagraph", { static : false})
  tagsParagraph : TypewritingParagraphComponent;

  @ViewChild("highlightsParagraph", { static : false})
  highlightsParagraph : TypewritingParagraphComponent;

  constructor(private api : ScApiService, private chats : ChatsService) { }

  ngOnChanges()
  {
    if (this.project)
    {
      this.chats.getChat('projects/' + this.project.Id + '/briefing-chat').subscribe((d : any) => {
        this.briefingChat = d;
      });
    }
  }

  ngOnInit(): void {
  }

}
