import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'sc-chatbot-trigger-button',
  templateUrl: './trigger-button.component.html',
  styleUrls: ['./trigger-button.component.scss']
})
export class ScChatbotTriggerButtonComponent {

  public state: 'chat' | 'close' = 'chat';

  public isPulsating = false;

  public badge : number = 0;

  public show = false;

  constructor() { }
  
  slideIn() {
    this.show = true;
  }

  slideOut() {
    this.show = false;
  }

  startPulse() {
    this.isPulsating = true;
  }

  stopPulse() {
    this.isPulsating = false;
  }
}
