import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-candidate-base-pool-item',
  templateUrl: './candidate-base-pool-item.component.html',
  styleUrls: ['./candidate-base-pool-item.component.scss']
})
export class CandidateBasePoolItemComponent implements OnInit {

  @ViewChild(NgbDropdown)
  dropdown : NgbDropdown;

  @Input()
  assignment : any;

  constructor(private api : ScApiService) { }

  ngOnInit(): void {
  }

  updateStatus(status)
  {    
    this.assignment.Status = status;
    this.api.put(`pools/${this.assignment.Pool.Id}/candidates/${this.assignment.CandidateId}/assignment`, this.assignment).subscribe(() => {});
  }

}
