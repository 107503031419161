import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'sc-json-ld',
  template: ''
})
export class ScJsonLdComponent implements OnChanges {

  @Input() json : any;

  @HostBinding('innerHTML') htmlContent: SafeHtml;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges) {
    this.htmlContent = this.getSafeHTML(changes.json.currentValue);
  }

  getSafeHTML(json: string) {
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}