<div class="form-control form-control-{{size}} clickable" (click)="modal.show()">
  <span *ngIf="!timeSelectable && date">{{ date | date : 'dd.MM.yyyy' }}</span>
  <span *ngIf="timeSelectable && date">{{ date | date : 'dd.MM.yyyy' }} um {{ date | date : 'HH.mm' }} Uhr</span>
  <span class="text-muted" *ngIf="!date">Kein Datum gewählt</span>
</div>

<sc-modal #modal>
  <sc-modal-header>
    Bearbeiten
  </sc-modal-header>

  <sc-modal-body>
    <div class="row">
      <div class="col-12 col-lg-6">
        <sc-date-picker [date]="date" (dateChange)="setDate($event)" displayMonths="1" #d></sc-date-picker>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row align-items-center mt-3 mt-lg-0" *ngIf="timeSelectable">
          <div class="col-2">
            <span *ngIf="rangeSelectable">Von</span>
            <span *ngIf="!rangeSelectable">Um</span>
          </div>
          <div class="col-4">
            <div ngbDropdown>
              <div ngbDropdownToggle>
                <div class="form-control">
                  {{ date | date : 'HH' }}
                </div>
              </div>
              <div ngbDropdownMenu>
                <a class="dropdown-item" *ngFor="let h of hours" (click)="setStartHours(h)">
                  {{ h | number : '2.0' }}
                </a>
              </div>
            </div>
          </div>
          <div class="col-auto px-0">
            :
          </div>
          <div class="col-4">
            <div ngbDropdown>
              <div ngbDropdownToggle>
                <div class="form-control">
                  {{ date | date : 'mm' }}
                </div>
              </div>
              <div ngbDropdownMenu>
                <a class="dropdown-item" *ngFor="let m of minutes" (click)="setStartMinutes(m)">
                  {{ m | number : '2.0' }}
                </a>
              </div>
            </div>
          </div>

        </div>

        <div class="row align-items-center mt-3 mt-lg-0" *ngIf="rangeSelectable && timeSelectable">
          <div class="col-2">
            Bis
          </div>
          <div class="col-4">
            <div ngbDropdown>
              <div ngbDropdownToggle>
                <div class="form-control">
                  {{ end | date : 'HH' }}
                </div>
              </div>
              <div ngbDropdownMenu>
                <a class="dropdown-item" *ngFor="let h of hours" (click)="setEndHours(h)">
                  {{ h | number : '2.0' }}
                </a>
              </div>
            </div>
          </div>
          <div class="col-auto px-0">
            :
          </div>
          <div class="col-4">
            <div ngbDropdown>
              <div ngbDropdownToggle>
                <div class="form-control">
                  {{ end | date : 'mm' }}
                </div>
              </div>
              <div ngbDropdownMenu>
                <a class="dropdown-item" *ngFor="let m of minutes" (click)="setEndMinutes(h)">
                  {{ m | number : '2.0' }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </sc-modal-body>

  <sc-modal-footer>
    <button class="btn btn-dark" (click)="modal.dismiss();">Fertig</button>
  </sc-modal-footer>
</sc-modal>
