import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { fadeIn } from 'src/transitions';

@Component({
  selector: 'app-pool-group',
  templateUrl: './pool-group.component.html',
  styleUrls: ['./pool-group.component.scss'],
  animations: [
    fadeIn
  ]
})
export class PoolGroupComponent implements OnInit {

  id : any;

  group : any;

  state = 'out';

  constructor(private api : ScApiService, private route : ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((params : any) => {
      if (!params.id) return;
      if (!params.poolId) return;

      this.id = params.id;
      this.api.get(`pools/${params.poolId}/groups/${params.id}`).subscribe((d : any) => {
        this.group = d;
        this.state = 'in';
      }, e => this.api.redirectAnonymousToLogin(e))
    })
  }
}
