import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environment.prod';
import { IntelligenceTriggerButtonComponent } from '../intelligence-trigger-button/intelligence-trigger-button.component';
import { ScChatbotStreamComponent } from '../stream/stream.component';

@Component({
  selector: 'sc-chatbot-intelligence-overlay',
  templateUrl: './intelligence-overlay.component.html',
  styleUrls: ['./intelligence-overlay.component.scss']
})
export class ScChatbotIntelligenceOverlayComponent implements AfterViewInit, OnChanges {

  constructor() { }

  @HostListener('window:keydown.escape', ['$event'])
  onEscape() {
    if (!this.isOpen) return;
    this.hide();
  }

  @ViewChild("chatbotOverlay", { static: true })
  chatbotOverlay: ElementRef;

  @ViewChild(IntelligenceTriggerButtonComponent, { static: true })
  trigger: IntelligenceTriggerButtonComponent;

  @ViewChild("mobileChatbotClose", { static: true })
  mobileChatbotClose: ElementRef;

  @ViewChild("chatbotStream")
  chatbotStream: ScChatbotStreamComponent;

  @Input()
  chat: any = null;

  chatbotOverlayOpenedOnce = false;

  private requestShowDestroy = new Subject<any>();

  isOpen = false;

  @Input()
  requestShow: Observable<any>;

  @Output()
  chatEnded = new EventEmitter<any>();

  ngAfterViewInit(): void {

    let mobileChatbotClose = this.mobileChatbotClose.nativeElement;
    let pulseStarted = false;

    mobileChatbotClose.addEventListener('click', () => {
      this.hide();
    });
  }

  ngOnChanges(changes: any) {
    if (changes.requestShow) {
      this.requestShowDestroy.next();
      if (!this.requestShow) return;

      this.requestShow.pipe(takeUntil(this.requestShowDestroy)).subscribe((chat: any) => {
        this.chat = chat;
        this.show();
      });
    }
  }

  toggle() {

    if (this.isOpen) {
      this.hide();
    }
    else {
      this.show();
    }
  }

  show() {
    this.trigger.stopPulse();
    this.trigger.state = 'close';

    let chatbotOverlay = this.chatbotOverlay.nativeElement;
    chatbotOverlay.classList.add('is-active');
    this.chatbotOverlayOpenedOnce = true;
    this.isOpen = true;
  }


  hide() {
    let chatbotOverlay = this.chatbotOverlay.nativeElement;
    this.trigger.state = 'chat';
    this.trigger.badge = 0;
    chatbotOverlay.classList.remove('is-active');

    this.isOpen = false;
  }

  handleEndOfChat() {
    this.chatEnded.emit(this.chat);
  }
}

