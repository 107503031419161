import { Component } from '@angular/core';

@Component({
  selector: 'app-jobsite-topbar',
  templateUrl: './jobsite-topbar.component.html',
  styleUrls: ['./jobsite-topbar.component.scss']
})
export class JobsiteTopbarComponent {

}
