<footer>

    <div class="container-fluid footer-inner">
        
        <div class="row align-items-center">
            
            <div class="col col-md-5">
                <span class="smaller copyright">© <span id="year">{{currentDate | date:'yyyy'}}</span> skillconomy</span>
            </div>

            <div class="col-auto col-md-7">
                
              <ul class="nav smaller justify-content-md-end">
                <li class="nav-item">
                  <a class="nav-link" routerLink="/imprint">Impressum</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" routerLink="/privacy">Datenschutz</a>
                </li> -->
              </ul>

            </div>

        </div>

    </div>

    
</footer>

