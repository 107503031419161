<div class="message-content">
  <div class="message-control-input input-visible">

    <div class="row mb-2">
      <div class="col">
        <input #placeInput placeholder="Bitte Ort eingeben..." type="text" class="form-control">
      </div>

      <div class="col-auto pl-0">

        <div *ngIf="showRadiusSelector" style="position: relative;"><button
            class="btn btn-outline-secondary dropdown-toggler" type="button" [scShowToggle]="userDropdownSm"
            #dropdownButton>
            + {{selectedRadius}} km 
            <i class="fa-sharp fa-solid fa-chevron-down"></i> 
          </button>

          <div class="dropdown-menu dropdown-menu-right" #userDropdownSm>
            <a *ngFor="let item of [10, 20, 50, 100, 200]" class="dropdown-item" (click)="setRadius(item)">+ {{item}}
              km</a>
          </div>


        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="map">
        <div #gmap></div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <button type="submit" class="btn btn-dark btn-block" (click)="send()">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
          <path fill="#fff" d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        </svg>
      </button>
    </div>
  </div>

</div>