import { Component, OnInit, ViewChild } from '@angular/core';
import { ScApiService, ScFileButtonComponent, ScUploadService } from '@sc-ui';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  oldPassword: string;

  newPassword: string;

  changePasswordSuccess: boolean;

  changePasswordError: string;

  isChangingPassword = false;

  isSaving = false;

  saveSuccess = false;

  isSavingNotifications = false;

  saveNotificationsSuccess = false;
  
  user : any;

  settings : any;

   avatarUri : '';

   isUploadingImage = false;

   show = 'profile';

   @ViewChild('fileButton')
   fileButton : ScFileButtonComponent


  constructor(
    private api : ScApiService, 
    private uploadService : ScUploadService,
    private userService : UserService) { }

  ngOnInit(): void {
    this.api.get('me').subscribe(d => {
      this.user = d;
    });

    this.api.get('me/settings').subscribe(d => {
      this.settings = d;
    }, e => this.api.redirectAnonymousToLogin(e))
  }

  onUploadError(e)
  {
    console.log(e);
  }

  onUploadSuccess(e)
  {
    console.log(e);
  }

  upload(files) {
    this.isUploadingImage = true;
    this.uploadService.upload(files, (d: any) => {
      this.avatarUri = d.Uri;
      this.user.Image = d.Uri;
      this.isUploadingImage = false;
    });
  }

  clearImage()
  {
    if (!this.user) return;
    this.user.Image = null;
    this.fileButton.reset();
  }

  save()
  {
    this.isSaving = true;

    this.api.put('me', this.user).subscribe(d => {
      this.isSaving = false;
      this.saveSuccess = true;
      this.userService.load();
      setTimeout(() => { 
        this.saveSuccess = false;
      }, 5000);
    });
  }

  saveNotifications()
  {
    this.isSavingNotifications = true;

    this.api.put('me/settings', this.settings).subscribe(d => {
      this.isSavingNotifications = false;
      this.saveNotificationsSuccess = true;      
      setTimeout(() => { 
        this.saveNotificationsSuccess = false;
      }, 5000);
    });
  }

  changePassword() {    

    if (!this.oldPassword)
    {
      this.changePasswordError = "Bitte altes Passwort eingeben";
      return;
    }

    if (!this.newPassword)
    {
      this.changePasswordError = "Bitte neues Passwort eingeben";
      return;
    }

    this.isChangingPassword = true;
    this.changePasswordError = null;

    this.api.changePassword(this.oldPassword, this.newPassword).subscribe(d => {
      
      this.newPassword = "";
      this.oldPassword = "";
      this.isChangingPassword = false;
      this.changePasswordSuccess = true;

      

      setTimeout(() => { 
        this.changePasswordSuccess = false;
      }, 5000);

    }, error => {

      console.error(error);
      this.isChangingPassword = false;

      if (error.error) {
        this.changePasswordError = error.error;
      }
      else {
        this.changePasswordError = "Ein Fehler ist aufgetreten";
      }
    })
  }

}