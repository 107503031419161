import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {

  @Input()
  rating : number;

  @Input()
  isReadOnly = false;

  @Output()
  changed = new EventEmitter<any>();

  @Input()
  ratingChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  setRating(value)
  {
    if (this.isReadOnly) return;
    this.rating = value;
    this.changed.emit(value);
    this.ratingChange.emit(value);
  }

}