import { Component, Input, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-pool-group-candidates',
  templateUrl: './pool-group-candidates.component.html',
  styleUrls: ['./pool-group-candidates.component.scss']
})
export class PoolGroupCandidatesComponent implements OnInit {

  @Input()
  group: any;

  q = '';

  hasMoreItems = false;

  isLoading = false;

  skip = 0;

  take = 25;

  candidates: any = [];

  constructor(private api: ScApiService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.load();
  }

  load() {
    this.candidates.length = 0;
    this.skip = 0;
    this.q = '';
    this.loadMore();
  }

  searchSoon() {
    if (this['_searchTimeout']) clearTimeout(this['_searchTimeout']);
    this['_searchTimeout'] = setTimeout(() => {
      this.candidates.length = 0;
      this.skip = 0;
      this.loadMore();
    }, 500);
  }

  loadMore() {
    if (!this.group) return;
    if (this.isLoading) return;

    this.isLoading = true;

    var uri = `pools/${this.group.PoolId}/groups/${this.group.Id}/candidates?skip=${this.skip}&take=${this.take}`;
    if (this.q) uri += `&q=${this.q}`;

    this.api.get(uri).subscribe(
      (d: any) => {
        this.skip += this.take;
        this.candidates.push.apply(this.candidates, d.Items);
        this.hasMoreItems = this.candidates.length < d.TotalCount;
      },
      () => { },
      () => { this.isLoading = false }
    );
  }

  onUserAdded(user)
  {
    this.candidates.splice(0, 0, user);
    this.group.CandidateCount++;
  }

  onUserRemoved(user)
  {
    var u = this.candidates.find(c => c.Id == user.Id);
    var i = this.candidates.indexOf(u);
    if (i < 0) return;
    this.candidates.splice(i, 1);
    this.group.CandidateCount --;
  }

  removeUser(user : any)
  {    
    this.api.delete( `pools/${this.group.PoolId}/groups/${this.group.Id}/candidates/${user.Id}`).subscribe(() => {
      this.onUserRemoved(user);
    });
  }

}
