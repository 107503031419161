/// <reference types="@types/googlemaps" />

import { Directive, AfterViewInit, ElementRef, Output, EventEmitter, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

@Directive({
  selector: '[scGmapsAutocomplete]'
})
export class GmapsAutocompleteDirective implements AfterViewInit {


  private autoComplete: google.maps.places.Autocomplete;

  @Output() selected = new EventEmitter<any>();

  constructor(
    private host: ElementRef,
    private mapsApiLoader: MapsAPILoader,
    private ngZone: NgZone) { }

  ngAfterViewInit() {
    setTimeout(() => this.loadGoogleMap(), 50);
  }

  loadGoogleMap() {
    //load Places Autocomplete
    this.mapsApiLoader.load().then(() => {
      this.autoComplete = new google.maps.places.Autocomplete(this.host.nativeElement);
      this.autoComplete.addListener("place_changed", () => {
        this.ngZone.run(() => {

          //get the place result
          let place: google.maps.places.PlaceResult = this.autoComplete.getPlace();

          console.log(place);

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          var city = '';
          var street = '';
          var zip = '';
          var number = '';
          var country = '';
          var formatted = '';

          place.address_components.forEach(c => {
            if (c.types.indexOf('locality') >= 0) city = c.long_name;
            if (c.types.indexOf('route') >= 0) street = c.long_name;
            if (c.types.indexOf('postal_code') >= 0) zip = c.long_name;
            if (c.types.indexOf('street_number') >= 0) number = c.long_name;
            if (c.types.indexOf('country') >= 0) country = c.long_name;
          })

          formatted = place.formatted_address;

          var location = {
            Latitude: place.geometry.location.lat(),
            Longitude: place.geometry.location.lng(),
            Zip: zip,
            City: city,
            Street: street,
            Number: number,
            Country: country,
            Formatted: formatted
          };

          this.selected.next(location);
        });
      });
    });
  }

  clear() {
    var el = this.host.nativeElement;

    el.blur();

    setTimeout(() => {
      el.value = null;
    }, 10);
  }

}
