import { Directive, Input, ElementRef, HostListener, OnChanges } from '@angular/core';
import { expand } from 'rxjs/operators';

@Directive({
  selector: '[scuiToggleOnClick]'
})
export class ToggleOnClickDirective implements OnChanges {

  @Input('scuiToggleOnClick')
  target: any

  constructor() { }

  ngOnChanges() {
    this.target.style.transition = 'height 0.3s ease-out';
    this.target.style.overflow = 'hidden';

    if (this.isCollapsed(this.target))
    {
      this.target.style.height = 0 + 'px'; 
      this.target.setAttribute('data-collapsed', 'true');
  
    }
  }

  @HostListener('click')
  toggle() {
    console.log(this.target);
    var isCollapsed = this.isCollapsed(this.target);
    if (isCollapsed) this.expandSection(this.target);
    else this.collapseSection(this.target);
  }

  isCollapsed(element) {
    var attr = element.getAttribute('data-collapsed');
    return (!attr || attr == "true");
  }

  collapseSection(element) {

    var sectionHeight = element.scrollHeight;

    requestAnimationFrame(function () {
      element.style.height = sectionHeight + 'px';     

      requestAnimationFrame(function () {
        element.style.height = 0 + 'px'; 
      });
    });

    element.setAttribute('data-collapsed', 'true');
  }

  expandSection(element) {

    element.style.height = '0px';

    var onTransitioned = (e: any) => {
      element.removeEventListener('transitionend', onTransitioned);
      element.style.height = null;
    };

    element.addEventListener('transitionend', onTransitioned);

    requestAnimationFrame(function () {
      element.style.height = element.scrollHeight + 'px';
    });

    element.setAttribute('data-collapsed', 'false');
  }

}
