<sc-activity-item-simple [activity]="activity" [showProjectName]="showProjectName" *ngIf="condensed">
    hat eine Einschätzung zu
    <a routerLink="/projects/{{ activity.Project.Id }}/candidates/{{ activity.Candidate?.Id }}">
        <strong>{{ activity.Candidate?.FirstName }} {{ activity.Candidate?.LastName }}</strong>
    </a> abgegeben
</sc-activity-item-simple>

<sc-activity-item-comment [activity]="activity" [showProjectName]="showProjectName" *ngIf="!condensed">
    <div class="comment-label">
        <p class="mb-0 activity">
            hat eine Einschätzung zu
            <a routerLink="/projects/{{ activity.Project.Id }}/candidates/{{ activity.Candidate?.Id }}">
                <strong>{{ activity.Candidate?.FirstName }} {{ activity.Candidate?.LastName }}</strong>
            </a> abgegeben
        </p>
    </div>
    <div class="comment-body">
        <div class=" ">
            <sc-star-rating isReadOnly="true" [rating]="activity.Payload?.Value"></sc-star-rating>
        </div>
        <p [innerHTML]="activity.Payload?.Comment"></p>
    </div>
</sc-activity-item-comment>