import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-ul-reveal',
  templateUrl: './ul-reveal.component.html',
  styleUrls: ['./ul-reveal.component.scss']
})
export class UlRevealComponent {

  @ViewChild('hostList') 
  hostList;
  
  @Input()
  ulClass : any = ''

  isRevealed = true;

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async reveal()
  {
    this.isRevealed = false;

    var children = this.hostList.nativeElement.childNodes;
    
    for(var i = 0; i < children.length; i++)
    {
      var c = children[i];
      if (!c?.classList)
        continue;
      await  this.delay(400);           
      c.classList.add('in');
    }

  }

}
