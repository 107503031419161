import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ScChatbotTriggerButtonComponent } from '../trigger-button/trigger-button.component';
import { environment } from 'src/environment.prod';

@Component({
  selector: 'sc-chatbot-overlay-multi-chat',
  templateUrl: './overlay-multi-chat.component.html',
  styleUrls: ['./overlay-multi-chat.component.scss']
})
export class OverlayMultiChatComponent {

  @ViewChild("chatbotOverlay", { static: true })
  chatbotOverlay: ElementRef;

  @ViewChild(ScChatbotTriggerButtonComponent, { static: true })
  public trigger: ScChatbotTriggerButtonComponent;

  @Input()
  chats : any = [];

  isActive = false;

  selectedChat : any = null;  

  toggle() {

    if (this.isActive) 
    {
      this.hide();
    }
    else
    {
      this.show();
    }
  }

  show() {   

    this.trigger.stopPulse();
    this.trigger.state = 'close';
    let chatbotOverlay = this.chatbotOverlay.nativeElement; 
    chatbotOverlay.classList.add('is-active');
    this.isActive = true;
  }

  showChat(chat) {
    if (chat && chat.Id != this.selectedChat?.Id) {
      this.selectedChat = chat;
    }

    var chatContainedInChats = this.chats.some(c => c.Id == chat.Id);
    if (!chatContainedInChats) this.chats.splice(0, 0, chat);
  }

  showOverview()
  {
    this.selectedChat = null;
  }

  hide() {
    let chatbotOverlay = this.chatbotOverlay.nativeElement;
    this.trigger.state = 'chat';
    this.trigger.badge = 0;
    chatbotOverlay.classList.remove('is-active');    
    this.isActive = false;
  }
}
