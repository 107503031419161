<app-jobsite-topbar></app-jobsite-topbar>

<div class="d-flex flex-column mh-100vh bg-white jobsite-wrapper" [class.toolbar]="showToolbar" [class.comment-mode]="isInCommentMode" *ngIf="project">

  <app-jobsite-header #header [job]="project"></app-jobsite-header>  

  <app-jobsite-nav [job]="project"></app-jobsite-nav>

  <div class="bg-white">

    <section id="aufgaben" scAnchorTarget="aufgaben">

      <div class="container">

        <div class="row">

          <div class="col-12 col-lg-10 offset-lg-1">

            <h2 class="mb-5 mb-lg-6">
              <span>{{ 'Ihre Aufgaben' | localize }}</span>
            </h2>

            <div class="row">

              <div class="col-12 order-1 col-md-12 col-lg-4">

                <app-ul-reveal #taskTags ulClass="list-group-flush list-group list-type-bullet mb-5 d-md-grid">
                  <li *ngFor="let item of project?.Tags" class="list-group-item">{{item}}</li>
                </app-ul-reveal>

              </div>

              <div class="col-12 order-2 col-md-12 col-lg-7 offset-lg-1">
                <app-typewriting-paragraph #taskDescription [typeSpeed]="7" [text]="project?.Description"></app-typewriting-paragraph>
              </div>

            </div>

          </div>

        </div>

      </div>

    </section>

    <section id="project-video" *ngIf="project.VideoUri != null">

      <div class="container">

        <div class="mt-4 mt-lg-0">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <app-video video-uri="{{ project.VideoUri }}"></app-video>
            </div>
          </div>
        </div>

      </div>

    </section>

    <section id="projects" *ngIf="project?.ProjectExamples?.length > 0 && !project.VideoUri">

      <div class="container">

        <div class="row">

          <div class="col-12 col-lg-10 offset-lg-1 blob">

            <img src="assets/blobs/blob-4.svg" />

          </div>

        </div>

      </div>

    </section>

    <section id="gehalt-und-benefits" scAnchorTarget="gehalt-und-benefits">
      <div id="gehalt">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-10 offset-lg-1">
              <div class="row align-items-md-center">
                <div class="col-12 col-md-5 mb-md-6">

                  <h2>
                    <span *ngIf="project?.EmploymentType != 'Freelancer'">{{ 'Ihr Gehalt' | localize }}</span>
                    <span *ngIf="project?.EmploymentType == 'Freelancer'">{{ 'Ihr Honorar' | localize }}</span>
                  </h2>

                  <p *ngIf="!isSkillconomy() && project?.EmploymentType != 'Freelancer'">
                    {{'Mithilfe unserer umfassenden Jobdatenbank schätzen wir für Sie ab, in welchem Bereich Ihr zukünftiges Zielgehalt liegen wird.' | localize }}
                  </p>

                  <p *ngIf="!isSkillconomy() && project?.EmploymentType == 'Freelancer'">
                    {{'Mithilfe unserer umfassenden Jobdatenbank schätzen wir für Sie ab, in welchem Bereich Ihr zukünftiges Honorar liegen wird.' | localize }}
                  </p>

                  <p *ngIf="isSkillconomy()">
                    {{ 'Hier siehst du vorab die grobe Gehaltsrange der Position. Lass uns gerne im persönlichen Gespräch über das zu dir passende Gesamtpaket sprechen.' | localize }}
                  </p>

                </div>

                <div class="col-12 col-md-6 offset-md-1">
                  <app-image-reveal #salaryReveal>
                    <app-jobsite-salary-chart minValue="{{project?.Compensation.MinSalary | number}} {{ project?.Compensation.SalaryCurrency }}" maxValue="{{project?.Compensation.MaxSalary | number}} {{ project?.Compensation.SalaryCurrency }}"></app-jobsite-salary-chart>
                  </app-image-reveal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="benefits">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-10 offset-lg-1">
              <h2 class="mt-6 mt-md-4">
                <span>{{ 'Ihre Benefits' | localize }}</span>
              </h2>
              <app-ul-reveal #benefits ulClass="list-group-flush list-group list-type-icon d-md-icon-grid mt-5">
                <li *ngFor="let benefitId of project?.BenefitIds" class="list-group-item">                  
                  <app-benefit [id]="benefitId"></app-benefit>
                </li>
              </app-ul-reveal>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="arbeitsumfeld" scAnchorTarget="arbeitsumfeld">

      <div class="container">

        <div class="row">

          <div class="col-12 col-lg-10 offset-lg-1">

            <h2 class="mb-5 mb-lg-6">
              <span>{{ 'Ihr Arbeitsumfeld' | localize }}</span>
            </h2>

            <div class="row">

              <div class="col-12 col-lg-4">

                <h6 class="mb-4 mb-lg-4">
                  <span>{{ 'Ihre Vorteile' | localize }}</span>
                </h6>

                <app-ul-reveal #workplaceHighlights ulClass="list-group-flush list-group list-type-icon d-md-grid">
                  <li *ngFor="let benefit of project?.Highlights" class="list-group-item">
                    <span class="fa-icon">
                      <i class="fa-sharp fa-solid fa-check"></i>
                    </span>
                    <span>{{benefit}}</span>
                  </li>
                </app-ul-reveal>

              </div>

              <div class="col-12 col-lg-7 offset-lg-1">

                <div class="slider mt-4 mt-lg-0">

                  <div *ngIf="project?.Impressions?.length > 0 && isBrowser">
                    <sc-slider #workplaceSlider>
                      <div *ngFor="let item of project?.Impressions">
                        <li class="slide">
                          <figure>
                            <picture>
                              <app-image-reveal #impressionImage>
                                <img [src]="item.ImageUri" height="800" width="800" class="img-fluid">
                              </app-image-reveal>
                              <!-- <img src="{{item.ImageUri}}" /> -->
                            </picture>
                            <figcaption class="is-muted"><strong *ngIf="item.Description != ''">{{item.Title}}</strong>
                              <app-typewriting-paragraph #impressionText [typeSpeed]="4" [text]="item.Description"></app-typewriting-paragraph>
                            </figcaption>
                          </figure>
                        </li>
                      </div>

                    </sc-slider>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

      <app-jobsite-map *ngIf="isBrowser && !project.IsMapHidden" id="standorte" [job]="project"></app-jobsite-map>

    </section>

    <section id="unternehmen" scAnchorTarget="unternehmen">

      <div class="container">

        <div class="row">

          <div class="col-12 col-lg-10 offset-lg-1 mb-5">

            <h2 class="mb-5 mb-lg-6">{{ 'Unternehmen' | localize }}</h2>

            <div class="row">

              <div class="col-12 col-lg-4 mb-6">

                <h6 class="mb-5">
                  <span>{{ 'Ihre Vorteile' | localize }}</span>
                </h6>

                <app-ul-reveal #companyHighlights ulClass="list-group-flush list-group list-type-icon d-md-grid">
                  <li *ngFor="let highlight of project.Company.Highlights" class="list-group-item">
                    <span class="fa-icon">
                      <i class="fa-sharp fa-solid fa-check"></i>
                    </span>
                    <span>{{highlight}}</span>
                  </li>
                </app-ul-reveal>

              </div>
              <!-- end .col-12 -->

              <div class="col-12 col-lg-7 offset-lg-1">

                <h6 class="mb-5">{{ project?.Company.Name }}</h6>

                <div *ngIf="project.Company.VideoUri != null" class="mb-5">
                  <app-video video-uri="{{ project.Company.VideoUri }}"></app-video>
                </div>

                <app-typewriting-paragraph #companyDescription [typeSpeed]="7" [text]="project?.Company.Description"></app-typewriting-paragraph>                

              </div>

            </div>

          </div>

        </div>

      </div>

    </section>

    <section class="sc-cta mb-8">
      <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column">
            <button class="btn btn-lg btn-round btn-primary" (click)="showInitialChatbot()">
              <i class="fa-sharp fa-solid fa-circle-chevron-right me-2"></i>
              Ist das Angebot interessant?
            </button>
          </div>
        </div>
      </div>
    </section>

    <sc-chatbot-overlay *ngIf="isBrowser" #chatbotOverlay [chat]="chat" [requestShow]="showChat" (chatEnded)="handleEndOfChat($event)"></sc-chatbot-overlay>

  </div>

  <app-footer class="mt-auto"></app-footer>

  <app-jobsite-toolbar [project]="project" [class.in]="showToolbar" [(isInCommentMode)]="isInCommentMode"></app-jobsite-toolbar>

</div>

<div class="card status-card box-shadow-lg" [class.in]="isRevealing">
  <div class="card-body">
    <svg class="loader-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
      <path fill="#030612" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
        <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite" />
      </path>
    </svg>

    <strong>Die Jobsite wird erstellt...</strong>
  </div>
</div>
