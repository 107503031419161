  <div class="card-image-top" *ngIf="pool.Image">
    <img class="img-fluid"  *ngIf="pool.Image" [src]="pool.Image | imageSize : 1200 : 395" />
  </div>

  
  <div *ngIf="pool">
  
    <div class="row mb-6 align-items-center">
  
      <div class="col">
        <a routerLink="/pools" class="link-arrow left">Pools</a>
      </div>
  
      <div class="col-auto pe-0">
        <app-pool-base-user-modal [pool]="pool"></app-pool-base-user-modal>
      </div>
  
    </div>
  
    <div class="row">
      <div class="col-12 col-lg-8">
        <h1 class="d-inline-block mb-1">{{ pool.Name }}</h1>
        <div class="pool-subline mb-5">{{ pool.Description }}</div>
        <div class="pool-stats">
            <i class="me-2 fa-solid fa-users"></i> {{ pool.CandidateCount }} Kandidat:innen
        </div>
      </div>        
    </div>
  </div>