import { Directive, ElementRef, OnInit, HostListener, AfterViewInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';

@Directive({
  selector: '[autoresizeTextarea]'
})
export class AutoresizeTextareaDirective implements AfterViewInit {

  @Input()
  set ngModel(val) {
    this.resize();
  }

  constructor(private host: ElementRef) { }

  ngAfterViewInit() {
    this.host.nativeElement.style.overflowY = 'hidden';
    this.host.nativeElement.style.resize = 'none';
    this.host.nativeElement.setAttribute('rows', 1);
    this.resize();
  }

  resize() {

    setTimeout(() => {
      var el = this.host.nativeElement;
      var nativeValue = this.host.nativeElement.value;

      el.style.height = 'auto';
      if (nativeValue) {
        el.style.height = el.scrollHeight + 'px';
      }
    }, 10);

  }

}
