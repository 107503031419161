import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, throttleTime, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScScrollService {

  offset: number = 70;

  scrollY: number = 0;

  $scrollYChange = new Subject<any>();

  $updateRequests: Subject<any> = new Subject<any>();

  $spy: Subject<any> = new Subject<any>();

  activeItemId: string;

  disableSpy: boolean;

  elementsInViewport = [];

  constructor(@Inject(PLATFORM_ID) platformId: string, @Inject(DOCUMENT) private document: any) {

    var isBrowser = isPlatformBrowser(platformId);

    if (!isBrowser) {
      console.warn("ScScrollService does not work with SSR!");
      return;
    }

    merge(
      fromEvent(document, 'scroll'),
      fromEvent(document, 'resize'),
      this.$updateRequests)
      .pipe(throttleTime(100, undefined, { leading: true, trailing: true }))
      .pipe(filter(() => !this.disableSpy))
      .subscribe(() => {
        this.spyTargets();
        this.spyElementsWithViewportHooks();
        this.readScrollY();
      });


    this.readScrollY();
  }

  readScrollY() {
    var newValue = this.getScrollY();
    var valueChanged = newValue != this.scrollY;
    this.scrollY = newValue;
    if (valueChanged) this.$scrollYChange.next(newValue);
  }

  requestUpdate() {
    this.$updateRequests.next();
  }

  private spyTargets() {

    var elements = document.querySelectorAll('[anchor]');
    var totalHeight = document.documentElement.scrollHeight;
    var viewportHeight = window.innerHeight;
    var viewportTop = window.scrollY;
    var scrollPercentage = viewportTop / (totalHeight - viewportHeight);
    var activeLineY = scrollPercentage * totalHeight;

    var minOffset = 100000;
    var minOffsetElement = null;

    for (var i = 0; i < elements.length; i++) {

      var element = elements[i];
      var rect = element.getBoundingClientRect();
      var viewportElementTop = rect.top;
      var viewportElementBottom = rect.bottom;

      var isOutsideViewport = viewportElementTop > window.innerHeight || viewportElementBottom < this.offset;
      if (isOutsideViewport) continue;

      var absoluteElementTop = viewportElementTop + viewportTop;
      var absoluteElementBottom = viewportElementBottom + viewportTop;
      if (absoluteElementTop <= activeLineY && absoluteElementBottom >= activeLineY) {
        minOffset = 0;
        minOffsetElement = element;
        break;
      }

      var elementOffsetTop = Math.abs(absoluteElementTop - activeLineY);
      var elementOffsetBottom = Math.abs(absoluteElementBottom - activeLineY);
      var elementOffset = Math.min(elementOffsetTop, elementOffsetBottom);

      if (elementOffset < minOffset) {
        minOffset = elementOffset;
        minOffsetElement = element;
      }
    }

    if (minOffsetElement == null) {
      this.$spy.next({ event: 'leftViewport', id: this.activeItemId });
      this.activeItemId = null;
      return;
    }

    var id = minOffsetElement.getAttribute("anchor");

    if (this.activeItemId == id) return;
    this.activeItemId = id;

    this.$spy.next({ event: 'enteredViewport', id: id });
  }

  private spyElementsWithViewportHooks() {

    var elements = document.querySelectorAll('*[scscrollspy]');

    for (var i = 0; i < elements.length; i++) {

      var element = elements[i];
      var rect = element.getBoundingClientRect();
      var viewportElementTop = rect.top;
      var viewportElementBottom = rect.bottom;

      var isOutsideViewport = viewportElementTop > window.innerHeight || viewportElementBottom < this.offset;
      if (isOutsideViewport) {
        var index = this.elementsInViewport.indexOf(element);
        if (index >= 0) {
          this.elementsInViewport.splice(index, 1);
          this.$spy.next({ event: 'leftViewport', element: element });
        }
      }
      else {
        var index = this.elementsInViewport.indexOf(element);
        if (index < 0) {
          this.elementsInViewport.push(element);
          this.$spy.next({ event: 'enteredViewport', element: element });
        }
      }
    }
  }

  scrollTo(id: string,
    anchorReferencePosition: 'top' | 'bottom' | 'middle' = 'top',
    anchorTargetPosition: 'top' | 'bottom' | 'middle' = 'top') {

    var targets = document.querySelectorAll('[anchor="' + id + '"]');
    if (targets.length === 0) {
      console.log('No matching anchor target found');
    }

    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    var targetPositionOffset = 0;
    if (anchorTargetPosition == 'middle') targetPositionOffset = vh / 2.0;
    if (anchorTargetPosition == 'bottom') targetPositionOffset = vh;
    var totalOffset = this.offset + targetPositionOffset;

    var target = targets[0];
    var rect = target.getBoundingClientRect();
    var targetReferencePosition = rect.top;
    if (anchorReferencePosition == 'middle') targetReferencePosition = (rect.top + rect.bottom) / 2;
    if (anchorReferencePosition == 'bottom') targetReferencePosition = rect.bottom;

    var top = targetReferencePosition - totalOffset + window.scrollY;

    this.disableSpy = true;
    this.activeItemId = id;
    this.$spy.next({ event: 'enteredViewport', id: id });

    window.scroll({
      top: top,
      left: 0,
      behavior: 'smooth'
    });

    fromEvent(document, 'scroll').pipe(debounceTime(50)).subscribe(d => {
      this.disableSpy = false;
    });
  }

  scrollToElement(element: ElementRef) {

    var rect = element.nativeElement.getBoundingClientRect();
    var top = rect.top - this.offset + window.scrollY;

    var initialPosition = window.scrollY;
    var SCROLL_DURATION = 30;
    var step_x = Math.PI / SCROLL_DURATION;
    var step_count = 0;

    requestAnimationFrame(step);

    function step() {
      if (step_count < SCROLL_DURATION) {
        requestAnimationFrame(step);
        step_count++;
        window.scrollTo(0, initialPosition + top * 0.25 * Math.pow((1 - Math.cos(step_x * step_count)), 2));
      }
    }
  }

  getScrollY() {

    var parent = this.getParent();

    if (parent.pageYOffset !== undefined) {
      return parent.pageYOffset;
    }
    return parent.scrollTop || 0;
  }

  getParent() {

    if (!this.document) {
      return;
    }

    if (document.documentElement && document.documentElement.scrollTop) {
      return this.document.documentElement;
    }

    if (this.document.body && document.body.scrollTop) {
      return this.document.body;
    }

    if (this.document.body && this.document.body.parentNode.scrollTop) {
      return this.document.body.parentNode;
    }

    return this.document;
  }
}
