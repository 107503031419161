<div class="row justify-content-center align-items-end">

    <div class="auth-card col-auto">
  
      <div class="auth-blob">
        <img src="assets/blobs/blob-1.svg" alt="skillconomy Blob" class="img-fluid">
      </div>
  
      <div class="card box-shadow">
  
        <div class="card-body">
  
          <div class="logo">
            <img src="assets/logo/skillconomy-logo-blau.svg" alt="skillconomy Logo" class="img-fluid">
          </div>

          <h2 class="text-center mb-5">Nicht registriert</h2>
  
          <form>
  
            <div class="row align-items-center">
              <div class="col">
                <div class="alert alert-error mt-4" role="alert">
                  <p class="alert-heading mb-0">
                    Die Anmeldung war leider nicht möglich. Bitte wenden Sie sich an Ihren Ansprechpartner bei skillconomy, um einen 
                    Link zur Registrierung im Portal zu erhalten!
                  </p>
                </div>
              </div>

              
            </div>  
            
            <div class="d-flex justify-content-center">
                <a class="btn btn-primary mt-4" href="mailto:info@skillconomy.com?subject=Anfrage Registrierungslink&body=Bitte senden Sie mir einen Link zur Registrierung im skillconomy-Portal an {{ eMail }}.">               
                    Link anfragen
                  </a>
              </div>
            
          </form>
  
        </div>
  
        <div class="card-footer text-center">
          <small>Sie haben bereits einen Account? <a (click)="goToLogin()">Anmelden</a></small>
        </div>
  
      </div>
  
    </div>
    
    <div class="auth-illu col-auto">
      <img src="assets/illus/skillconomy-bot-waving.svg" alt="skillconomy Illu Bot" class="img-fluid">
    </div>
  
  </div>