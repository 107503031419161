import { Component, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ScUploadService, ScFileButtonComponent } from '@sc-ui';


@Component({
  selector: 'sc-chatbot-input-file-upload',
  templateUrl: './input-file-upload.component.html',
  styleUrls: ['./input-file-upload.component.scss']
})
export class ScChatbotInputFileUploadComponent  {  

  @Input()
  message : any;

  @Output() 
  submit: EventEmitter<any> = new EventEmitter();

  isUploading: boolean = false;  
  uploadProgress: number = 0;
  uploadSuccess: boolean = false;
  uploadError: string = null;
  fileTooLarge: boolean = false;

  @ViewChild("fileinput", { static : true }) fileInput : ScFileButtonComponent;
  
  constructor(private uploadService: ScUploadService) {}

  upload(selectedFiles) {
    this.fileTooLarge = false;

    if(selectedFiles.length < 1) return;
    const fileToUpload = selectedFiles[0];
    
    if(fileToUpload.size / 1024 > this.message.Payload.MaximumFileSize){
      this.fileTooLarge = true;
      return;
    } 

    this.isUploading = true;
    this.uploadSuccess = false;
    this.uploadError = null;

    this.uploadService.upload(
      selectedFiles, 
      (res: any) => {

        var message = {
          Direction: "UserToBot",
          Type: 'FileUpload',
          Payload: {
            Uri: res.Uri,
            FileName: fileToUpload.name
          }
        };

        this.uploadSuccess = true;

        setTimeout( ()=>{          
          this.submit.emit(message);
        }, 100);
        
      },
      (percentage : number) => { 
        this.uploadProgress = percentage;
      },
      (error : any) => { 
        this.uploadError = error.message;
      }
    ); 
  }

  retryAfterError() {
    this.fileInput.reset();
    this.uploadError = null;
    this.uploadProgress = 0;
    this.uploadSuccess = false;
    this.isUploading = false;
  }

}
