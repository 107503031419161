import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private _channels = [];

  private _connection: HubConnection;

  private onEvent$: Subject<any> = new Subject<any>();

  public disconnected : Subject<any> = new Subject<any>();

  public connected : Subject<any> = new Subject<any>();

  constructor()
  {   
    this.connected.subscribe(() => {
      this._channels.forEach(c => {
        console.log('(Re)subscribing to ' + c);
        this._connection.invoke('subscribe', c).then(() => {
          console.log('Subscribed to ' + c);
        });        
      });
    });

    this.connect();
  }

  public channel(channel: string) : Observable<any> {    
      
    if (this._channels.indexOf(channel) < 0) 
    {
      this._channels.push(channel);

      if (this._connection) {
        console.log('Subscribing to ' + channel);
        this._connection.invoke('subscribe', channel).then(() => {
          console.log('Subscribed to ' + channel);
        });    
      }
    }
    
    return this.onEvent$.pipe(filter(e => e.Channel == channel));
  }

  public unsubscribeAll()
  {
    this._channels.length = 0;
    this._connection.invoke('unsubscribeAll');
  }

  private connect() {
    
    if (this._connection) return; 

      var connection = new HubConnectionBuilder()
        .withUrl("https://skicoeventhub.azurewebsites.net/hub")
        .configureLogging(LogLevel.Information)  
        .build();

      // connection.keepAliveIntervalInMilliseconds = 2500;

      connection.on("Send", d => {
        console.log(d);
        this.onEvent$.next(d);
      });

      connection.onclose(() => {
        this._connection = null;
        this.disconnected.next();
        console.log('Event hub connection has been closed. Reconnecting...');        
        this.connect();
      });

      connection.start()
        .then(() => {
          this._connection = connection;
          this.connected.next();          
          console.log('Event hub connection started');
        })
        .catch(err => {
          console.log('Event hub connection failed, retrying in 3000ms');          
          setTimeout(() => this.connect(), 3000);
        }
      );
  }
}
