import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ScProgressbarComponent implements OnInit {

  @Input('small') styleSmall: boolean = true;
  @Input('value') progressValue: number = 0;
  @Input('success') progressSuccess: boolean = false;
  @Input('error') progressError: string = undefined;

  @Output() retryClick = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  retryAfterError(event) {
    event.preventDefault();
    this.retryClick.emit();
  }

}
