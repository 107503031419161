import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Siema from 'siema';

@Component({
  selector: 'sc-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements AfterViewInit {

  @ViewChild('slider') 
  slider;

  siema : any;

  id: string;

  options: any;

  slideCount: number = 0;

  currentIndex = 0;

  indices = [0];

  @Input()
  paginationAlignment = 'center';

  @Input()
  showPagination : boolean = true;

  constructor(private changeRef: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    
    this.loadPagination();
    
    this.options = {
      selector: this.slider.nativeElement,
      duration: 400,
      easing: 'ease-out',
      draggable: true,
      multipleDrag: false,
      threshold: 20,
      loop: true,
      onInit: () => { },
      onChange: () => { this.currentIndex = this.siema.currentSlide },
    };

    this.loadPagination();

    this.siema = new Siema(this.options);

  }

  loadPagination()
  {
    var children = this.slider.nativeElement.childNodes;
    var indices = [];
    var currentIndex = 0;

    
    for(var i = 0; i < children.length; i++)
    {
      var c = children[i];
      console.log(c.nodeName);
      if (c.nodeName == 'DIV') {
        indices.push(currentIndex);
        currentIndex++;
      }

    }
    this.slideCount = indices.length;
    this.indices = indices;
    this.changeRef.detectChanges();
  }

  prev() {
    this.siema.prev();
  }

  next() {
    this.siema.next();
  }

  goto(slideIndex) {
    this.siema.goTo(slideIndex);
  }

}
