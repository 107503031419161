import { Directive, Input, HostListener, ElementRef } from '@angular/core';
import { OnInit } from '@angular/core';
import { ScScrollService } from '../services/scroll.service';

@Directive({
  selector: '[scAnchorLink]'
})
export class ScAnchorLinkDirective implements OnInit {

  isActive : boolean;

  @Input('scAnchorLink')
  id: string;

  @Input()
  anchorReferencePosition : 'top' | 'bottom' | 'middle' = 'top';

  @Input()
  anchorTargetPosition : 'top' | 'bottom' | 'middle' = 'top';

  @HostListener('click', ["$event"])
  onClick(ev) {
    this.navigate();
  }

  constructor(private scrollService: ScScrollService,
    private el: ElementRef) {

  }

  navigate() {
    this.scrollService.scrollTo(this.id, this.anchorReferencePosition, this.anchorTargetPosition);
  }

  ngOnInit() {
    this.scrollService.$spy.subscribe(e => {
     
      if (e.id != this.id && e.event === 'enteredViewport') this.isActive = false;
      if (e.id == this.id && e.event === 'enteredViewport') this.isActive = true;
      if (e.id == this.id && e.event === 'leftViewport') this.isActive = false; 

      if (this.isActive)
        this.el.nativeElement.classList.add("active");
      else
        this.el.nativeElement.classList.remove("active");
    });

    this.scrollService.requestUpdate();
  }

  

}
