import { NgModule } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MentionModule } from 'angular-mentions';
import { RouterModule } from '@angular/router';
import { GmapsAutocompleteDirective } from './directives/gmaps-autocomplete.directive';
import { CommonModule, registerLocaleData } from '@angular/common';

import localeDe from '@angular/common/locales/de';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ActionBannerComponent } from './components/action-banner/action-banner.component';
import { ActivityFeedComponent } from './components/activity-feed/activity-feed.component';
import { ActivityItemCommentComponent } from './components/activity-item-comment/activity-item-comment.component';
import { ActivityItemFileComponent } from './components/activity-item-file/activity-item-file.component';
import { ActivityItemHeaderComponent } from './components/activity-item-header/activity-item-header.component';
import { ActivityItemHighlightComponent } from './components/activity-item-highlight/activity-item-highlight.component';
import { ActivityItemLinkComponent } from './components/activity-item-link/activity-item-link.component';
import { ActivityItemSimpleComponent } from './components/activity-item-simple/activity-item-simple.component';
import { AutoresizeTextareaDirective } from './directives/autoresize-textarea.directive';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AvatarListComponent } from './components/avatar-list/avatar-list.component';
import { BypassSecurityTrustPipe } from './pipes/bypass-security-trust.pipe';
import { ConfettiComponent } from './components/confetti/confetti.component';
import { CopyClickDirective } from './directives/copy-click.directive';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FallbackSrcDirective } from './directives/fallback-src.directive';
import { FormComponent } from './components/form/form.component';
import { FormRowComponent } from './components/form-row/form-row.component';
import { HeadroomComponent } from './components/headroom/headroom.component';
import { HumanizedDateDiffPipe } from './pipes/humanized-date-diff.pipe';
import { HumanizedDatePipe } from './pipes/humanized-date.pipe';
import { HumanizedFileSizePipe } from './pipes/humanized-file-size.pipe';
import { HumanizedTimeSpanPipe } from './pipes/humanized-time-span.pipe';
import { ImageSelectComponent } from './components/image-select/image-select.component';
import { ImageSelectCropComponent } from './components/image-select-crop/image-select-crop.component';
import { ImageSizePipe } from './pipes/image-size.pipe';
import { ItemFileComponent } from './components/item-file/item-file.component';
import { ItemHighlightComponent } from './components/item-highlight/item-highlight.component';
import { ItemLinkComponent } from './components/item-link/item-link.component';
import { MapMarkerRadiusComponent } from './components/map-marker-radius/map-marker-radius.component';
import { ModalBodyComponent } from './components/modal-body/modal-body.component';
import { ModalFooterComponent } from './components/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { ModalWizardComponent } from './components/modal-wizard/modal-wizard.component';
import { ModalWizardPageComponent } from './components/modal-wizard-page/modal-wizard-page.component';
import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ObjectKeysPipe } from './pipes/object-keys.pipe';
import { ScAnchorLinkDirective } from './directives/anchor-link.directive';
import { ScAnchorTargetDirective } from './directives/anchor-target.directive';
import { ScCollapsibleComponent } from'./components/collapsible/collapsible.component';
import { ScDismissModalsDirective } from './directives/dismiss-modals.directive';
import { ScFileButtonComponent } from './components/file-button/file-button.component';
import { ScFileIconDirective } from './directives/file-icon.directive';
import { ScJsonLdComponent } from './components/json-ld/json-ld.component';
import { ScOnEnterDirective } from './directives/on-enter.directive';
import { ScProgressbarComponent } from './components/progressbar/progressbar.component';
import { ScScrollSpyDirective } from './directives/scroll-spy.directive';
import { ScShowToggleDirective } from './directives/show-toggle.directive';
import { ScSwitchComponent } from './components/switch/switch.component';
import { ScuiModalComponent } from './components/modal/modal.component';
import { ShowModalDirective } from './directives/show-modal.directive';
import { SliderComponent } from './components/slider/slider.component';
import { StringifyJsonPipe } from './pipes/stringify-json.pipe';
import { ToggleOnClickDirective } from './directives/toggle-on-click.directive';
import { TopbarTemplateDirective } from './directives/topbar-template.directive';
import { TruncatePipe } from './pipes/truncate.pipe';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserListSelectionComponent } from './components/user-list-selection/user-list-selection.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { FaComponent } from './components/fa/fa.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { RadioComponent } from './components/radio/radio.component';
import { AutosizeTextareaComponent } from './components/autosize-textarea/autosize-textarea.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { AgmCoreModule } from '@agm/core';
import { DatepickerInlineComponent } from './components/date-picker-inline/date-picker-inline.component';
import { BackOnClickDirective } from './directives/back-on-click.directive';
import { FixPositionDirective } from './directives/fix-position.directive';
import { ImageRevealComponent } from './components/image-reveal/image-reveal.component';
import { BenefitComponent } from './components/benefit/benefit.component';

registerLocaleData(localeDe);


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ImageCropperModule,
    MentionModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyBajkLHFeKGPwkvXBqq_GUb9CWv_nyar6g', libraries: ["places"], language: 'de' })
  ],
  declarations: [
    ActionBannerComponent,
    ActivityFeedComponent,
    ActivityItemCommentComponent,
    ActivityItemFileComponent,
    ActivityItemHeaderComponent,
    ActivityItemHeaderComponent,
    ActivityItemHighlightComponent,
    ActivityItemLinkComponent,
    ActivityItemSimpleComponent,
    AutoresizeTextareaDirective,
    AvatarComponent,
    AvatarListComponent,
    BypassSecurityTrustPipe,
    ConfettiComponent,
    CopyClickDirective,
    DatePickerComponent,
    DropdownComponent,
    FallbackSrcDirective,
    FallbackSrcDirective, 
    FormComponent,
    FormRowComponent,
    GmapsAutocompleteDirective,
    HeadroomComponent,
    HumanizedDateDiffPipe,
    HumanizedDatePipe,
    HumanizedFileSizePipe,
    HumanizedTimeSpanPipe,
    ImageSelectComponent,
    ImageSelectCropComponent,
    ImageSizePipe,
    ItemFileComponent,
    ItemHighlightComponent,
    ItemLinkComponent,
    MapMarkerRadiusComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    ModalWizardComponent,
    ModalWizardPageComponent,
    ObjectKeysPipe,
    ScAnchorLinkDirective,
    ScAnchorTargetDirective,
    ScCollapsibleComponent,
    ScDismissModalsDirective,
    ScFileButtonComponent,
    ScFileIconDirective,
    ScFileIconDirective, 
    ScJsonLdComponent,
    ScOnEnterDirective,
    ScProgressbarComponent,
    ScScrollSpyDirective,
    ScShowToggleDirective,
    ScShowToggleDirective, 
    ScSwitchComponent,
    ScuiModalComponent,
    ShowModalDirective,
    SliderComponent,
    StringifyJsonPipe,
    ToggleOnClickDirective,
    TopbarTemplateDirective,
    TruncatePipe,
    TruncatePipe,   
    UserListComponent,
    UserListSelectionComponent,
    WizardComponent,
    TopbarTemplateDirective,
    ObjectKeysPipe,
    ActionBannerComponent,
    DatePickerComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalBodyComponent,
    FormComponent,
    FormRowComponent,
    ImageSizePipe,
    FaComponent,
    CheckboxComponent,
    RadioComponent,
    AutosizeTextareaComponent,
    StarRatingComponent,
    DatepickerInlineComponent,
    BackOnClickDirective,
    FixPositionDirective,
    ImageRevealComponent,
    BenefitComponent
  ],
  exports: [
    ActionBannerComponent,
    ActivityFeedComponent,
    ActivityItemCommentComponent,
    ActivityItemFileComponent,
    ActivityItemHeaderComponent,
    ActivityItemHighlightComponent,
    ActivityItemLinkComponent,
    ActivityItemSimpleComponent,
    AutoresizeTextareaDirective,
    AvatarComponent,
    AvatarListComponent,
    BypassSecurityTrustPipe,
    ConfettiComponent,
    CopyClickDirective,
    DatePickerComponent,
    DropdownComponent,
    FallbackSrcDirective,
    FormComponent,
    FormRowComponent,
    GmapsAutocompleteDirective,
    HeadroomComponent,
    HumanizedDateDiffPipe,
    HumanizedDatePipe,
    HumanizedFileSizePipe,
    HumanizedTimeSpanPipe,
    HumanizedTimeSpanPipe,
    ImageSelectComponent,
    ImageSelectCropComponent,
    ImageSizePipe,
    ItemFileComponent,
    ItemHighlightComponent,
    ItemLinkComponent,
    MapMarkerRadiusComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    ModalWizardComponent,
    ModalWizardPageComponent,
    ObjectKeysPipe,
    ScAnchorLinkDirective,
    ScAnchorTargetDirective,
    ScCollapsibleComponent,
    ScDismissModalsDirective,
    ScFileButtonComponent,
    ScFileIconDirective,
    ScJsonLdComponent,
    ScOnEnterDirective,
    ScProgressbarComponent,
    ScScrollSpyDirective,
    ScShowToggleDirective,
    ScSwitchComponent,
    ScuiModalComponent,
    ShowModalDirective,
    SliderComponent,
    StringifyJsonPipe,
    ToggleOnClickDirective,
    TopbarTemplateDirective,
    TruncatePipe,
    UserListComponent,
    UserListSelectionComponent,
    WizardComponent,
    ImageSizePipe,
    FaComponent,
    CheckboxComponent,
    RadioComponent,
    AutosizeTextareaComponent,
    StarRatingComponent,
    DatepickerInlineComponent,
    BackOnClickDirective,
    FixPositionDirective,
    ImageRevealComponent,
    BenefitComponent
  ]
})
export class ScUiModule {

  constructor() {
    // library.add(fasCheck);
  }  
}

