import { Subject, fromEvent } from 'rxjs';
import { takeUntil, auditTime } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, Renderer2, ElementRef, Input, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScApiService, SliderComponent } from '@sc-ui';
import { Meta, TransferState, makeStateKey } from '@angular/platform-browser';
import { PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ChatsService, ScChatbotOverlayComponent } from '@sc-ui-chatbot';
import { GoogleAnalyticsService } from '@sc-ui';
import { LocalizationService } from 'src/services/localization.service';
import { JobsiteHeaderComponent } from './jobsite-header/jobsite-header.component';
import { TypewritingParagraphComponent } from 'src/components/typewriting-paragraph/typewriting-paragraph.component';
import { ImageRevealComponent } from 'refs/sc-ui/components/image-reveal/image-reveal.component';
import { UlRevealComponent } from 'src/components/ul-reveal/ul-reveal.component';

@Component({
  selector: 'app-jobsite',
  templateUrl: './jobsite.component.html',
  styleUrls: ['./jobsite.component.scss']
})
export class JobsiteComponent implements OnInit, OnDestroy, AfterViewInit {

  TravelIntensity = {
    0: 'Keine Reisetätigkeit',
    1: 'Geringfügige Reisetätigkeit',
    2: 'Mittlere Reisetätigkeit',
    3: 'Hohe Reisetätigkeit'
  }

  $destroy = new Subject();

  project: any;

  id: string;

  chat: any = null;

  isRevealing = false;

  isInCommentMode = false;

  initialChat: any = null;

  showToolbar = false;

  @ViewChild('header', { static: false })
  header: JobsiteHeaderComponent;

  @ViewChild(ScChatbotOverlayComponent)
  chatOverlay: ScChatbotOverlayComponent;

  @ViewChild('taskTags', { static: false })
  taskTags: UlRevealComponent;

  @ViewChild('taskDescription', { static: false })
  taskDescription: TypewritingParagraphComponent;

  @ViewChild('companyDescription', { static: false })
  companyDescription: TypewritingParagraphComponent;

  @ViewChild('salaryReveal', { static: false })
  salaryReveal: ImageRevealComponent;

  @ViewChild('companyHighlights', { static: false })
  companyHighlights: UlRevealComponent;

  @ViewChild('workplaceHighlights', { static: false })
  workplaceHighlights: UlRevealComponent;

  @ViewChild('benefits', { static: false })
  benefits: UlRevealComponent;

  @ViewChild('workplaceSlider', { static: false })
  workplaceSlider: SliderComponent;

  @ViewChildren('impressionImage')
  impressionImages: QueryList<ImageRevealComponent>;

  @ViewChildren('impressionText')
  impressionTexts: QueryList<TypewritingParagraphComponent>;

  isMobileBreakpoint: boolean;
  isBrowser: boolean;
  shouldReveal: boolean;

  isSkillconomy() { return this.project?.Company?.Id == 1 };

  constructor(
    private renderer2: Renderer2,
    private api: ScApiService,
    private chatService: ChatsService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: string,
    private state: TransferState,
    private localization: LocalizationService) {

    this.isBrowser = isPlatformBrowser(platformId);

  }

  ngOnInit() {

    this.route.queryParams.subscribe((params: any) => {
      if (params.reveal) {
        this.shouldReveal = true;
      }
    })

    this.route.params.subscribe((params: any) => {
      if (!params.id) return;
      this.id = params.id;
      this.load();
    });


    if (typeof window !== 'undefined') {
      fromEvent(window, 'resize')
        .pipe(auditTime(250))
        .subscribe(() => this.checkBreakpoint());
    }
  }

  load() {
    if (!this.id) return;


    this.api.get('projects/' + this.id + '/jobsite').subscribe((d: any) => {

      this.localization.setCulture(d.Locale);
      this.project = this.preprocessData(d);

      // this.generate();

      if (this.shouldReveal) {
        setTimeout(() => {
          this.reveal();
        });
      }
      else {
        setTimeout(() => {
          this.revealImmediate();
        });
      }

      if (this.isBrowser) {
        this.initChatbot();
      }

    }, err => {
      this.router.navigate(['/404']);
    });

  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async revealImmediate() {


    this.showToolbar = true;

    this.header.revealImmediate();
    this.taskTags.reveal();
    this.taskDescription.showText(false);
    this.salaryReveal.revealAnimated();
    this.benefits.reveal();
    this.workplaceHighlights.reveal();

    for (let index = 0; index < this.impressionImages.length; index++) {
      this.impressionImages.get(index).revealAnimated();
      this.impressionTexts.get(index).showText(false);
    }

    this.companyHighlights.reveal();
    this.companyDescription.showText(false);

  }

  async reveal() {

    this.isRevealing = true;

    await this.header.reveal();

    this.scrollTo('aufgaben');
    await this.delay(1000);
    this.taskTags.reveal();
    await this.taskDescription.type().toPromise();
    await this.delay(1000);

    this.scrollTo('gehalt-und-benefits');
    await this.delay(1000);
    this.salaryReveal.revealAnimated();
    await this.benefits.reveal();
    await this.delay(1000);

    this.scrollTo('arbeitsumfeld');
    await this.delay(1000);

    this.workplaceHighlights.reveal();

    for (let index = 0; index < this.impressionImages.length; index++) {
      this.impressionImages.get(index).revealAnimated();
      await this.impressionTexts.get(index).type().toPromise();
      await this.delay(500);

      if (index < this.impressionImages.length - 1)
        this.workplaceSlider.next();
      await this.delay(500);
    }

    this.scrollTo('unternehmen');
    await this.delay(1000);
    this.companyHighlights.reveal();
    await this.companyDescription.type().toPromise();
    await this.delay(1000);

    this.isRevealing = false;
    this.showToolbar = true;

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

  }

  initChatbot() {
    if (!this.id) return;
    this.chatService.getChat('projects/' + this.id + '/jobsite-chat').subscribe((c: any) => {
      this.chat = c;
      this.initialChat = c;
      if (this.chatOverlay?.trigger) {
        this.chatOverlay.trigger.slideIn();
        this.chatOverlay.trigger.badge = 1;
      }
    });
  }


  showInitialChatbot() {
    this.chat = this.initialChat;
    this.chatOverlay.show();
  }

  handleEndOfChat(chat) {
    if (chat != this.initialChat) {
      this.chatOverlay.hide();
      setTimeout(() => {
        this.chat = this.initialChat;
      }, 300);

    }
  }

  preprocessData(data: any) {
    data.TravelIntensity = this.TravelIntensity[data.TravelIntensity];
    return data;
  }

  checkBreakpoint() {
    if (this.isBrowser) {
      if (window.innerWidth <= 767.98) {
        this.renderer2.addClass(document.body, 'breakpoint-sm');
        this.isMobileBreakpoint = true;
      } else {
        this.renderer2.removeClass(document.body, 'breakpoint-sm');
        this.isMobileBreakpoint = false;
      }
    }
  }

  ngAfterViewInit() {
    this.checkBreakpoint();
  }

  ngOnDestroy() {
    this.$destroy.next();
  }

  scrollTo(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

}
