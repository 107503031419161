import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'sc-chatbot-message-scroll-container',
  templateUrl: './message-scroll-container.component.html',
  styleUrls: ['./message-scroll-container.component.scss']
})
export class MessageScrollContainerComponent implements OnInit {  

  private lastScrollHeight = 0;
  private lastScrollTop = 0;

  @ViewChild('container')
  container : ElementRef;

  @Output('scrolledToNewest')
  public scrolledToNewest = new EventEmitter<any>();

  @Output('scrolledToOldest')
  public scrolledToOldest = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  public scrollToNewest()
  {
    var el = this.container.nativeElement;
    el.scrollTo({ top: 0, behavior: "smooth" });
  }

  public lockToEnd(duration)
  {
    var el = this.container.nativeElement;
    var isLocked = true;
    setTimeout(() => isLocked = false, duration);

    var nextFrame = () => requestAnimationFrame(() => {
      var offsetHeight = el.offsetHeight;
      var scrollHeight = el.scrollHeight;
      el.scrollTop = scrollHeight - offsetHeight;
      if (isLocked) nextFrame();
    });

    nextFrame();
  }

  public isScrollable() : boolean
  {
    var el = this.container.nativeElement;
    var isScrollable = el.clientHeight < el.scrollHeight;
    return isScrollable;
  }

  public persistScrollPosition() {
    console.log('persisting scroll top');
    this.lastScrollTop = this.container.nativeElement.scrollTop;
    this.lastScrollHeight = this.container.nativeElement.scrollHeight;
    // this.lastScrollTop = this.container.nativeElement.scrollTop;
  }

  public restoreScrollPosition() {
    var currentScrollHeight = this.container.nativeElement.scrollHeight;
    var newScrollDistance = this.lastScrollTop + (currentScrollHeight - this.lastScrollHeight);
    this.container.nativeElement.scrollTop = newScrollDistance;
    // this.container.nativeElement.scrollTop = this.lastScrollTop;
  }

  onScroll() {      
    if (this.isScrolledToNewest()) this.scrolledToNewest.emit(null);;
    if (this.isScrolledToOldest()) this.scrolledToOldest.next();;    
  }

  public isScrolledToNewest()
  {
    // var element = this.container.nativeElement;
    // return element.scrollTop <= 5;

    var element = this.container.nativeElement;
    var offsetHeight = element.offsetHeight;
    var scrollHeight = element.scrollHeight;
    return scrollHeight - (offsetHeight + element.scrollTop) == 0;
  }

  public isScrolledToOldest()
  {
    var element = this.container.nativeElement;
    return element.scrollTop == 0;
    // var element = this.container.nativeElement;
    // var height = element.offsetHeight;
    // var scrollHeight = element.scrollHeight;
    // return height === scrollHeight + element.scrollTop;
  }

}
