import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BodyService {

  private classNames = [];

  addClass(className : string) {
    if (this.classNames.indexOf(className) >= 0) return;
      this.document.body.classList.add(className);      
      this.classNames.push(className);
  }

  clearClasses() {
    this.classNames.forEach(c => this.document.body.classList.remove(c));
    this.classNames = [];
  }  

  constructor(
    @Inject(DOCUMENT) private document: any) { }
}
