import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-project-activities',
  templateUrl: './project-activities.component.html',
  styleUrls: ['./project-activities.component.scss']
})
export class ProjectActivitiesComponent implements OnInit {

  @Input() 
  project : any;

  activitiesUri : string;

  mentionsUri : string;

  constructor(public userService : UserService) { }

  ngOnInit(): void {
  }

  ngOnChanges()
  {
    if (!this.project) return;
    this.activitiesUri = `projects/${this.project.Id}/activities`;
    this.mentionsUri = `projects/${this.project.Id}/mentions`;
  }

}
