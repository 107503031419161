<sc-activity-item-simple [activity]="activity" [showProjectName]="showProjectName" *ngIf="condensed">
    hat 
    <a routerLink="/projects/{{ activity.Project?.Id }}/candidates/{{ activity.Candidate?.Id }}">
        <strong>{{ activity.Candidate?.FirstName }} {{ activity.Candidate?.LastName }}</strong>
    </a> 
    als 'Eingestellt' markiert
</sc-activity-item-simple>

<sc-activity-item-highlight icon="fa-sharp fa-solid fa-circle-check" [activity]="activity" [showProjectName]="showProjectName" *ngIf="!condensed">
    hat 
    <a routerLink="/projects/{{ activity.Project?.Id }}/candidates/{{ activity.Candidate?.Id }}">
        <strong>{{ activity.Candidate?.FirstName }} {{ activity.Candidate?.LastName }}</strong>
    </a>  
    als 'Eingestellt' markiert
</sc-activity-item-highlight>
