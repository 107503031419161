import { Injectable } from '@angular/core';
import { HttpRequest, HttpEventType, HttpHeaders, HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ScUploadService {

  constructor(private http: HttpClient) { }

  getHeaders(): HttpHeaders {

    return new HttpHeaders()
      .append('Accept', 'application/json')
      .append('Cache-Control', 'no-cache')
      .append('Pragma', 'no-cache')
      .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      .append('If-Modified-Since', '0')
      .append('ScAuthorization', 'up2beT7U9YSPX3u58xryvH8KDthYH6AaYYzV67AxtEZPTKZaUqLzMGK3M78GEbvbD9w3QxSMu2WXYiwi8GTdXXXLC9YGyDjaBkmqEw3zJ46dc2nBQdtAqm88h7uVWj5C9FvNRz99DFgdfSAWWjGwEVb4QakNVCYfeArNkwk7Y8hXk2fPqqG8L9ApQvCETJifXAL2kyhCk76qTTCSbaK8VaVqcdbkZVD7Uc7hRMB7A5b7Cx8GaQkukBAd62ptbY8G');
  }

  upload(files, onSuccess?: ((response : any) => void), onProgressChanged?: ((percentage: number) => void), onError?: ((error: any) => void)) {

    if (files.length === 0) return;
    const formData = new FormData();
    for (let file of files) formData.append(file.name, file);
    const uri = "https://assets.skillconomy.com/upload";
    // const uri = "https://localhost:44370/upload";

    const uploadReq = new HttpRequest("POST", uri, formData, {
      headers: this.getHeaders(),
      reportProgress: true
    });

    this.http.request(uploadReq).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round(100 * event.loaded / event.total);
          if (!onProgressChanged) return;
          onProgressChanged(progress);
        } else if (event.type === HttpEventType.Response) {
          const resp = event.body;
          if (!onSuccess) return;
          onSuccess(resp);
        }
      },
      (error: any) => {
        if (!onError) return;
        onError(error);
      }
    );
  }
}
