<a> 
  <div class="card card-sm">

    <div class="card-body">
      <div class="row align-items-center">
        <div class="col">
          <span class="mb-0">
            <ng-content></ng-content>
          </span>
        </div>
        <div class="col-auto">
          <span class="arrow right"></span>
        </div>
      </div>
    </div>

  </div>
</a>