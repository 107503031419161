<div class="row" *ngIf="candidate">
  <div class="col-12">

    <section>

      <h3 class="mb-5">Berufserfahrung</h3>

      <p class="mb-4" *ngIf="!candidate.Experience?.length">
        Es liegen keine Angaben vor
      </p>
      
      <ul class="list-group list-group-flush">
        
        <li *ngFor="let entry of candidate.Experience" class="list-group-item">

          <div class="row">

            <div class="col-12 col-md">
              <h5 class="mb-0">{{entry.Title}}</h5>
              <p class="mb-0 small">{{entry.CompanyName}}</p>
            </div>

            
            <div class="col-12 col-md-auto mt-2">
      
              <p *ngIf="!entry.ToPresent && entry.Start && entry.End && entry.Start != entry.End" class="small text-right mb-0">
                {{ entry.Start | date:'MM/y'}} bis {{ entry.End | date:'MM/y'}}<br>
                <span>{{ entry.Start | humanizedDateDiff : entry.End }}</span>
              </p>

              <p *ngIf="!entry.ToPresent && entry.Start && entry.End && entry.Start == entry.End" class="small text-right mb-0">
                {{ entry.Start | date:'MM/y'}}<br>
                <span>ca. ein Monat</span>
              </p>

              <p *ngIf="!entry.ToPresent && entry.Start && !entry.End" class="small text-right mb-0">
                {{ entry.Start | date:'MM/y'}}<br>
                <span>unbekannte Dauer</span>
              </p>
 
              <p *ngIf="entry.ToPresent && entry.Start" class="small text-right mb-0">
                {{ entry.Start | date:'MM/y'}} bis heute<br>
                <span *ngIf="entry.Start">{{ entry.Start | humanizedDateDiff }}</span>
              </p>

              <p *ngIf="entry.ToPresent && !entry.Start" class="small text-right mb-0">
                bis heute<br>
                <span *ngIf="entry.Start">unbekannte Dauer</span>
              </p>  

            </div>

          </div>

        </li>

      </ul>

    </section>
  </div>
</div>
