import { Pipe, PipeTransform } from '@angular/core';

declare var moment : any;

@Pipe({
  name: 'humanizedDate'
})
export class HumanizedDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(typeof moment === "undefined") return value;
    moment.locale('de');
    moment.relativeTimeThreshold('y', 365);
    var valueMoment = value ? moment(value) : moment();
    var now = moment();
    return moment.duration(-now.diff(valueMoment)).humanize(true);
  }

}
