import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, NavigationStart } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { BodyService, EventService, ScApiService } from '@sc-ui';
import { Location } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Console } from 'console';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'skillconomy';

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private bodyService: BodyService,
    private location: Location,
    private api: ScApiService,
    @Inject(PLATFORM_ID) private platformId: string) {

  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.route))
      .pipe(map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }))
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => this.handleRouteData(event));


    this.location.subscribe((ev: any) => {
      console.log(ev);
      this.lastPoppedUrl = ev.url;
    });

    this.route.queryParams.subscribe(params => {

      var currentUserId = this.api.userId;

      if (params.t) {
        var trace: any = {
          TraceId: params.t
        };

        if (params.u) {
          trace.ContactId = params.u;
        }
        else if (this.api.userId) {
          trace.ContactId = this.api.userId;
        }

        this.api.post('trace', trace).subscribe();
      }

      if (this.router.url.startsWith('/login')) return;
      if (this.router.url.startsWith('/register')) return;

      var requestedUserIsLoggedIn = params.u && params.u == currentUserId;
      if (requestedUserIsLoggedIn) {
        return;
      }

      if (params.s) {
        var target = this.router.url;
        target = this.removeURLParameter(target, "s");
        target = this.removeURLParameter(target, "t");
        var targetParam = '?target=' + encodeURIComponent(target);
        this.router.navigateByUrl('register/' + params.s + targetParam);
        return;
      }

      if (params.u) {
        var target = this.router.url;
        target = this.removeURLParameter(target, "u");
        target = this.removeURLParameter(target, "eMail");
        target = this.removeURLParameter(target, "t");
        var targetParam = '?target=' + encodeURIComponent(target);

        if (params.eMail) {
          targetParam += '&eMail=' + params.eMail;
        }

        if (params.u)
        {
          targetParam += '&u=' + params.u;
        }

        this.router.navigateByUrl('/login' + targetParam);
      }
    });

    this.router.events.subscribe((ev: any) => {

      if (isPlatformServer(this.platformId)) return;

      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          var scrollY = this.yScrollStack.pop();
          setTimeout(() => window.scrollTo(0, scrollY), 500);
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  handleRouteData(data: any) {
    if (!data) return;
    var title = data.title ? this.title + ' - ' + data.title : this.title;
    this.titleService.setTitle(title);

    if (!data.bodyClasses) {
      this.bodyService.clearClasses();
      return;
    }

    data.bodyClasses.forEach(c => this.bodyService.addClass(c));
  }

  removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);

      //reverse iteration as may be destructive
      for (var i = pars.length; i-- > 0;) {
        //idiom for string.startsWith
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
  }
}
