import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringifyJson'
})
export class StringifyJsonPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var result = JSON.stringify(value, null, 4); 
    return result;
  }

}
