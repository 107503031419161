import { Directive, HostListener, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';


@Directive({
  selector: '[scCopyClick]'
})
export class CopyClickDirective {

  @Input('scCopyClick')
  text : any;

  @HostListener('click') onClick() {
    this.copyMessage(this.text);
  }

  constructor(private clipboardService : ClipboardService) { }

  copyMessage(val: string){
    this.clipboardService.copy(val);
  }

}
