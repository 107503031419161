import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { AppChatsService } from 'src/services/app-chats.service';

@Component({
  selector: 'app-candidate-feedback',
  templateUrl: './candidate-feedback.component.html',
  styleUrls: ['./candidate-feedback.component.scss']
})
export class CandidateFeedbackComponent implements OnChanges {

  @Input()
  candidate: any;

  @Input()
  project: any;

  assignment : any;

  feedback: any;

  selectedFeedback: any;

  hideStatusUpdateNotification = false;

  @HostBinding('class.hide')
  get hidden() { return this.feedback == null; }

  constructor(private appChats: AppChatsService, private api: ScApiService) { }

  ngOnChanges(): void {

    if (!this.candidate || !this.project) return;

    this.assignment = this.candidate.Assignments.find(a => a.Project.Id == this.project.Id);

    this.api.get(`projects/${this.project.Id}/candidates/${this.candidate.Id}/feedback`).subscribe(d => {
      this.feedback = d;
    });
  }

  editFeedback() 
  {
    this.appChats.candidateFeedbackChat(this.project.Id, this.candidate.Id);
  }

  updateStatus()
  {    
    this.hideStatusUpdateNotification = true;
    this.appChats.candidateStatusChat(this.assignment.Project.Id, this.assignment.CandidateId);
  }

  decide() 
  {
    this.hideStatusUpdateNotification = true;
    this.appChats.candidateDecideChat(this.project.Id, this.candidate.Id);
  }

}
