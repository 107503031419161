<div class="container p-0">
<div class="row" *ngIf="message">
	<div class="col-6 pe-2 pl-0">
		<button (click)="send(true)" class="btn btn-secondary btn-block {{ message.Payload.YesCssClass }} me-n1">
			{{ message.Payload.YesLabel }}
		</button>
	</div>
	<div class="col-6 pl-2 pe-0">
		<button (click)="send(false)" class="btn btn-secondary btn-block {{ message.Payload.NoCssClass }} ms-n1">
			{{ message.Payload.NoLabel }}
		</button>
	</div>
</div>
</div>