<div id="chatbot-blob-trigger" [class.pulse]="isPulsating" [class.slide-in]="show" #chatbotTrigger>

    <svg viewBox="0 0 200 200" class="blob" xmlns="http://www.w3.org/2000/svg">
        <path fill="#FF0066" d="M53.1,-72.2C68.6,-61.9,80.7,-46,85.9,-28.3C91.1,-10.6,89.4,9,83.5,27.2C77.7,45.4,67.6,62.2,52.9,73.6C38.3,84.9,19.2,90.7,0.4,90.1C-18.4,89.6,-36.8,82.8,-52.3,71.8C-67.7,60.7,-80.2,45.4,-84.9,28.2C-89.6,11,-86.6,-8.1,-80.2,-25.6C-73.9,-43.1,-64.2,-59.1,-50.3,-69.8C-36.4,-80.6,-18.2,-86.2,0.3,-86.6C18.8,-87.1,37.7,-82.4,53.1,-72.2Z" transform="translate(100 100)" />
    </svg>

    <svg *ngIf="state != 'close'" version="1.1" class="skico-bot-illu" width="40.9" height="38" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40.9 38" enable-background="new 0 0 40.9 38" xml:space="preserve">
        <path id="head" fill="#FFFFFF" d="M39.9,29.7c-0.4-2.5-0.9-5.1-1.3-7.6c-0.4-2.6-0.9-5.1-1.3-7.7l0-0.1c-0.3-1.5-0.6-3.1-1.8-4.1
      c-1.4-1.4-3.4-1.4-5.2-1.4c0,0,0,0-0.1,0C25,8.9,19.9,9,14.9,9.1l-0.1,0c-0.5,0-1.1,0-1.6,0c-3,0-6,0.1-8.5,1.6
      c-2.2,1.4-3.4,3.6-3.6,6.8c0,0.1,0,13.7,0.5,14.1c6.9,3.6,13.1,5.4,18.9,5.4c6.7,0,12.9-2.4,19.3-7.1l0.1-0.1L39.9,29.7z" />
        <g id="eyes">
            <path id="eye-2" fill="#0962D0" d="M28.1,22.5c-0.2,0.4-0.6,0.7-1,0.7c-0.6,0-1.1-0.6-1.3-1.2c-0.4-1.1-1.4-5.9,0.7-5.6
        C28.6,16.6,28.7,21.3,28.1,22.5z" />
            <path id="eye-1" fill="#0962D0" d="M11.9,23.1c-0.2,0.3-0.5,0.6-0.9,0.6c-0.6,0-1-0.6-1.2-1.1c-0.4-1-1.3-5.3,0.6-5
        C12.3,17.8,12.4,22,11.9,23.1z" />
        </g>
        <path id="mouth" fill="none" stroke="#0962D0" stroke-width="1.2" stroke-miterlimit="10" d="M23.1,28c-2,1-4.4,1.1-6.4,0.2" />
        <path id="antenna" fill="#FFFFFF" d="M19.7,4.7c0.7-0.7,0.9-1.6,0.7-2.4c-0.2-0.8-0.9-1.5-1.8-1.8c-0.5-0.1-0.9-0.1-1.3-0.1
      c-0.2,0-0.4,0-0.5,0c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0.1-0.4,0.3-0.6,0.6c0,0,0,0.1,0,0.1c-0.4,0.7-0.5,1.5-0.2,2.4
      c0.3,1,1,1.7,1.9,1.8c0,0,0,0,0,0l0,0.4l0,0.1c0.1,1.1,0.2,2.2,0.2,3.3c0.1,1.1,0.2,2.3,0.3,3.4l1.5-0.1c-0.1-1.1-0.2-2.2-0.2-3.3
      C19,8.2,19,7.1,18.9,6l0.1-0.7C19.2,5.2,19.5,5,19.7,4.7z" />
        <g id="eyes-closed">
            <path id="eye-3" fill="none" stroke="#0062D4" stroke-width="1.1654" stroke-miterlimit="10" d="M11.5,21.5
        c-0.1,0.7-0.3,1.4-0.6,1.4c-0.4,0-0.6-1.2-0.7-2.3" />
            <path id="eye-4" fill="none" stroke="#0062D4" stroke-width="1.2691" stroke-miterlimit="10" d="M27.7,21.1
        c-0.1,0.7-0.4,1.4-0.7,1.4c-0.4,0-0.7-1.2-0.9-2.3" />
        </g>
    </svg>

    <svg *ngIf="state == 'close'" class="skico-bot-illu" height="18" viewBox="0 0 19 18" width="19" xmlns="http://www.w3.org/2000/svg">
        <path d="m16.2227629.08899602 2.2482322 2.24823215-6.6335098 6.63276785 6.6335098 6.63327758-2.2482322 2.2482322-6.63327757-6.6335098-6.63276785 6.6335098-2.24823215-2.2482322 6.633-6.63327758-6.633-6.63276785 2.24823215-2.24823215 6.63276785 6.633z" fill="#fff" fill-rule="evenodd" />
    </svg>

    <span *ngIf="badge > 0" id="sc-chat-badge" class="badge rounded-pill bg-danger">{{ badge }}</span>
</div>