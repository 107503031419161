import { Pipe, PipeTransform } from '@angular/core';
import { ScApiService } from '../services/api.service';


@Pipe({
  name: 'imageSize'
})
export class ImageSizePipe implements PipeTransform {

  constructor() { }

  transform(value: string, ...args: any): unknown {

    var width = args[0];
    var height = args[1];

    var uri = new URL(value);

    if (width) uri.searchParams.set('width', width);
    if (height) uri.searchParams.set('height', height);    

    var uriWithSize = uri.toString();   
    return uriWithSize;
  }

}
