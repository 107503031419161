import { Directive, ViewChild, ElementRef, OnInit, Inject, Input, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, merge, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

@Directive({
  selector: '[scShowToggle]'
})
export class ScShowToggleDirective implements OnInit {

  isShown: boolean = false;

  private _hidden$ = new Subject<any>();  

  @Input('scShowToggle')
  target : any; 

  constructor(@Inject(DOCUMENT) private _document: any, private host : ElementRef) {
  }

  ngOnInit() {
  }

  @HostListener("click", ['$event'])
  toggle(e) {
    if (this.isShown)
      this.hide();
    else
      this.show(e);
  }

  show(e) {    
    this._setCloseHandlers();
    this.isShown = true;
    this.target.classList.add("show");
  }

  hide(): void {
    if (this.isShown) {
      this.isShown = false;
      this.target.classList.remove("show");
      this._hidden$.next();
    }
  }

  private _setCloseHandlers() {

    const escapes$ = fromEvent<KeyboardEvent>(this._document, 'keyup')
      .pipe(takeUntil(this._hidden$), filter(event => event.which === 27));

    const clicks$ = fromEvent<MouseEvent>(this._document, 'click')
      .pipe(takeUntil(this._hidden$), filter(e => this._shouldCloseFromClick(e)));

    merge(escapes$, clicks$).pipe(takeUntil(this._hidden$)).subscribe(() => {
      this.hide();
    });
  }

  private _shouldCloseFromClick(event: MouseEvent) {
    return event.button !== 2 && !this._isEventFromToggle(event);
  }

  private _isEventFromToggle($event) {
    return this.host.nativeElement.contains($event.target);
  }

}
