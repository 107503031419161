<div [ngSwitch]="activity.Content">
   
    <sc-activity-item-candidate-commented *ngSwitchCase="'#commented'" [activity]="activity" [showProjectName]="showProjectName" [condensed]="condensed">        
    </sc-activity-item-candidate-commented>   

    <sc-activity-item-candidate-accepted *ngSwitchCase="'#accepted'" [activity]="activity" [showProjectName]="showProjectName" [condensed]="condensed">
    </sc-activity-item-candidate-accepted>    

    <sc-activity-item-candidate-feedback-posted *ngSwitchCase="'#candidatefeedback'" [activity]="activity" [showProjectName]="showProjectName" [condensed]="condensed">
    </sc-activity-item-candidate-feedback-posted>

    <sc-activity-item-candidate-hired *ngSwitchCase="'#hired'" [activity]="activity" [showProjectName]="showProjectName" [condensed]="condensed">
    </sc-activity-item-candidate-hired>

    <sc-activity-item-candidate-rejected *ngSwitchCase="'#rejected'" [activity]="activity" [showProjectName]="showProjectName" [condensed]="condensed">
    </sc-activity-item-candidate-rejected>

    <sc-activity-item-candidate-shortlisted *ngSwitchCase="'#shortlistedcandidate'" [activity]="activity" [showProjectName]="showProjectName" [condensed]="condensed">        
    </sc-activity-item-candidate-shortlisted>

    <sc-activity-item-candidate-added-to-pool *ngSwitchCase="'#candidateaddedtopool'" [activity]="activity" [condensed]="condensed">        
    </sc-activity-item-candidate-added-to-pool>

    <sc-activity-item-project-jobsite-commented *ngSwitchCase="'#jobsitecomment'" [activity]="activity" [showProjectName]="showProjectName" [condensed]="condensed">
    </sc-activity-item-project-jobsite-commented>

    <sc-activity-item-project-jobsite-confirmed *ngSwitchCase="'#jobsiteconfirmed'" [activity]="activity" [showProjectName]="showProjectName" [condensed]="condensed">
    </sc-activity-item-project-jobsite-confirmed>

    <sc-activity-item-project-jobsite-ready *ngSwitchCase="'#jobsiteready'" [activity]="activity" [showProjectName]="showProjectName" [condensed]="condensed">
    </sc-activity-item-project-jobsite-ready>

    <sc-activity-item-project-jobsite-work-item-commented *ngSwitchCase="'#jobsiteworkitemcomment'" [activity]="activity" [showProjectName]="showProjectName" [condensed]="condensed">
    </sc-activity-item-project-jobsite-work-item-commented>

    <sc-activity-item-project-messaging-done *ngSwitchCase="'#messagingdone'" [activity]="activity" [showProjectName]="showProjectName" [condensed]="condensed">        
    </sc-activity-item-project-messaging-done> 

    <sc-activity-item-project-briefing-completed *ngSwitchCase="'#projectbriefingcompleted'" [activity]="activity" [showProjectName]="showProjectName" [condensed]="condensed">
    </sc-activity-item-project-briefing-completed>

</div>