import { Directive, Input, OnInit, ElementRef } from '@angular/core';

@Directive({
  selector: 'img[scFileIcon]',
  host: {
    '[src]': 'src'
  }
})
export class ScFileIconDirective implements OnInit {

  @Input() scFileIcon: string;

  src: string;

  constructor(private el : ElementRef) { }

  ngOnInit() {

    var cleanFileType = this.scFileIcon
    .replace(".", "")
    .replace("application/", "")
    .replace("image/", "")
    .trim().toLocaleLowerCase();

    console.log(cleanFileType);

    switch (cleanFileType) {
      case "pdf":
        this.src = "/assets/img/file-icons/file-pdf.svg";
        break;

      case "zip": case "rar": case "7z":
        this.src = "/assets/img/file-icons/file-pdf.svg";
        break;

      case "doc": case "docx":
        this.src = "/assets/img/file-icons/file-word.svg";
        break;

      case "xls": case "xlsx":
        this.src = "/assets/img/file-icons/file-excel.svg";
        break;

      case "ppt": case "pptx":
        this.src = "/assets/img/file-icons/file-powerpoint.svg";
        break;

      case "png": case "jpg": case "jpeg": case "gif":
        this.src = "/assets/img/file-icons/file-image.svg";
        break;

      default:
        this.src = "/assets/img/file-icons/file.svg";

    }

    this.el.nativeElement.classList.add('file-icon');
  }

}
