import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  culture: string = 'de-de';

  dictionaries = {
    "de-de-du": {
      "Bitte kontaktieren Sie mich nicht mehr": "Bitte kontaktiert mich nicht mehr",
      "Ich möchte keine weiteren Angebote erhalten": "Ich möchte keine weiteren Angebote erhalten",
      "Ihr Jobangebot": "Dein Jobangebot",
      "Vollzeit": "Vollzeit",
      "Teilzeit": "Teilzeit",
      "Voll- oder Teilzeit": "Voll- oder Teilzeit",
      "Festanstellung": "Festanstellung",
      "Befristete Anstellung": "Befristete Anstellung",
      "Freelancer": "Freelancer",
      "Aufgaben": "Aufgaben",
      "Ihre Aufgaben": "Deine Aufgaben",
      "weitere": "weitere",
      "Ihr Arbeitsort": "Dein Arbeitsort",
      "Deutschlandweit": "Deutschlandweit",
      "Gehalt + Benefits": "Gehalt + Benefits",
      "Ihr Gehalt": "Dein Gehalt",
      "Projektbeispiel": "Dein Tag",
      "Honorar + Benefits": "Honorar + Benefits",
      "Ihr Honorar": "Dein Honorar",
      "Mithilfe unserer umfassenden Jobdatenbank schätzen wir für Sie ab, in welchem Bereich Ihr zukünftiges Zielgehalt liegen wird.": "Mithilfe unserer umfassenden Jobdatenbank schätzen wir für dich ab, in welchem Bereich dein zukünftiges Zielgehalt liegen wird.",
      "Mithilfe unserer umfassenden Jobdatenbank schätzen wir für Sie ab, in welchem Bereich Ihr zukünftiges Honorar liegen wird.": "Mithilfe unserer umfassenden Jobdatenbank schätzen wir für dich ab, in welchem Bereich dein zukünftiges Honorar liegen wird.",
      "Hier siehst du vorab die grobe Gehaltsrange der Position. Lass uns gerne im persönlichen Gespräch über das zu dir passende Gesamtpaket sprechen.": "Hier siehst du vorab die grobe Gehaltsrange der Position. Lass uns gerne im persönlichen Gespräch über das zu dir passende Gesamtpaket sprechen.",
      "relevante Erfahrung → ideales Profil": "relevante Erfahrung → ideales Profil",
      "Ihre Benefits": "Deine Benefits",
      "Arbeitsumfeld": "Arbeitsumfeld",
      "Ihr Arbeitsumfeld": "Dein Arbeitsumfeld",
      "Unternehmen": "Unternehmen",
      "Ihre Vorteile": "Deine Vorteile",
      "Impressum": "Impressum",
      "Datenschutz": "Datenschutz"
    },
    "en-us": {
      "Bitte kontaktieren Sie mich nicht mehr": "Please do not contact me again",
      "Ich möchte keine weiteren Angebote erhalten": "I do not want to receive further offers",
      "Ihr Jobangebot": "Your Job Offer",
      "Vollzeit": "Full-time",
      "Teilzeit": "Part-time",
      "Voll- oder Teilzeit": "Full- or Part-time",
      "Festanstellung": "Permanent Employment",
      "Befristete Anstellung": "Temporary Employment",
      "Freelancer": "Freelancer",
      "Aufgaben": "Tasks",
      "Ihre Aufgaben": "Your Tasks",
      "weitere": "others",
      "Ihr Arbeitsort": "Location",
      "Deutschlandweit": "Anywhere in Germany",
      "Gehalt + Benefits": "Salary + Benefits",
      "Ihr Gehalt": "Your Salary",
      "Honorar + Benefits": "Compensation + Benefits",
      "Ihr Honorar": "Your Compensation",
      "Mithilfe unserer umfassenden Jobdatenbank schätzen wir für Sie ab, in welchem Bereich Ihr zukünftiges Zielgehalt liegen wird.": "Based on our comprehensive job database we estimate your approximate target salary for the offered position.",
      "Mithilfe unserer umfassenden Jobdatenbank schätzen wir für Sie ab, in welchem Bereich Ihr zukünftiges Honorar liegen wird.": "Based on our comprehensive job database we estimate your approximate compensation for the offered position.",
      "Hier siehst du vorab die grobe Gehaltsrange der Position. Lass uns gerne im persönlichen Gespräch über das zu dir passende Gesamtpaket sprechen.": "Hier siehst du vorab die grobe Gehaltsrange der Position. Lass uns gerne im persönlichen Gespräch über das zu dir passende Gesamtpaket sprechen.",
      "relevante Erfahrung → ideales Profil": "Relevant Experience → Ideal Profile",
      "Ihre Benefits": "Your Benefits",
      "Arbeitsumfeld": "Workplace",
      "Ihr Arbeitsumfeld": "Your Workplace",
      "Unternehmen": "Company",
      "Ihre Vorteile": "Your Benefits",
      "Impressum": "Imprint",
      "Datenschutz": "Data Privacy"
    }
  }

  constructor() { }

  setCulture(culture: string) {
    this.culture = culture;
  }

  localize(key: string): string {
    if (!this.dictionaries[this.culture]?.[key]) return key;
    return this.dictionaries[this.culture][key];
  }
}