import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ChatsService, IntelligenceTriggerButtonComponent, ScChatbotStreamComponent } from '@sc-ui-chatbot';
import { AppChatsService } from 'src/services/app-chats.service';

@Component({
  selector: 'app-candidate-chats',
  templateUrl: './candidate-chats.component.html',
  styleUrls: ['./candidate-chats.component.scss']
})
export class CandidateChatsComponent implements OnInit {

  @ViewChild("chatList")
  chatList: ElementRef;

  @ViewChild('candidateChatStream')
  candidateChatStream : ScChatbotStreamComponent;

  @ViewChild('botChatStream')
  botChatStream : ScChatbotStreamComponent;

  @ViewChild(IntelligenceTriggerButtonComponent)
  public trigger: IntelligenceTriggerButtonComponent;

  isActive = false;

  selectedChat: any = null;

  canNavigateToCandidate = false;

  botChat: any = null;

  view: 'chat' | 'list' | 'chatbot' = 'list';

  overViewScrolledToTop = true;

  constructor(public appChats: AppChatsService, private router : Router) {
  }

  ngOnInit() {
    this.appChats.registerComponent(this);
    this.appChats.initialize();
    this.navigateTo('list');

    this.router.events.subscribe(e => {
      if (e !instanceof NavigationEnd) return;
      this.updateCanNavigateToCandidate();
    });
  }

  toggleChatbot() {

    if (this.isActive && this.view != 'chatbot') {
      this.navigateTo('chatbot');
    }
    else if (!this.isActive) {
      this.navigateTo('chatbot');
      this.show();
    }
    else {
      this.hide();
    }
  }  

  show() {
    this.isActive = true;
    if (this.view == 'chat') this.candidateChatStream?.activateAcknowledgments();
    if (this.view == 'chatbot') this.botChatStream?.activateAcknowledgments();
  }

  hide() {
    this.isActive = false;
    this.candidateChatStream?.deactivateAcknowledgments();
    this.botChatStream?.deactivateAcknowledgments();
  }

  showOverview() {
    this.navigateTo('list');
    this.show();
  }

  showBotChat(chat)
  {
    this.botChat = chat;
    this.navigateTo('chatbot');
    this.show();
    this.trigger.slideIn();
  }

  showDirectChat(chat) {    
    this.selectedChat = chat;
    this.navigateTo('chat');
    this.show();
    this.updateCanNavigateToCandidate();
  }

  checkOverviewScroll(el: any) {
    const scrollTop = el.scrollTop;
    this.overViewScrolledToTop = scrollTop == 0;
  }

  cancelChatbot()
  {
    this.botChat = null;
    this.trigger.slideOut();
    this.hide();
  }

  navigateTo(view)
  {
    this.view = view;

    if (!this.isActive) return;

    if (this.view == 'list')
    {
      this.candidateChatStream?.deactivateAcknowledgments();
      this.botChatStream?.deactivateAcknowledgments();
    }
    if (this.view == 'chat')
    {
      this.candidateChatStream?.activateAcknowledgments();
      this.botChatStream?.deactivateAcknowledgments();
    }
    if (this.view == 'chatbot')
    {
      this.candidateChatStream?.deactivateAcknowledgments();
      this.botChatStream?.activateAcknowledgments();
    }
  }

  navigateToCandidate()
  {
    var chat = this.selectedChat;
    if (!chat?.Users?.length) return null;
    var userId = chat.Users[0].Id;
    var split = userId.split(':');
    if (split[0] != 'candidate') return;
    var route = `/candidates/${split[1]}`;
    this.hide();
    this.router.navigateByUrl(route);
  }

  updateCanNavigateToCandidate()
  {
    var chat = this.selectedChat;
    if (!chat?.Users?.length) return false;
    var userId = chat.Users[0].Id;
    var split = userId.split(':');
    if (split[0] != 'candidate') false;
    var route = `/candidates/${split[1]}`;
    var currentRoute = this.router.url;
    this.canNavigateToCandidate = !currentRoute.endsWith(route);
  }
}
