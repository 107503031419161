<div class="row justify-content-center align-items-end">

    <div class="auth-card col-auto">
  
      <div class="auth-blob">
        <img src="assets/blobs/blob-1.svg" alt="skillconomy Blob" class="img-fluid">
      </div>
  
      <div class="card box-shadow">
  
        <div class="card-body">
  
          <div class="logo">
            <img src="assets/logo/skillconomy-logo-blau.svg" alt="skillconomy Logo" class="img-fluid">
          </div>
  
          <h2 class="text-center mb-5">Einladungslink anfordern</h2>
  
  
          <form>
  
            <div class="form-group">
                <p class="text-center">
                    Sie müssen sich zunächst im skillconomy-Portal registrieren. Hierzu erhalten Sie von uns einen Link per Mail an <i>{{eMail}}</i>, über den Sie zur 
                    Registrierung gelangen.
                </p>
            </div>
  
            <div class="row align-items-center" *ngIf="sendLinkError">
              <div class="col">
                <div class="alert alert-error mt-4" role="alert">
                  <p class="alert-heading mb-0">
                    Der Link konnte nicht zugesendet werden. Bitte wenden Sie sich an skillconomy.
                  </p>
                </div>
              </div>
            </div>   
  
            <div class="d-flex justify-content-center">
              <div class="row align-items-center" *ngIf="sendLinkSuccess">
                <div class="col">
                  <div class="alert alert-success mt-4" role="alert">
                    <p class="alert-heading mb-0">
                      <span class="icon me-3">
                        <i class="fa-sharp fa-solid fa-check"></i>
                      </span>    
                      Der Link wurde versendet. Bitte schauen Sie auch im Spam-Ordner.
                    </p>
                  </div>
                </div>
              </div>    
  
              <button class="btn btn-primary mt-4" *ngIf="!sendLinkSuccess" [disabled]="isSendingLink" (click)="sendLink()">
                <span *ngIf="isSendingLink" class="spinner-border spinner-border-sm"></span>
                Link zusenden
              </button>
  
            </div>
            
          </form>
  
        </div>
  
        <div class="card-footer text-center">
          <small>Sie haben bereits einen Account? <a (click)="goToLogin()">Anmelden</a></small>
        </div>
  
      </div>
  
    </div>
    
    <div class="auth-illu col-auto">
      <img src="assets/illus/skillconomy-bot-waving.svg" alt="skillconomy Illu Bot" class="img-fluid">
    </div>
  
  </div>