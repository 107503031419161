import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfettiService {

  onStart = new Subject<any>();

  constructor() { }

  start(duration)
  {
    this.onStart.next(duration);
  }
}
