<ng-template [appTopbarTemplate]>
    <div class="row align-items-center no-gutters topbar" *ngIf="group">
        <div class="col d-none d-md-block">
            <h4 class="mb-0 group-title oneline">{{ group?.Name }}</h4>
            <a routerLink="/pools/{{ group.Pool.Id }}" class="small">Gruppe in {{ group.Pool.Name }}</a>
        </div>
    </div>
  </ng-template>
  
  <div class="scroll-dummy" *ngIf="!group"></div>
  
  <div class="content" [@fadeIn]="state">  
  
    <div class="container-xl">
      <div class="row">
        <div class="col-12">
          <div class="card card-xl group-content">
            <div class="card-body">
              <app-pool-group-base [group]="group"></app-pool-group-base>
              <app-pool-group-candidates [group]="group"></app-pool-group-candidates>  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- <app-footer-blob></app-footer-blob>--> 
  