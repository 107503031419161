import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-activity-item-highlight',
  templateUrl: './activity-item-highlight.component.html',
  styleUrls: ['./activity-item-highlight.component.scss']
})
export class ActivityItemHighlightComponent implements OnInit {

  @Input()
  icon : any = 'fa-sharp fa-solid fa-star';

  @Input()
  activity : any;

  @Input()
  condensed : any;

  @Input()
  showProjectName = false;

  constructor() { }

  ngOnInit(): void {
  }

}
