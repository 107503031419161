import { Component, OnInit } from '@angular/core';
import { ConfettiService } from '../../services/confetti.service';
import { default as confetti } from './confetti';

@Component({
  selector: 'sc-confetti',
  templateUrl: './confetti.component.html',
  styleUrls: ['./confetti.component.scss']
})
export class ConfettiComponent implements OnInit {

  constructor(private confettiService: ConfettiService) { }

  ngOnInit() {
    this.confettiService.onStart.subscribe((duration) => {

      duration = duration * 1000;

      var animationEnd = Date.now() + duration;
      var defaults = {
        startVelocity: 30, spread: 360, ticks: 60, zIndex: 0,
        colors: ['7BBCE7', '5A9CB0', '75BFA7', 'FDEE94', 'FAA586', 'D7ACE2']
      };

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
      }

      var interval = setInterval(function () {
        var timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        var particleCount = 50 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
      }, 250);      
    });
  }
}