import { Component, OnInit } from '@angular/core';

declare var Rellax : any;

@Component({
  selector: 'app-particles',
  templateUrl: './particles.component.html',
  styleUrls: ['./particles.component.scss']
})
export class ParticlesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var rellax = new Rellax('.rellax');
  }

}


