import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'sc-chatbot-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class ScChatbotTextinputComponent implements OnChanges {
 
  @Input() 
  message : any;

  @Output() 
  submit: EventEmitter<any> = new EventEmitter();

  @ViewChild('multilineInput', { static: false })
  multilineInput : ElementRef;

  textInput : '';

  inputType = 'text';

  isValid = true;

  error = '';

  ngOnChanges(changes: SimpleChanges)
  {
    if (!changes.message) return;
    this.textInput = '';
    this.inputType = this.message.Payload.IsPassword ? 'password' : 'text';
  }

  send() {

    console.log('sending text');

    if (this.message.Payload.ValidateIsPhone)
    {
      var digitCount = 0;
      var digits = '0123456789';
      var validChars = '01234567890+-/() ';

      for (var i = 0; i < this.textInput.length; i++) {
        var c = this.textInput.charAt(i);

        if (!validChars.includes(c))
        {
          this.error = 'Ungültiges Zeichen: ' + c;
          return;
        }
        
        if (!digits.includes(c)) continue;
        digitCount++;
      }

      if (digitCount < 7)
      {
        this.error = 'Die Telefonnummer ist zu kurz';
        return;
      }

      var firstChar = this.textInput[0];
      var validFirstChars = '0+';
      if (!validFirstChars.includes(firstChar))
      {
        this.error = 'Die Telefonnummer muss mit 0 oder + beginnen';
        return;
      }
    }

    this.error = '';    

    var message = {
      Direction: "UserToBot",
      Type: 'Text',
      Payload: {      
        IsPassword:  this.message.Payload.IsPassword,
        Body: this.textInput
      }
    };

    this.textInput = '';  
    this.resizeTextArea();     
    this.submit.next(message);
  }

  resizeTextArea() {
    setTimeout(() => {
      var el = this.multilineInput?.nativeElement;
      if (!el) return;
      el.style.height = 'auto';
      el.style.height = el.scrollHeight + 2 + 'px';
    }, 5);

  }

}
