<ul class="nav nav-tabs nav-overflow">

  <li class="nav-item" *ngFor="let item of items; let i = index">
    <a class="nav-link" [scAnchorLink]="item.Title">
      {{ item.Title }}
    </a>
  </li>

  <!-- make sure that component height is already final, before items are loaded -->
  <li class="nav-item dummy" *ngIf="!items?.length">
    <a class="nav-link">
      none
    </a>
  </li>

</ul>
