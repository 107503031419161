import { Directive, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Directive({
  selector: '[scDismissModals]'
})
export class ScDismissModalsDirective {

  constructor(private modals : NgbModal) { }

  @HostListener("click")
  dismissAll()
  {
    this.modals.dismissAll();
  }


}
