import { Component, Input, Output, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Marker } from '@agm/core/services/google-maps-types';

interface homeLocation {
  "Longitude": number,
  "Latitude": number,
};

interface jobLocation {
  "Zip": string,
  "City": string,
  "Country": string,
  "Street": string,
  "Number": string,
  "Longitude": number,
  "Latitude": number
};

@Component({
  selector: 'app-jobsite-map',
  templateUrl: './jobsite-map.component.html',
  styleUrls: ['./jobsite-map.component.scss']
})
export class JobsiteMapComponent implements OnInit {

  mapHomeLocation: homeLocation = undefined;
  jobLocations: Array<jobLocation> = undefined;

  @Input()
  job: any;

  selectedLocation: any = null;
  jobLocationName: string = '';
  jobDistance: string = '';
  jobTravelTime: string = '';
  hasRoute: boolean = false;

  directionsService: any;
  directionsDisplay: any;

  @ViewChild('gmap', { static: true })
  gmapElement: any;

  map: any;

  mapMarker: any = [];

  isSkillconomy() { return this.job?.Company?.Id == 1 };

  constructor(private ref: ChangeDetectorRef, private mapsApiLoader: MapsAPILoader) { }

  ngOnInit() {


    this.mapHomeLocation = this.job.Candidate;
    this.jobLocations = this.job.Locations;

    this.mapsApiLoader.load().then(() => {
      this.directionsService = new google.maps.DirectionsService();
      this.directionsDisplay = new google.maps.DirectionsRenderer();

      var germany = new google.maps.LatLng(50.932580, 10.454166);
      var mapOptions = {
        zoom: 5.5,
        center: germany,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        language: 'DE'
      };

      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);
      this.directionsDisplay.setMap(this.map);
      this.jobLocationName = "Deutschlandweit";

      if (this.jobLocations !== undefined && this.jobLocations?.length > 0) {
        this.renderRoute(this.jobLocations[0]);
      }

    });
  }

  clearMarker() {
    for (var i = 0; i < this.mapMarker.length; i++) {
      this.mapMarker[i].setMap(null);
    }
    this.mapMarker = [];
  }

  renderRoute(targetLocation) {

    this.jobDistance = '';
    this.jobTravelTime = 'berechne Route ...';

    this.selectedLocation = targetLocation;
    this.jobLocationName = [[targetLocation.Street, targetLocation.Number].join(' '), [targetLocation.Zip, targetLocation.City].join(' ')].join('<br>');

    this.mapsApiLoader.load().then(() => {

      const destLatLng = new google.maps.LatLng(targetLocation.Latitude, targetLocation.Longitude)

      if (this.mapHomeLocation == undefined) {
        this.clearMarker();

        var marker = new google.maps.Marker({
          position: destLatLng,
          map: this.map,
          title: 'markers'
        });
        this.mapMarker.push(marker);
        this.map.setCenter(destLatLng);
        this.map.setZoom(9);

        this.hasRoute = false;

        return;
      }


      var request: google.maps.DirectionsRequest = {
        origin: new google.maps.LatLng(this.mapHomeLocation.Latitude, this.mapHomeLocation.Longitude),
        destination: destLatLng,
        travelMode: google.maps.TravelMode.DRIVING,
      };

      this.directionsService.route(request, (result, status) => {

        if (status === google.maps.DirectionsStatus.OK) {

          var durationHours = result.routes[0].legs[0].duration.value / 3600;
          var displayTime = (Math.floor(durationHours)) + ':' + Math.floor(durationHours % 1 * 60);
          var durationMinutes = Math.floor(durationHours % 1 * 60);
          var homeLocationAdress = result.routes[0].legs[0].start_address.split(',');

          this.directionsDisplay.setDirections(result);

          setTimeout(() => {
            this.jobDistance = result.routes[0].legs[0].distance.text + ' von ' + homeLocationAdress[1].split(' ')[2];
            this.jobTravelTime = Math.floor(durationHours) + ' Std. ' + durationMinutes + ' Min.';
            this.hasRoute = true;

            this.ref.detectChanges();

          }, 10);

        } else {

          this.clearMarker();

          var marker = new google.maps.Marker({
            position: destLatLng,
            map: this.map,
            title: 'markers'
          });
          this.mapMarker.push(marker);
          this.map.setCenter(destLatLng);
          this.map.setZoom(9);

          this.hasRoute = false;
        }
      });

    });
  }
}