<div class="dropdown">

    <a class="dropdown-toggle" [scuiToggleOnClick]="menu">
        <ng-content select="scui-dropdown-toggle"></ng-content>
    </a>

    <div #menu class="dropdown-menu dropdown-menu-right show py-0">
        <div class="py-3">
            <ng-content select="scui-dropdown-menu"></ng-content>
        </div>
        
    </div>

</div>