import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-vertically-centered',
  templateUrl: './vertically-centered.component.html',
  styleUrls: ['./vertically-centered.component.scss']
})
export class VerticallyCenteredComponent implements OnInit {

  isCollapsed: boolean;

  constructor() {
    this.isCollapsed = true;
  }

  ngOnInit() { }
}