<span class="badge badge-pill badge-small badge-light"
  *ngIf="!assignment.IsAccepted && !assignment.IsRejected && !assignment.IsRevoked">
  <i class="fas me-1 fa-user"></i>
  Vorgestellt
</span>

<span class="badge badge-pill badge-small badge-light"
  *ngIf="assignment.IsAccepted && !assignment.IsRejected && !assignment.IsRevoked && !assignment.IsHired && assignment.ApplicationStatus == 'None'">
  <i class="fas me-1 fa-comment-alt"></i>
  Erstgespräch
</span>

<span class="badge badge-pill badge-small badge-light"
  *ngIf="assignment.IsAccepted && !assignment.IsRejected && !assignment.IsRevoked && !assignment.IsHired && assignment.ApplicationStatus == 'AwaitingFirstInterview'">
  <i class="fas me-1 fa-comment-alt"></i>
  Erstgespräch
</span>

<span class="badge badge-pill badge-small badge-light"
  *ngIf="assignment.IsAccepted && !assignment.IsRejected && !assignment.IsRevoked && !assignment.IsRevoked && !assignment.IsHired && assignment.ApplicationStatus == 'AwaitingInformation'">
  <i class="fas me-1 fa-question-circle"></i>
  Informationen angefragt
</span>

<span class="badge badge-pill badge-small badge-light"
  *ngIf="assignment.IsAccepted && !assignment.IsRejected && !assignment.IsHired && assignment.ApplicationStatus == 'AwaitingSecondInterview'">
  <i class="fas me-1 fa-comment-alt"></i>
  Zweitgespräch
</span>

<span class="badge badge-pill badge-small badge-light"
  *ngIf="assignment.IsAccepted && !assignment.IsRejected && !assignment.IsHired && assignment.ApplicationStatus == 'Offer'">
  <i class="fas me-1 fa-hand-holding-seedling"></i>
  Angebot unterbreitet
</span>

<span class="badge badge-pill badge-small badge-danger-soft" 
  *ngIf="assignment.IsRevoked && !assignment.IsHired">
  <i class="fas me-1 fa-times-circle"></i>
  Bewerbung zurückgezogen
</span>

<span class="badge badge-pill badge-small badge-danger-soft"
   *ngIf="assignment.IsRejected && !assignment.IsHired">
  <i class="fas me-1 fa-times-circle"></i>
  Abgelehnt
</span>

<span class="badge badge-status badge-pill badge-small badge-success-soft" 
  *ngIf="assignment.IsHired">
  <i class="fas me-1 fa-check-circle"></i>
  Eingestellt
</span>
