import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService, ScApiService, ScuiModalComponent } from '@sc-ui';
import { TypewritingParagraphComponent } from 'src/components/typewriting-paragraph/typewriting-paragraph.component';
import { NotificationsService } from 'src/services/notifications.service';
import { fadeIn } from 'src/transitions';
import { ProjectBriefingComponent } from './project-briefing/project-briefing.component';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  animations: [fadeIn]
})
export class ProjectComponent implements OnInit {

  state = 'out';

  id: any;

  shouldGenerateContent = false;

  project: any;

  notifications: any;

  chatStarted = false;

  @ViewChild(ProjectBriefingComponent, { static: false })
  briefingComponent: ProjectBriefingComponent;

  @ViewChild(ScuiModalComponent, { static: true })
  modal: ScuiModalComponent;

  constructor(
    private api: ScApiService,
    private events: EventService,
    private notificationService: NotificationsService,
    private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.params.subscribe((params: any) => {

      if (params.id) {

        this.id = params.id;
        this.events.channel('projects/' + this.id).subscribe(() => this.load());
        this.load();
      }
    });
  }

  load() {
    this.api.get('projects/' + this.id).subscribe((d: any) => {
      this.project = d;
      this.state = 'in';
    }, e => this.api.redirectAnonymousToLogin(e));

    this.api.get('projects/' + this.id + '/notifications').subscribe(d => {
      this.notifications = d;
    });
  }

  execute(i) {
    this.notificationService.execute(i);
    this.modal.complete(null);
  }

}
