<div class="card-image-top" *ngIf="project">
  <img class="img-fluid" *ngIf="project.Image" [src]="project.Image | imageSize : 1200 : 395" />
  <img class="img-fluid" *ngIf="!project.Image" src="/assets/project-hero-placeholder/hero-placeholder-{{getImageIndex()}}.jpg" />
</div>

<div *ngIf="project">

  <div class="row mb-5 align-items-center">

    <div class="col">
      <a routerLink="/projects" class="link-arrow left">Projekte</a>
    </div>

    <div class="col-auto">
      <app-project-base-user-modal [project]="project"></app-project-base-user-modal>
    </div>

  </div>

  <div class="row">
    <div class="col-12">
      <h1 class="d-inline-block mb-3">{{ project.Name }}</h1><span class="small ms-3">m|w|d</span>
    </div>

    <ul class="col-12 list-inline mb-4">
      <li class="list-inline-item" *ngIf="project.Locations?.length == 1">
        <i class="me-1 size-1 fa-sharp fa-solid fa-location-dot"></i> {{ project.Locations[0].City }}
      </li>
      <li class="list-inline-item" *ngIf="project.Locations?.length == 2">
        <i class="me-1 size-1 fa-sharp fa-solid fa-location-dot"></i> {{ project.Locations[0].City }} + 1 weiterer
      </li>
      <li class="list-inline-item" *ngIf="project.Locations?.length > 2">
        <i class="me-1 size-1 fa-sharp fa-solid fa-location-dot"></i> {{ project.Locations[0].City }} + {{ project.Locations?.length - 1}} weitere
      </li>

      <li class="list-inline-item" *ngIf="project.EmploymentType == 'Permanent'">
        <i class="me-1 size-1 fa-sharp fa-solid fa-clock"></i> Unbefristet
      </li>
      <li class="list-inline-item" *ngIf="project.EmploymentType == 'Interim'">
        <i class="me-1 size-1 fa-sharp fa-solid fa-clock"></i> Befristet
      </li>
      <li class="list-inline-item" *ngIf="project.EmploymentType == 'Freelancer'">
        <i class="me-1 size-1 fa-sharp fa-solid fa-clock"></i> Freelancer
      </li>

      <li class="list-inline-item" *ngIf="project.IsFullTime && !project.IsPartTime">
        <i class="me-1 size-1 fa-sharp fa-solid fa-file-signature"></i> Vollzeit
      </li>
      <li class="list-inline-item" *ngIf="!project.IsFullTime && project.IsPartTime">
        <i class="me-1 size-1 fa-sharp fa-solid fa-file-signature"></i> Teilzeit
      </li>
      <li class="list-inline-item" *ngIf="project.IsFullTime && project.IsPartTime">
        <i class="me-1 size-1 fa-sharp fa-solid fa-file-signature"></i> Teilzeit/Vollzeit
      </li>

      <li class="list-inline-item" *ngIf="project.MinSalary && project.MaxSalary">
        <i class="me-1 size-1 fa-sharp fa-solid fa-money-bill-wave"></i> {{ project.MinSalary | number }} – {{ project.MaxSalary | number }} {{ project.SalaryCurrency }}
      </li>
    </ul>

    <div class="col">
      <app-project-status-badge [project]="project"></app-project-status-badge>
    </div>
  </div>


  
</div>