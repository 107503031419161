<label id="label">
    <ng-content></ng-content>
    <input #fileInput id="file" type="file" [multiple]="false" (change)="onChange($event)" />
</label>

<sc-modal #modal size="lg">
    <div class="modal-header">
        <h4 class="modal-title">Bildausschnitt wählen</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="min-height: 500px;">

        <image-cropper #bannerCrop [imageChangedEvent]="fileSelected" [maintainAspectRatio]="maintainAspectRatio" [aspectRatio]="aspectRatio" [resizeToWidth]="resizeToWidth" [resizeToHeight]="resizeToHeight" [format]="fileType" (imageCropped)="cropped($event)">
        </image-cropper>

        <div [class.hide]="!imageTooSmall" role="alert">
            <span class="text-danger">Die Mindestbreite beträgt: {{ minWidth }} px</span>
        </div>
    </div>
    <div class="modal-footer">

        <button class="btn btn-outline-primary" (click)="modal.dismiss()">
            Abbrechen
        </button>
        <button class="btn btn-dark" [disabled]="isUploading || imageTooSmall" *ngIf="canUpload" (click)="upload()">
            <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Hochladen
        </button>
    </div>
</sc-modal>