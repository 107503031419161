<div class="row" [scAnchorTarget]="'Briefing'" *ngIf="project">

  <!-- <div class="col-12 section-title">
    <h3 class="mb-6">Briefing</h3>
  </div> -->

  <div class="col-9 col-lg-10" *ngIf="project.StatusIndex >= 4">

    <div class="required-skills mb-6" *ngIf="project.Requirements == '' && (project?.MustHaves || project.MinYearsOfExperience || project.MaxYearsOfExperience || project.Responsibility || project.MinGermanLevel || project.MinEnglishLevel)">

      <h4 class="mb-4 h5">Must-Haves</h4>

      <ul class="tag-list list-inline mb-0">
        <li class="list-inline-item" *ngFor="let tag of project.MustHaves">
          <span class="tag  tag-clip-text tag-light">{{tag}}</span>
        </li>

        <li class="list-inline-item" *ngIf="project.MinYearsOfExperience && project.MaxYearsOfExperience">
          <span class="tag  tag-clip-text tag-light">Berufserfahrung: {{ project.MinYearsOfExperience }} - {{ project.MaxYearsOfExperience }} Jahre</span>
        </li>

        <li class="list-inline-item" *ngIf="project.MinYearsOfExperience && !project.MaxYearsOfExperience">
          <span class="tag  tag-clip-text tag-light">Berufserfahrung: > {{ project.MinYearsOfExperience }} Jahre</span>
        </li>

        <li class="list-inline-item" *ngIf="!project.MinYearsOfExperience && project.MaxYearsOfExperience">
          <span class="tag  tag-clip-text tag-light">Berufserfahrung: < {{ project.MaxYearsOfExperience }} Jahre</span> </li> <li class="list-inline-item" *ngIf="project.Responsibility">
              <span class="tag  tag-clip-text tag-light">Führungserfahrung: {{ project.Responsibility }}</span>
        </li>

        <li class="list-inline-item" *ngIf="project.MinGermanLevel == 1">
          <span class="tag  tag-clip-text tag-light">Deutsch: Grundlagen</span>
        </li>
        <li class="list-inline-item" *ngIf="project.MinGermanLevel == 2">
          <span class="tag  tag-clip-text tag-light">Deutsch: Gut</span>
        </li>
        <li class="list-inline-item" *ngIf="project.MinGermanLevel == 3">
          <span class="tag  tag-clip-text tag-light">Deutsch: Fließend</span>
        </li>
        <li class="list-inline-item" *ngIf="project.MinGermanLevel == 4">
          <span class="tag  tag-clip-text tag-light">Deutsch: Muttersprache</span>
        </li>

        <li class="list-inline-item" *ngIf="project.MinEnglishLevel == 1">
          <span class="tag  tag-clip-text tag-light">Englisch: Grundlagen</span>
        </li>
        <li class="list-inline-item" *ngIf="project.MinEnglishLevel == 2">
          <span class="tag  tag-clip-text tag-light">Englisch: Gut</span>
        </li>
        <li class="list-inline-item" *ngIf="project.MinEnglishLevel == 3">
          <span class="tag  tag-clip-text tag-light">Englisch: Fließend</span>
        </li>
        <li class="list-inline-item" *ngIf="project.MinEnglishLevel == 4">
          <span class="tag  tag-clip-text tag-light">Englisch: Muttersprache</span>
        </li>
      </ul>

    </div>

    <div class="required-skills mb-6" *ngIf="project?.NiceToHaves">

      <h4 class="mb-4 h5">Nice-to-Haves</h4>

      <ul class="tag-list list-inline mb-0">
        <li class="list-inline-item" *ngFor="let tag of project.NiceToHaves"><span class="tag  tag-clip-text tag-light">{{tag}}</span></li>
      </ul>

    </div>

  </div>

  <div class="col-12">

    <div class="description">

      <div class="row mb-6" *ngIf="project.Requirements">
        <div class="col-12">
          <div class="row">
            <div class="col">
              <h4 class="mb-4 h5">Anforderungen</h4>
            </div>
            <div class="col-auto">
              <button class="btn btn-round btn-secondary" (click)="briefingModal.show()"><i class="fa-sharp fa-solid fa-stars me-2"></i> Briefing</button>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-9">
              <div class="requirements" [innerHTML]="project.Requirements | bypassSecurityTrust : 'html'"></div>
            </div>
          </div>

        </div>
      </div>

      <div class="row" *ngIf="project.StatusIndex >= 5">
        <div class="col-12 col-lg-10">
          <h4 class="mb-4 h5">Aufgabenbeschreibung</h4>
          <p>{{ project.Description }}</p>
        </div>
      </div>

      <div class="row mt-6" *ngIf="project.StatusIndex >= 5">
        <div class="col-12 col-lg-10">
          <a class="btn btn-round mt-3 btn-dark" routerLink="/projects/{{ project?.Id }}/jobsite">
            Jobsite
          </a>
        </div>
      </div>

    </div>

  </div>
</div>

<sc-modal-wizard #briefingModal size="lg">
  <sc-modal-header>
    <i class="fa-sharp fa-solid fa-stars me-2"></i> KI-Briefing
  </sc-modal-header>

  <div class="modal-wizard-body">
    <sc-modal-wizard-page #chatPage>
      <sc-modal-body style="height: 75vh;" class="chat-modal-body">
        <div id="sc-chatbot-container" style="height: 100%; width: 100%;" *ngIf="briefingChat">
          <sc-chatbot-stream #chatbotStream style="z-index: 1000;" theme="light" q delayInitialServerMessage="0" [showHeader]="true" [showInitialLoader]="false" [chat]="briefingChat">
          </sc-chatbot-stream>
        </div>
      </sc-modal-body>

      <sc-modal-footer>
        <div class="container">
          <div class="row align-items-center">
          <div class="col">
            <span class="text-muted">Demo-Account: Anpassung der Anforderungen nicht möglich</span>
          </div>
          <div class="col-auto">
            <button class="btn btn-round btn-dark" disabled>
              Anforderungen anpassen
            </button>
          </div>
        </div>
      </div>

  </sc-modal-footer>
  </sc-modal-wizard-page>
  </div>
</sc-modal-wizard>
