
import {
    animate,
    state,
    style,
    transition,
    trigger,
    AnimationTriggerMetadata,
  } from '@angular/animations';


export const scChatbotAnimation: {
    readonly message: AnimationTriggerMetadata;
    readonly input: AnimationTriggerMetadata;
  } = {
    /** Animation for message bubbles */
    message: trigger('messageAnimate', [
      state('*', style({
        height: '0px',
      })),
      state('visible', style({
        height: '*',
      })),
      state('visible-instant', style({
        height: '*',
      })),
      transition('* => visible', animate('400ms ease')),
    ]),
  

    /** Animation for inputs */
    input: trigger('inputAnimate', [
      state('initial', style({
        height: '80px',
        opacity: 0,
      })),
      state('input-in', style({
        height: '*',
        opacity: 1,
      })),
      state('input-in-instant', style({
        height: '*',
        opacity: 1,
      })),
      state('input-out', style({
        height: '80px',
        opacity: 0,
        'transform-origin': 'center right'
      })),
      transition('initial => input-out', animate('0ms linear')),
      transition('* => initial', animate('0ms linear')),
      transition('* => input-in-instant', animate('0ms linear')),
      transition('* => input-in', animate('400ms ease')),
      transition('* => input-out', animate('200ms ease')),
    ]),

  };