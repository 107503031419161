import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'autosize-textarea',
  templateUrl: './autosize-textarea.component.html',
  styleUrls: ['./autosize-textarea.component.scss']
})
export class AutosizeTextareaComponent implements OnChanges, AfterViewInit {

  @Input()
  text: any;

  @Input()
  placeholder : any;

  @Input()
  size : 'md' | 'sm' | 'lg' = 'md';

  @Input()
  readonly = false;

  @Output()
  textChange = new EventEmitter<any>();

  innerText: any;

  @ViewChild('editableDiv')
  editableDiv : ElementRef;

  ngOnChanges() {
    {
      if (this.text != this.innerText && this.editableDiv) {
        this.editableDiv.nativeElement.innerText = this.text;
        this.innerText = this.text;
      }
    }
  }

  ngAfterViewInit() {
    if (this.text != this.innerText) {
      this.editableDiv.nativeElement.innerText = this.text;
      this.innerText = this.text;
    }
  }

  onInput(e)
  {
    var newValue = e.target.innerText;
    this.text = newValue;
    this.innerText = newValue;
    this.textChange.next(newValue);
  }
}
