import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Subscription, Subject, Observable, interval } from "rxjs";

@Component({
  selector: 'sc-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss']
})
export class ScCollapsibleComponent implements OnInit {

  expandedHeight : number;

  watcher: number;

  private isActive: boolean = false;

  private innerCollapsed : boolean;

  @ViewChild("container", { static: true })
  public container: ElementRef;

  @Input()
  public set collapsed(value: boolean) {

    this.innerCollapsed = value;

    if (!this.isActive) return;

    if (value) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  public duration: number = 100;

  public speed: number = 10;

  public resolution: number = 5;

  public currentHeight: number = -1;

  currentHeightString() {
    return this.currentHeight.toString() + "px";
  }

  ngOnInit() {
    var el = this.container.nativeElement;
    var expandedHeight = el.scrollHeight;
    
    this.isActive = true;
    this.currentHeight = this.innerCollapsed ? 0 : expandedHeight;

    var watch = () => {
      cancelAnimationFrame(this.watcher);

      if (this.expandedHeight !== el.scrollHeight) {
        this.expandedHeight = el.scrollHeight;
        if (!this.innerCollapsed) this.currentHeight = this.expandedHeight;
      }

      this.watcher = requestAnimationFrame(watch);
    };

    this.watcher = window.requestAnimationFrame(watch);
  }

  expand() {

    this.expandedHeight = this.container.nativeElement.scrollHeight;

    var numberOfSteps = this.duration / this.resolution;
    var increment = this.expandedHeight / this.speed;

    var subscription = interval(this.resolution).subscribe(() => {
      if (this.currentHeight >= this.expandedHeight) {
        this.currentHeight = this.expandedHeight;
        subscription.unsubscribe();
        return;
      }

      this.currentHeight += increment;
    });
  }

  collapse() {
    
    var numberOfSteps = this.duration / this.resolution;
    var increment = this.currentHeight / this.speed;

    var id = setInterval(() => {
      if (this.currentHeight <= 0) {
        this.currentHeight = 0;
        clearInterval(id);
        return;
      }

      this.currentHeight -= increment;
    }, 5);
    
  }

}
