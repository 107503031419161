import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-image-reveal',
  templateUrl: './image-reveal.component.html',
  styleUrls: ['./image-reveal.component.scss']
})
export class ImageRevealComponent {

  @ViewChild('imgWrapper')
  imgWrapper: ElementRef

  @Input()
  src: any = ''

  @Input()
  w: any = 0

  @Input()
  h: any = 0

  isRevealed = false;

  isAnimated = false;

  revealAnimated() {
    setTimeout(() => {
      this.isAnimated = true
    }, 0);
    
    setTimeout(() => {
      this.isRevealed = true
    }, 250);
  }

  revealImmediate() {
    this.isRevealed = true
  }
}
