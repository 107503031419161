<h6 class="mb-0 d-inline-block" *ngIf="activity.Author?.IsSelf">
  {{ activity.Author?.FirstName }} {{ activity.Author?.LastName }}
</h6>

<h6 class="mb-0 d-inline-block" *ngIf="!activity.Author?.IsSelf && (activity.Author?.FirstName || activity.Author?.LastName)">
  {{ activity.Author?.FirstName }} {{ activity.Author?.LastName }}
</h6>

<h6 class="mb-0 d-inline-block" *ngIf="!activity.Author?.IsSelf && !activity.Author?.FirstName && !activity.Author?.LastName && activity.Author?.EMail">
  {{ activity.Author?.EMail }}
</h6>

<h6 class="mb-0 d-inline-block" *ngIf="!activity.Author?.IsSelf && !activity.Author?.FirstName && !activity.Author?.LastName && !activity.Author?.EMail">
  Unbekannt
</h6>

<time *ngIf="humanizeDate" class="d-inline-block ms-2 mb-0 text-muted smaller" placement="right" title="{{activity.Timestamp | date : 'dd.MM.yyyy'}} um {{activity.Timestamp | date : 'HH:mm '}} Uhr">
  {{ activity.Timestamp | humanizedDate }} <a routerLink="/projects/{{ activity.Project.Id }}" *ngIf="activity.Project && showProjectName">in '{{ activity.Project.Name }} m|w|d'</a>
</time>

<time *ngIf="!humanizeDate" class="d-inline-block ms-2 mb-0 text-muted smaller" placement="right">
  am {{activity.Timestamp | date : 'dd.MM.yy'}} um {{activity.Timestamp | date : 'HH:mm '}} Uhr <a routerLink="/projects/{{ activity.Project.Id }}" *ngIf="activity.Project && showProjectName">in '{{ activity.Project.Name }} m|w|d'</a>
</time>