<div class="row justify-content-center align-items-end">

  <div class="auth-card col-auto">

    <div class="auth-blob">
      <img src="assets/blobs/blob-1.svg" alt="skillconomy Blob" class="img-fluid">
    </div>

    <div class="card card-xl">

      <div class="card-body">

        <div class="logo">
          <img src="assets/logo/skillconomy-logo-blau.svg" alt="skillconomy Logo" class="img-fluid">
        </div>

        <h2 class="text-center mb-6">Seite nicht gefunden</h2>

        <div class="d-flex justify-content-center mt-6">
          <a routerLink="/dashboard" class="link-arrow left">Zum Dashboard</a>
        </div>
      </div>


    </div>

  </div>

  <div class="auth-illu col-auto">
    <img src="assets/illus/skillconomy-bot-dizzy.svg" alt="skillconomy Illu Bot" class="img-fluid">
  </div>
</div>