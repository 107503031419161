import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ScUploadService } from '../../services/upload.service';
import { ScuiModalComponent } from '../modal/modal.component';

@Component({
  selector: 'sc-image-select-crop',
  templateUrl: './image-select-crop.component.html',
  styleUrls: ['./image-select-crop.component.scss']
})
export class ImageSelectCropComponent {

  @Input()
  aspectRatio : number = 3;

  @Input()
  maintainAspectRatio : boolean = true;

  @Input()
  resizeToWidth : number = 0;

  @Input()
  resizeToHeight : number = 0;

  @Input()
  fileName : string;

  @Input()
  fileType : string = "jpeg";

  @Input()
  minWidth : number = 0;

  @Output()
  uploaded = new EventEmitter<any>();

  @ViewChild(ScuiModalComponent)
  modal : ScuiModalComponent;

  @ViewChild('fileInput')
  fileInput : ElementRef;

  constructor(private uploadApi : ScUploadService) { }

  canUpload = false;

  isUploading = false;

  fileSelected : any;

  imageData :string;

  imageTooSmall = false;

  onChange(event: any): void {
    this.modal.show();
    setTimeout(() => { this.fileSelected = event; }, 100)
      
  }

  cropped(event) {
    this.canUpload = true;
    this.imageData = event.base64;
    this.imageTooSmall = event.width < this.minWidth;
  }

  upload() {

    if(this.isUploading) return;
    this.isUploading = true;

    var extension = "." + this.fileType.toLowerCase();
    var fileName = this.fileName?.toLowerCase();

    if (!fileName)
    {
      var now = new Date();
      fileName = now.getTime() * 1e4 + 116444736e9 + extension;
    }
    else if (!fileName.endsWith(extension))
    {
       fileName += extension;
    }

    fetch(this.imageData)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], fileName, { type: "image/" + this.fileType });
        var files = [ file ];
        this.uploadApi.upload(files, (d: any) => {
            this.modal.dismiss();
            this.fileInput.nativeElement.value = "";
            this.uploaded.emit(d);
            this.isUploading = false;
          });
      })

  }

}
