<div class="container p-0">
  <div class="row align-items-end">
    <div class="col">
      <div class="slider">
        <ngx-slider *ngIf="selectionType=='MinMax'" [(value)]="selectedMin" [(highValue)]="selectedMax" [options]="options"></ngx-slider>
		<ngx-slider *ngIf="selectionType=='Single'" [(value)]="selectedMin" [options]="options"></ngx-slider>
      </div>
    </div>
    <div class="col-auto ps-4">
      <button class="btn btn-dark btn-circle send-button" (click)="send()">
        <i class="fa-sharp fa-solid fa-paper-plane-top"></i>
      </button>
    </div>
  </div>
</div>
