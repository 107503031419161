<div class="row justify-content-center align-items-end" *ngIf="isLoaded">

    <div class="auth-card col-auto">

        <div class="auth-blob">
            <img src="assets/blobs/blob-1.svg" alt="skillconomy Blob" class="img-fluid">
        </div>

        <div class="card box-shadow" *ngIf="invalid">

            <div class="card-body">

                <div class="logo">
                    <img src="assets/logo/skillconomy-logo-blau.svg" alt="skillconomy Logo" class="img-fluid">
                </div>

                <h2 class="text-center mb-5">Der Link ist leider nicht mehr gültig!</h2>               

            </div>

            <div class="card-footer text-center">
                <small><a routerLink="/login">Hier</a> können Sie sich anmelden oder ein neues Passwort anfordern</small>
            </div>

        </div>

        <div class="card" *ngIf="!invalid">

            <div class="card-body">

                <div class="logo">
                    <img src="assets/logo/skillconomy-logo-blau.svg" alt="skillconomy Logo" class="img-fluid">
                </div>

                <h2 class="text-center mb-5">Herzlich Willkommen!</h2>

                <div class="form-group">
                    <label class="form-label">E-Mail</label>
                    <div class="input-group input-group-merge">
                        <input readonly [(ngModel)]="eMail" type="text" class="form-control" autocomplete="username">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Anrede</label>
                    <div class="input-group input-group-merge">
                        <select [(ngModel)]="gender" type="text" class="form-control" autocomplete="sex">
                            <option ngValue="f">Frau</option>
                            <option ngValue="m">Herr</option>
                            <option ngValue="d">Divers</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Vorname</label>
                    <div class="input-group input-group-merge">
                        <input [(ngModel)]="firstName" type="text" class="form-control" autocomplete="given-name">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Nachname</label>
                    <div class="input-group input-group-merge">
                        <input [(ngModel)]="lastName" type="text" class="form-control" autocomplete="family-name">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Wählen Sie Ihr Passwort</label>
                    <div class="input-group input-group-merge">
                        <input name="password" [(ngModel)]="password" type="password" class="form-control form-control" autocomplete="new-password">
                    </div>
                </div>

                <div class="row align-items-center" [hidden]="!error">
                    <div class="col">
                        <div class="alert alert-error" role="alert">
                            <p class="alert-heading mb-0">{{error}}</p>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-center">
                    <button [disabled]="isSending" class="btn w-75 btn-primary mb-3 mt-3" (click)="register()">
                        <span *ngIf="isSending" class="loader loader-sm loader-white d-inline-block"></span>
                        Registrieren
                    </button>
                </div>

            </div>

            <div class="card-footer text-center">
                <small>Sie haben bereits einen Account? <a routerLink="/login">Zum Login</a></small>
            </div>

        </div>

    </div>

    <div class="auth-illu col-auto">
        <img src="assets/illus/skillconomy-bot-waving.svg" alt="skillconomy Illu Bot" class="img-fluid">
    </div>
</div>