import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register-forbidden',
  templateUrl: './register-forbidden.component.html',
  styleUrls: ['./register-forbidden.component.scss']
})
export class RegisterForbiddenComponent implements OnInit {

  eMail = '';

  constructor(private route : ActivatedRoute, private router : Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params : any) => {
      if (params.eMail) this.eMail = params.eMail;
    })
  }

  goToLogin() {
    this.router.navigateByUrl(`/login?eMail=${this.eMail}`);
  }

}
