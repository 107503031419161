<div class="row" [scAnchorTarget]="'Gruppen'">
    <div class="col-12 section-title">
        <h3>Gruppen</h3>
    </div>
</div>

<div class="row align-items-center justify-content-between mb-5">
    <div class="col-lg-4">
        <input class="form-control form-control-sm form-control-search candidate-search" [(ngModel)]="q" (ngModelChange)="searchSoon()" placeholder="Suchen" />
    </div>
    <div class="col-auto">
        <button class="btn btn-sm btn-secondary" (click)="modal.show()">
            <i class="me-2 fa-sharp fa-solid fa-plus"></i> Gruppe erstellen
        </button>
    </div>
</div>

<div class="row">

    <div class="col-4 mt-3" *ngFor="let group of groups">
        <a routerLink="/pools/{{pool.Id}}/groups/{{group.Id}}">
            <div class="card group-card">
                <div class="card-body">
                    <h3 class="h4">
                        {{ group.Name }}
                    </h3>

                    <p class="small ">
                        {{ group.Description }}
                    </p>

                    <div class="row footer-row mb-n2 align-items-center">
                        <div class="col-auto" *ngIf="group.Candidates.length">
                            <sc-avatar-list [users]="group.Candidates"></sc-avatar-list>
                        </div>

                        <div class="col pl-0">
                            <span class="small font-weight-bold text-muted" *ngIf="group.CandidateCount > 3">+ {{ group.CandidateCount - 3}} weitere</span>
                            <span class="small font-weight-bold text-muted" *ngIf="group.CandidateCount == 0">Keine Mitglieder</span>
                        </div>

                        <div class="col-auto">
                            <span class="arrow right"></span>
                        </div>
                    </div>
                </div>
            </div>
        </a>

    </div>
</div>

<sc-modal #modal>
    <sc-modal-header>
        <div class="row align-items-center">
            <div class="col">
                <h4 class="modal-title mb-0">Gruppe erstellen</h4>
            </div>
        </div>
    </sc-modal-header>
    <div class="modal-body">
        <div class="row mb-3">
            <div class="col-12">
                <label class="form-label">Titel</label>
            </div>
            <div class="col-12">
                <input class="form-control" [(ngModel)]="newGroup.Name" />
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <label class="form-label">Beschreibung</label>
            </div>
            <div class="col-12">
                <textarea rows="5" class="form-control" [(ngModel)]="newGroup.Description" style="resize: none;"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="createGroup(); modal.close();">Fertig</button>
    </div>
</sc-modal>