import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ContentChildren, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormRowComponent } from '../form-row/form-row.component';

@Component({
  selector: 'sc-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, AfterViewInit, OnChanges {

  @ContentChildren(FormRowComponent, { descendants: true })
  rows: QueryList<FormRowComponent>;

  @Output()
  change = new EventEmitter<any>();

  @Input()
  size: 'sm' | 'md' | 'lg' = 'md';

  @Input()
  form: 'horizontal' | 'default' = 'horizontal';

  @Input()
  disabled = false;

  private _unsubscribeRows = new Subject<any>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any) {
    if (changes.disabled || changes.size) {
      this.layoutRows();
    }
  }

  ngAfterViewInit() {
    this.layoutRows();
    this.rows.changes.subscribe(() => this.layoutRows());
  }

  layoutRows()
  {
    this._unsubscribeRows.next();

    var maxLabelLength = 0;

    this.rows.forEach(r => {
      r.size = this.size;
      if (maxLabelLength > r.label?.length) return;
      maxLabelLength = r.label.length;            
    });

    this.rows.forEach(r => {
      r.change.pipe(takeUntil(this._unsubscribeRows)).subscribe(() => { 
        this.change.next(); 
      });

      setTimeout(() => {
        r.form = this.form;
        r.formDisabled = this.disabled;
        r.updateDisabledState();
        r.labelMaxLength = maxLabelLength + 3;
      });
    });
  }
}
