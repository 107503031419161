import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FontAwesomeService } from '../../services/font-awesome.service';

@Component({
  selector: 'i.fa-solid, i.fa-regular, i.fa-light, i.fa-brands, fa',
  templateUrl: './fa.component.html',
  styleUrls: ['./fa.component.scss']
})
export class FaComponent implements OnChanges, OnInit {

  svg: any = '';

  @ViewChild('faContainer')
  faContainer: ElementRef;

  @Input('class')
  icon: string;

  constructor(private fa: FontAwesomeService, private sanitizer: DomSanitizer, private changeRef: ChangeDetectorRef, private hostElement: ElementRef) { }

  loadIcon(): void {

    console.log(this.icon);

    var downloadDirectory = 'regular';
    var downloadFile = 'abacus.svg';
    var isSharpIcon = false;

    if (!this.icon) return;

    var hostClasses = this.icon.split(' ');

    var classNameToFolderMappings = {
      'fa-solid': 'solid',
      'fa-regular': 'regular',
      'fa-light': 'light',
      'fa-brands': 'brands'
    };

    var classNameToSharpFolderMappings = {
      'fa-solid': 'sharp-solid',
      'fa-regular': 'sharp-regular'
    };

    isSharpIcon = this.icon.indexOf('fa-sharp') >= 0;

    hostClasses.forEach(className => {

      if (className == "fa-sharp") return;

      if (classNameToFolderMappings[className] && !isSharpIcon) {
        downloadDirectory = classNameToFolderMappings[className];
      }

      if (classNameToSharpFolderMappings[className] && isSharpIcon) {
        downloadDirectory = classNameToSharpFolderMappings[className];
      }

      var sizeClasses = [
        "2xs", "xs", "sm", "md", "lg", "xl", "2xl",
        "1x", "2x", "3x", "4x", "5x", "6x", "7x", "8x", "9x", "10x"
      ]

      if (className.startsWith('fa-')
        && className !== 'fa-solid'
        && className !== 'fa-regular'
        && className !== 'fa-light'
        && className !== 'fa-brands'
        && !sizeClasses.map(i => 'fa-' + i).includes(className)) {
        downloadFile = className.replace('fa-', '');
      }
    });

    this.fa.getIcon(downloadDirectory, downloadFile).subscribe(d => {
      // console.log(downloadDirectory, downloadFile);
      this.svg = this.sanitizer.bypassSecurityTrustHtml(d);
      this.changeRef.detectChanges();
      var svgElem = this.faContainer.nativeElement.querySelector('svg');
      if (svgElem) {
        svgElem.classList.add('svg-inline--fa', 'fa-w-16');
        var svgPath = svgElem.querySelector('path');
        svgPath.setAttribute('fill', 'currentColor');
      }
    }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadIcon();
  }

  ngOnInit() {
    this.loadIcon();
  }

}
