import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-file-button',
  templateUrl: './file-button.component.html',
  styleUrls: ['./file-button.component.scss']
})
export class ScFileButtonComponent implements OnInit { 

  @Input() maxFileCount = 1;
  @Input('accept') acceptFileTypes = "";

  @Output() filesChanged = new EventEmitter();
  @ViewChild("file") fileEl : ElementRef;

  constructor(private el : ElementRef) { }

  ngOnInit() { }

  onChange(e) {
    this.filesChanged.emit(e.target.files);
  }

  reset(){
    this.fileEl.nativeElement.value = ""; 
  }

}
