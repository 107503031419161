import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { AppChatsService } from 'src/services/app-chats.service';

@Component({
  selector: 'app-candidate-base-project-item',
  templateUrl: './candidate-base-project-item.component.html',
  styleUrls: ['./candidate-base-project-item.component.scss']
})
export class CandidateBaseProjectItemComponent implements OnInit, OnChanges {

  @ViewChild(NgbDropdown)
  dropdown : NgbDropdown;

  @Input()
  assignment : any;

  canUpdateStatus = false;

  canDecide = false;

  constructor(private appChats : AppChatsService) { }

  ngOnInit() {
  }

  ngOnChanges()
  {
    this.canUpdateStatus = false;
    this.canDecide = false;

    this.canUpdateStatus =
      this.assignment.Access.IsDecider &&
      this.assignment.IsAccepted &&
      !this.assignment.IsRejected &&
      !this.assignment.IsHired &&
      !this.assignment.IsRevoked;

    this.canDecide = 
      this.assignment.Access.IsDecider &&
      !this.assignment.IsAccepted &&
      !this.assignment.IsRejected &&
      !this.assignment.IsHired &&
      !this.assignment.IsRevoked;
      
  }

  updateStatus()
  {    
    this.appChats.candidateStatusChat(this.assignment.Project.Id, this.assignment.CandidateId);
  }

  decide()
  {    
    this.appChats.candidateDecideChat(this.assignment.Project.Id, this.assignment.CandidateId);
  }
}
