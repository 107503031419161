<div #navContainer>
  
	<nav #nav class="bg-white">
  
	  <div class="container">
		
		<div class="row align-items-center">
		  
		  <div class="col offset-lg-1 nav-overflow-container">
			
			<!-- Nav -->
			<ul class="nav nav-tabs nav-overflow header-tabs">
			  <li class="nav-item" *ngFor="let item of menuItems; let i=index">
				<a id="navitem-{{item.anchor}}" (click)="itemClick(item)" class="nav-link" [class.active]="item.active" [class.anim-left]="item.animDirection=='left'" [class.anim-right]="item.animDirection=='right'">
				  {{ item.label | localize }}
				</a>
			  </li>
			</ul>
  
		  </div>
  
		</div>
	  
	  </div>
  
	</nav>
  </div>