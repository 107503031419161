<label>
  <ng-content></ng-content>
  <input 
    #file 
    id="file" 
    type="file"
    [multiple]="maxFileCount>1"
    [accept]="acceptFileTypes"
    (change)="onChange($event)"
  />  
</label>
