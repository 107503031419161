import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { fadeIn } from 'src/transitions';

@Component({
  selector: 'app-pool',
  templateUrl: './pool.component.html',
  styleUrls: ['./pool.component.scss'],
  animations: [ fadeIn ]
})
export class PoolComponent implements OnInit {

  id : any;

  pool : any;

  state = 'out';

  constructor(private api : ScApiService, private route : ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((params : any) => {
      if (!params.id) return;
      this.id = params.id;
      this.api.get(`pools/${params.id}`).subscribe((d : any) => {
        this.pool = d;
        this.state = 'in';
      }, e => this.api.redirectAnonymousToLogin(e))
    })
  }

}
