<input class="form-control form-control-block" [(ngModel)]="qUser" (ngModelChange)="searchSoon(); validateEMail();" placeholder="Name oder E-Mail" />

<div *ngIf="isValidEMail && !qUsers?.length && !searchPending && allowEMailInvite">

    <button class="btn btn-secondary w-100 btn-sm mt-4" (click)="inviteUserByEMail()" [disabled]="isInviting">
        <span *ngIf="!isInviting">
            <i class="fa-sharp fa-solid fa-user-plus me-2 "></i>
        </span>
        <span *ngIf="isInviting" class="spinner-border spinner-border-sm"></span>
        Einladen
    </button>
</div>

<div class="user-list mt-4" *ngIf="!searchPending">

    <p class="text-muted mb-0" *ngIf="qUser && !isValidEMail && !searchPending && qUsers?.length == 0">
        Wir haben leider keine Person mit diesem Namen gefunden. Geben Sie eine E-Mail-Adresse ein, um einen neuen Teilnehmer einzuladen.
    </p>

    <div class="row align-items-center mb-4" *ngFor="let user of qUsers" [class.is-user]="user.isAdded">

        <div class="col-auto">
            <sc-avatar size="sm" [user]="user"></sc-avatar>
        </div>

        <div class="col ms-n3">
            <h5 class="mb-0 user-name" *ngIf="user.FirstName || user.LastName">{{ user.FirstName }} {{ user.LastName }} </h5>
            <h5 class="mb-0 user-name" *ngIf="!user.FirstName && !user.LastName && user.isAdded">{{ user.EMail }}</h5>
            <h5 class="mb-0 user-name" *ngIf="!user.FirstName && !user.LastName && !user.isAdded">Nicht registriert</h5>
            <p class="mb-0 user-mail small">{{ user.EMail }}</p>
        </div>

        <div class="col-auto">
            <button class="btn btn-secondary btn-sm btn-circle" (click)="inviteUser(user)" [disabled]="user.isInviting" *ngIf="!user.isAdded">
                <span class="icon" *ngIf="!user.isAdding">
                    <i class="fa-sharp fa-solid fa-user-plus"></i>
                </span>
                <span *ngIf="user.isAdding" class="spinner-border spinner-border-sm"></span>
            </button>
            <button class="btn btn-dark btn-sm btn-circle" *ngIf="user.isAdded">
                <span class="icon">
                    <i class="fa-sharp fa-solid fa-check"></i>
                </span>
            </button>
        </div>

    </div>

</div>

<div class="alert alert-success mt-4 mb-0" *ngIf="inviteSuccess">
    <span class="icon me-3">
      <i class="fa-sharp fa-solid fa-check"></i>
    </span>
    Der Teilnehmer wurde eingeladen!
</div>