<a>
  
  <div class="notification card notification-card bg-primary-lighter card-hover">

    <div class="card-body">
      <div class="row align-items-center">

        <div class="col-auto">
  
          <span class="icon circle-danger" [class.circle-danger]="!badge">
            <i class="fa-solid fa-sharp fa-bell text-primary"></i>
          </span> 
          
          <span class="notification-counter badge badge-pill badge-danger" *ngIf="badge">{{ badge }}</span>
  
        </div>
  
        <div class="col">
          <strong><ng-content></ng-content></strong>
        </div>
  
        <div class="col-12 col-md-auto d-flex justify-content-center">
          <button class="btn btn-primary btn-sm mt-3 mt-md-0">{{ ctaLabel }}</button>
        </div>
          
      </div>
    </div>

  </div>

</a>