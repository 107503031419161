<div class="comment has-alert-item">
  <div class="row">

    <div class="col-auto">
      <sc-avatar size="sm" [user]="activity.Author"></sc-avatar>
    </div>

    <div class="col ms-n2">

      <div class="comment-header mb-2">
        <div class="row">
          <div class="col">
            <sc-activity-item-header [activity]="activity" [showProjectName]="showProjectName"></sc-activity-item-header>
          </div>
        </div>
      </div>

      <div class="comment-body">
        
        <ng-content></ng-content>

        <div class="link-container">
          <sc-item-link name="Jobsite {{ activity.Project.Name}} m|w|d" preview="{{ activity.Project.Image }}" uri="https://app.skillconomy.com/projects/{{ activity.Project.Id }}/jobsite-review"></sc-item-link>
        </div>

      </div>

    </div>
  </div>
</div>
