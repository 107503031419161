<div [ngSwitch]="items[0].Content" *ngIf="items?.length == 1">    

    <app-notification *ngSwitchCase="'#candidatedecide'" ctaLabel="Jetzt entscheiden" (click)="startChat('decide')">
        Wie soll es mit der Bewerbung weitergehen?
    </app-notification>

    <app-notification *ngSwitchCase="'#candidatestatus'" ctaLabel="Status aktualisieren" (click)="startChat('status')">
        Wie ist der Status der Bewerbung?
    </app-notification>

    <app-notification *ngSwitchCase="'#candidatefeedback'" ctaLabel="Jetzt abgeben" (click)="startChat('feedback')">
        Ihr Feedback zur Bewerbung wurde angefragt!
    </app-notification>
    
</div>

<div *ngIf="items?.length > 1">
    <app-notification [badge]="items.length" ctaLabel="Aufgaben ansehen" (click)="modal.show()">
        Ihr Einsatz ist gefragt!
    </app-notification>
</div>

<sc-modal #modal>

    <sc-modal-header>
      <div class="row align-items-center">
        <div class="col-auto me-n2">
          <span class="icon">
            <i class="fa-sharp fa-solid fa-bell"></i>
          </span>
        </div>
        <div class="col">
          <h5 class="modal-title mb-0">Aufgaben</h5>
        </div>
       </div>
    </sc-modal-header>
     
    <div class="modal-body">
        <section class="notification-group">
            <ul class="list-unstyled">
                <li *ngFor="let i of items" [ngSwitch]="i.Content">
                    <app-notifications-modal-item *ngSwitchCase="'#candidatedecide'" (click)="startChat('decide');">
                        Möchten Sie <span *ngIf="i.Candidate?.Gender == 'f'">die Bewerberin</span><span *ngIf="i.Candidate?.Gender != 'f'">den Bewerber</span> kennenlernen?
                    </app-notifications-modal-item>
                    <app-notifications-modal-item *ngSwitchCase="'#candidatestatus'" (click)="startChat('status');">
                        Wie ist der Status der Bewerbung?
                    </app-notifications-modal-item>
                    <app-notifications-modal-item *ngSwitchCase="'#candidatefeedback'" (click)="startChat('feedback');">
                        Bitte geben Sie Feedback zum Profil
                    </app-notifications-modal-item>
                </li>
            </ul>
        </section>
    </div>
</sc-modal>