<div class="card card-sm card-hover">

  <div class="card-body">

    <i class="notification-icon ms-2 size-1 text-primary fa-sharp fa-solid fa-bell"></i>

    <div class="row align-items-center">
              
      <div class="col-auto">
        <sc-avatar class="circle-danger" [user]="candidateAssignment.Candidate" size="sm"></sc-avatar>
      </div>

      <div class="col-9 ms-n2 candidate-desc">
        <h5 class="d-inline-block candidate-name mb-0">{{ candidateAssignment.Candidate.FirstName }} {{ candidateAssignment.Candidate.LastName }}</h5>
        <p class="smaller job-title" [title]="candidateAssignment.Candidate.JobTitle" *ngIf="candidateAssignment.Candidate?.JobTitle">{{ candidateAssignment.Candidate.JobTitle }}</p>
      </div>

    </div>

  </div>

</div>