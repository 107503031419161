import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate-status-badge',
  templateUrl: './candidate-status-badge.component.html',
  styleUrls: ['./candidate-status-badge.component.scss']
})
export class CandidateStatusBadgeComponent implements OnInit {

  @Input()
  assignment : any;
  
  constructor() { }

  ngOnInit() {
  }
}
