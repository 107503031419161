<div  class="progress" 
      [class.progress-sm]="styleSmall"
      [class.success]="progressSuccess"
      [class.error]="progressError != undefined"
    >
  <div 
    class="progress-bar" 
    role="progressbar"
    [style.width.%]="progressValue" 
    aria-valuemin="0" aria-valuemax="100">
  
    <span class="error-text" *ngIf="progressError">
        {{ progressError }}

        <a (click)="retryAfterError($event)" class="btn btn-secondary btn-sm">Erneut versuchen</a>
    </span>
  
  </div>
</div>