<div class="placeholder shimmer" [class.animated]="isAnimated" [class.revealed]="isRevealed">

  <div class="faux-image-wrapper">
    <div class="faux-image"></div>
  </div>

  <ng-content></ng-content>

  <!-- <img [src]="src" class="img-fluid"  [attr.width]="w ? w : null"  [attr.height]="h ? h : null" /> -->

</div>