import { Component, OnInit, HostBinding, Input } from '@angular/core';


@Component({
  selector: 'sc-chatbot-messageloader',
  templateUrl: './messageloader.component.html',
  styleUrls: ['./messageloader.component.scss']
})
export class ScChatbotMessageloaderComponent implements OnInit { 

  constructor() { }

  ngOnInit() { }

}
