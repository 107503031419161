import { Injectable, TemplateRef } from '@angular/core';
import { NavigationEnd, NavigationStart, ResolveStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TopbarService {

  public template: TemplateRef<any>;

  constructor(private router : Router) {

    this.router.events
      .pipe(filter(event => event instanceof ResolveStart))
      .subscribe(e => {
        this.template = null;
      });
   }
}
