/// <reference types="@types/googlemaps" />
import { Component, ViewChild, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'sc-chatbot-input-location',
  templateUrl: './input-map.component.html',
  styleUrls: ['./input-map.component.scss']
})
export class ScChatbotMapComponent implements OnInit {

  @ViewChild('placeInput', { static: true }) placeInputElement: any;
  @ViewChild('gmap', { static: true }) gmapElement: any;

  @Input() 
  message : any;

  @Output() 
  submit: EventEmitter<any> = new EventEmitter();

  showRadiusSelector: boolean = true;

  selectedRadius: number = 50;
  selectedPlace: google.maps.places.PlaceResult;
  selectedPlaceName: string;
  selectedPlaceVicinity: string;

  map: google.maps.Map;
  cityCircle: google.maps.Circle = null;
  cityMarker: google.maps.Marker = null;

  constructor(private mapsApiLoader: MapsAPILoader) {
  }

  ngOnInit() {

    this.mapsApiLoader.load().then(() => {

      
      var germany = new google.maps.LatLng(50.932580, 10.454166);

      var mapOptions = {
        zoom:13,
        center: germany,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true
      };
  
      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);
      var input: HTMLInputElement = this.placeInputElement.nativeElement;
      var autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.setComponentRestrictions({ 'country': ['de'] });
  
      google.maps.event.addListener(
        autocomplete,
        'place_changed',
        () => {
            this.selectedPlace = autocomplete.getPlace();
            this.render();
        }
      );
    });    
  }  

  send() {

    var message = {
      Direction: "UserToBot",
      Type: 'Location',
      Payload: {
        Radius: this.selectedRadius,
        Latitude: this.selectedPlace.geometry.location.lat(),
        Longitude: this.selectedPlace.geometry.location.lng(),
        PlaceID: this.selectedPlace.place_id,
        Vicinity: this.selectedPlace.vicinity
      }
    };

    this.submit.emit(message);
  } 

  setRadius(radius: number) {
    this.selectedRadius = radius;
    this.render();
  }

  render() {

    if ( !(this.selectedPlace && this.selectedPlace.geometry) ) { return; }

    if( this.showRadiusSelector==true ) {

      if (this.cityCircle == null) {
        this.cityCircle = new google.maps.Circle({
          strokeColor: '#0065a4',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#0065a4',
          fillOpacity: 0.35,
          map: this.map
        });
      }
  
      this.cityCircle.setCenter(this.selectedPlace.geometry.location);
      this.cityCircle.setRadius(this.selectedRadius * 1000);
  
      var bounds = this.cityCircle.getBounds();
      this.map.panTo(this.selectedPlace.geometry.location);
      this.map.fitBounds(bounds, 0);

    } else {
      // Just render a small dot.
      this.map.panTo(this.selectedPlace.geometry.location);

      if( this.cityMarker == null ) {
        this.cityMarker = new google.maps.Marker({position: this.selectedPlace.geometry.location, map: this.map});
      }
      this.cityMarker.setPosition(this.selectedPlace.geometry.location);

    }
  }

  setUserPosition(lat: number, lng: number) {
    var service = new google.maps.places.AutocompleteService();
    var placeService = new google.maps.places.PlacesService(this.map);
    var initialPosition = new google.maps.LatLng(lat, lng);

    service.getQueryPredictions({ 'location': initialPosition }, (predictions, status) => {

        if (status !== google.maps.places.PlacesServiceStatus.OK) { return; }
        var placeRequest = { placeId: predictions[0].place_id };

        placeService.getDetails(placeRequest, (r) => {
            this.placeInputElement.nativeElement.value = r.formatted_address;
            this.selectedPlace = r;
            this.render();
        });
    });
  }

  setUserLocation(location: string) {
    var service = new google.maps.places.AutocompleteService();
    var placeService = new google.maps.places.PlacesService(this.map);

    service.getQueryPredictions({ input: location }, (predictions, status) => {

        if (status !== google.maps.places.PlacesServiceStatus.OK) { return; }
        var placeRequest = { placeId: predictions[0].place_id };

        placeService.getDetails(placeRequest, (r) => {
            this.placeInputElement.nativeElement.value = r.formatted_address;
            this.selectedPlace = r;
            this.render();
        });
    });
  }


}
