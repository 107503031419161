<span class="message-control-label label-visible">

    <div *ngIf="selectedLabels.length > 1" >
        <span class="oneline d-block" *ngFor="let label of selectedLabels">
            ∙ {{label}}
        </span>
    </div>
    <div *ngIf="selectedLabels.length == 1">
        <span>{{selectedLabels[0]}}</span>
    </div>

    <div *ngIf="selectedLabels.length == 0">
        <span>Nichts ausgewählt.</span>
    </div>

</span>