import { AfterViewInit, Component, ContentChildren, OnInit, QueryList } from '@angular/core';
import { ModalWizardPageComponent } from '../modal-wizard-page/modal-wizard-page.component';

@Component({
  selector: 'sc-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit, AfterViewInit {

  @ContentChildren(ModalWizardPageComponent, { descendants: true })
  private _pages: QueryList<ModalWizardPageComponent>;

  previousPage : ModalWizardPageComponent;

  currentPage: ModalWizardPageComponent;

  ngOnInit() { 
  }

  ngAfterViewInit() {

    if (!this._pages.some(p => p.definesHeight))
    {
      this._pages.first.definesHeight = true;
    }

    this._pages.forEach(page => {
      if (page == this._pages.first) {
        this.currentPage = page;
        page.show();
      }
      else {
        page.hide();
      }
    });
  }

  navigateTo(targetPage: ModalWizardPageComponent, direction: 'rightToLeft' | 'leftToRight' | 'auto' | 'none' = 'auto') {
    
    if (targetPage == this.currentPage) return;
    this.previousPage = this.currentPage;

    var isCurrentPageOut = false;
    var isTargetPageIn = false;

    this._pages.forEach(page => {

      if (page == targetPage) {
        if (direction == 'none')
        {
          page.show();
        }
        else if (direction == 'rightToLeft' || (direction == 'auto' && isCurrentPageOut)) {
          page.slideInFromRight();
        }
        else {
          page.slideInFromLeft();
        }

        isTargetPageIn = true;
      }
      else if (page == this.currentPage)
      {
        if (direction == 'none')
        {
          page.hide();
        }
        else if (direction == 'rightToLeft' || (direction == 'auto' && !isTargetPageIn)) {
          page.slideOutToLeft();
        }
        else {
          page.slideOutToRight();
        }
        
        isCurrentPageOut = true;
      }
    });

    this.currentPage = targetPage;
  }

  navigateBack()
  {
    this.navigateTo(this.previousPage);
  }
}
