import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-status-badge',
  templateUrl: './project-status-badge.component.html',
  styleUrls: ['./project-status-badge.component.scss']
})
export class ProjectStatusBadgeComponent implements OnInit {

  @Input()
  project: any;

  status: any;

  stati = {
    AwaitingAnalysis: {
      label: 'Angefragt'
    },
    AwaitingQuote: {
      label: 'Angefragt'
    },
    AwaitingOrder: {
      label: 'Warte auf Auftrag'
    },
    AwaitingBriefing: {
      label: 'Briefing ausstehend'
    },
    AwaitingJobsite: {
      label: 'Jobsite wird erstellt'
    },
    AwaitingJobsiteFeedback: {
      label: 'Jobsite-Freigabe ausstehend'
    },
    AwaitingJobsiteChanges: {
      label: 'Jobsite wird überarbeitet'
    },
    AwaitingSourcing: {
      label: 'Erste Ansprache ausstehend'
    },
    Sourcing: {
      label: 'Erste Ansprache läuft'
    },
    AwaitingReview: {
      label: 'Review ausstehend'
    },
    AwaitingSecondSourcing: {
      label: 'Zweite Ansprache ausstehend'
    },
    SecondSourcing: {
      label: 'Zweite Ansprache läuft'
    },
    Completed: {
      label: 'Abgeschlossen'
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
