import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent implements OnInit {

  items : any = [];

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => this.init(), 500);
  }

  private init() {

    var elements = document.querySelectorAll('[anchor]');    

    for (var i = 0; i < elements.length; i++) {

      var element = elements[i];
      var headline = element.getAttribute('anchor');
      this.items.push({ Title: headline, Element : element });
      
    }
  }
}
