import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScApiService } from '../../services/api.service';
import { EMailValidation } from '../../utilities/email-validation';

@Component({
  selector: 'sc-user-list-selection',
  templateUrl: './user-list-selection.component.html',
  styleUrls: ['./user-list-selection.component.scss']
})
export class UserListSelectionComponent implements OnInit {

  @Input()
  uri : string;

  @Input()
  users : any = [];

  @Input()
  allowEMailInvite = false;

  @Input()
  loadInitially = false;

  @Output()
  addUser = new EventEmitter<any>();

  private searchTimeout : any;
  
  qUsers : any = [];

  qUser : string = '';  

  searchPending = false;

  isInviting = false;

  inviteSuccess = false;

  isValidEMail = false;

  constructor(private api : ScApiService, private changeRef : ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes : any)
  {
    if (changes.uri && this.loadInitially) {
      this.search();
    }

    if (changes.users && this.qUsers)
    {
      this.qUsers.forEach(u => {
        u.isAdded = this.users?.some(u2 => u2.Id == u.Id);
      })
    }
  }

  searchSoon()
  {         
    if (this.searchTimeout) return;

    if (!this.qUser)
    {
      this.qUsers = [];
      return;
    }

    this.searchTimeout = setTimeout(() => this.search(), 500);
  }

  validateEMail()
  {
    console.log('validating ail');
    var lowerEMail = this.qUser.toLowerCase();
    var isExistingEMail = this.qUsers.some(u => u.isAdded && u.EMail.toLowerCase() == lowerEMail);
    this.isValidEMail = !isExistingEMail && this.allowEMailInvite && EMailValidation.validate(this.qUser);
  }

  search()
  {
    this.searchTimeout = null;
    
    var uri = this.uri;

    if (this.qUser)
    {
      var encodedQ = encodeURIComponent(this.qUser);
      uri += '?q=' + encodedQ;
    }
    
    this.api.get(uri).subscribe((d : any) => {      
      var users = [];
      d.forEach(u => {
        u.isAdded = this.users?.some(u2 => u2.Id == u.Id);
        users.push(u);
      })

      this.qUsers = users;
      this.searchPending = false;      
      this.validateEMail();
    })
  }

  inviteUser(user)
  {
    user.isAdding = true;

    var onSuccess = () => {
      this.users.push(user);
      user.isAdding = false;
      user.isAdded = true;
      this.qUser = "";
    }

    var onError = () => {
      user.isAdding = false;
    }

    var args = {
      user: user,
      confirm: onSuccess,
      error: onError
    }
    
    this.addUser.next(args);
  }

  
  inviteUserByEMail()
  {
    var user : any = {
      EMail : this.qUser
    };
    this.inviteUser(user);
  }

}
