import { Component, Input, Output, ViewChild, OnInit, EventEmitter, ElementRef, OnChanges } from '@angular/core';
import { scChatbotAnimation } from '../animations';


@Component({
  selector: 'sc-chatbot-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  animations: [scChatbotAnimation.input]
})

export class ScChatbotInputComponent implements OnInit, OnChanges {

  public lock = false;

  @ViewChild('inputContainer')
  inputContainer: ElementRef;

  @Output()
  submit: EventEmitter<any> = new EventEmitter();

  @Input()
  message: any;

  botMessage : any;

  @Input()
  chat : any;

  visibilityState: string = 'initial';

  constructor() { }

  ngOnInit() {
    console.log('input init')
  }

  ngOnChanges() {
    if (this.chat.Mode == 'Bot' && this.message?.Direction == 'BotToUser')
    {
      this.botMessage = this.message;
      this.lock = false;
      setTimeout(() => {
        this.animateToHeight();
      }, 10);
    }    
  }

  send(message) {
    if (this.lock) return;
    this.lockHeight();
    this.submit.next(message);
    if (this.chat.Mode == 'Bot') 
      this.lock = true;
    else
      this.animateToHeight();
  }

  lockHeight() {
    var containerEl = this.inputContainer.nativeElement;
    var currentHeight = containerEl.offsetHeight;
    if (this['_setToAutoHeightTimeout']) clearTimeout(this['_setToAutoHeightTimeout']);
    setTimeout(() => {
      containerEl.style.height = currentHeight + 'px';
      console.log("Locking height at " + currentHeight + "px");
    }, 1);
  }

  animateToHeight() {
    var containerEl = this.inputContainer.nativeElement;
    var contentEl = containerEl.firstElementChild;
    if (contentEl) {
      var contentHeight = contentEl.scrollHeight + 26;
      var desiredContainerHeight = Math.max(contentHeight, 80);
      
      setTimeout(() => {
        containerEl.style.height = desiredContainerHeight + 'px';
        console.log("Setting to " + desiredContainerHeight + "px");
      }, 1);

      this['_setToAutoHeightTimeout'] = setTimeout(() => {
        containerEl.style.height = 'auto';
        this['_setToAutoHeightTimeout'] = null;
        console.log("Setting to auto");
      }, 501);
    }
    else
    {
      setTimeout(() => {
        containerEl.style.height = 0 + 'px';
        console.log("Setting to 0 px");
      }, 1);
    }

  }
}
