import { isPlatformBrowser } from '@angular/common';
import { ConstantPool } from '@angular/compiler';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViewportInfoService {

  width: number;
  height: number;

  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: string,) {

    var isBrowser = isPlatformBrowser(platformId);

    if (!isBrowser)
    {
      console.warn("Viewport service does not work with SSR!");
      return;
    }    

    this.getDim();

    window.addEventListener('resize', () => {
      this.getDim();
    });
  }

  getDevice() {

    if (this.width < 768) {
      this.isMobile = true;
      this.isTablet, this.isDesktop = false;
    }

    if (this.width > 768 && this.width < 992) {
      this.isTablet = true;
      this.isMobile, this.isDesktop = false;
    }

    if (this.width > 992) {
      this.isDesktop = true;
      this.isMobile, this.isTablet = false;
    }

    console.log(this.isMobile, this.isTablet, this.isDesktop)
  }

  getDim() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    console.log('vp:' + this.width + ' x ' + this.height);
    this.getDevice();
  }
}
