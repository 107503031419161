/// <reference types="@types/googlemaps" />

import { Component, OnInit, ViewChild, OnChanges, SimpleChanges, Input } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'sc-map-marker-radius',
  templateUrl: './map-marker-radius.component.html',
  styleUrls: ['./map-marker-radius.component.scss']
})
export class MapMarkerRadiusComponent implements OnInit, OnChanges {

  private _fallbackDefaultLocation = { Latitude: 50.932580, Longitude : 10.454166 };

  private _fallbackDefaultZoom = 6;

  @ViewChild('gmap', { static: true }) gmapElement: any;

  @Input()
  locations: any = [];

  @Input()
  defaultRadius: number = 50;

  @Input()
  defaultLocation: any = null;

  @Input()
  defaultZoom: number = 0;

  map: google.maps.Map;

  cityCircles: google.maps.Circle[] = [];

  cityMarkers: google.maps.Marker[] = [];

  // 52.52437, 13.41053


  constructor(private mapsApiLoader: MapsAPILoader) { }

  ngOnChanges(changes: SimpleChanges) {
    this.render();
  }

  ngOnInit() {

    this.mapsApiLoader.load().then(() => {

      var mapOptions = {
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true
      };

      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);
      this.render();
    });
  }

  render() {

    if (!this.map) return;

    if (!this.locations || this.locations.length == 0) {
      var defaultLocation = this.defaultLocation ? this.defaultLocation : this._fallbackDefaultLocation;
      var defaultZoom = this.defaultZoom && this.defaultZoom != 0 ? this.defaultZoom : this._fallbackDefaultZoom;
      var defaultLatLng = new google.maps.LatLng(defaultLocation.Latitude, defaultLocation.Longitude);

      this.map.setCenter(defaultLatLng);
      this.map.setZoom(defaultZoom);
      return;
    }

    this.cityCircles.forEach(c => { c.setMap(null) });
    this.cityCircles = [];

    this.cityMarkers.forEach(c => { c.setMap(null) });
    this.cityMarkers = [];

    var bounds = new google.maps.LatLngBounds();

    this.locations.forEach(location => {

      var pos = { lat: location.Latitude, lng: location.Longitude };

      if (location.Radius) {
        var cityCircle = new google.maps.Circle({
          strokeColor: '#0065a4',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#0065a4',
          fillOpacity: 0.35,
          map: this.map,
          center: pos,
          radius: location.Radius * 1000
        });

        this.cityCircles.push(cityCircle);

        bounds.union(cityCircle.getBounds());
      }

      else {
        var cityMarker = new google.maps.Marker({
          position: pos,
          map: this.map
        });

        this.cityMarkers.push(cityMarker);
        bounds.extend(pos);
      }
    });

    this.map.fitBounds(bounds, 20);
  }

}
