<div class="input-container" #inputContainer>

    <sc-chatbot-input-default *ngIf="chat.Mode == 'Person'" (submit)="send($event)">
    </sc-chatbot-input-default>

    <ng-container *ngIf="botMessage && chat.Mode == 'Bot'">
        <sc-chatbot-input-none *ngIf="botMessage?.Type == 'None'" [message]="botMessage" (submit)="send($event)">
        </sc-chatbot-input-none>

        <sc-chatbot-input-text *ngIf="botMessage?.Type == 'Text'" [message]="botMessage" (submit)="send($event)">
        </sc-chatbot-input-text>

        <sc-chatbot-input-yes-no *ngIf="botMessage?.Type == 'YesNo'" [message]="botMessage" (submit)="send($event)">
        </sc-chatbot-input-yes-no>

        <sc-chatbot-input-choice *ngIf="botMessage?.Type == 'Choice'" [message]="botMessage" (submit)="send($event)">
        </sc-chatbot-input-choice>

        <sc-chatbot-input-location *ngIf="botMessage?.Type == 'Location'" [message]="botMessage" (submit)="send($event)">
        </sc-chatbot-input-location>

        <sc-chatbot-input-file-upload *ngIf="botMessage?.Type == 'FileUpload'" [message]="botMessage" (submit)="send($event)">
        </sc-chatbot-input-file-upload>

        <sc-chatbot-input-range *ngIf="botMessage?.Type == 'Range'" [message]="botMessage" (submit)="send($event)">
        </sc-chatbot-input-range>
    </ng-container>

</div>

<div class="lock" [class.show]="chat?.Mode != 'Bot' || message?.isSending"></div>