<div id="backdrop" #chatbotOverlay [class.chat]="selectedChat" [class.overview]="!selectedChat">

    <div id="overlay">
        <div id="overview">
            <div class="container">
                Übersicht über Chats

                <div class="row">
                    <div class="col-12" *ngFor="let chat of chats">
                        <button (click)="selectedChat = chat;">{{ chat.Id }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="chat">
            <button id="sc-chatbot-mobile-close" class="d-md-none" (click)="hide()" #mobileChatbotClose>
                <svg class="sc-icon-close" height="18" viewBox="0 0 19 18" width="19" xmlns="http://www.w3.org/2000/svg">
                    <path d="m16.2227629.08899602 2.2482322 2.24823215-6.6335098 6.63276785 6.6335098 6.63327758-2.2482322 2.2482322-6.63327757-6.6335098-6.63276785 6.6335098-2.24823215-2.2482322 6.633-6.63327758-6.633-6.63276785 2.24823215-2.24823215 6.63276785 6.633z" fill="#fff" fill-rule="evenodd" />
                </svg>
            </button>
            <button id="sc-chatbot-mobile-close" (click)="showOverview()">
                <svg class="sc-icon-close" height="18" viewBox="0 0 19 18" width="19" xmlns="http://www.w3.org/2000/svg">
                    <path d="m16.2227629.08899602 2.2482322 2.24823215-6.6335098 6.63276785 6.6335098 6.63327758-2.2482322 2.2482322-6.63327757-6.6335098-6.63276785 6.6335098-2.24823215-2.2482322 6.633-6.63327758-6.633-6.63276785 2.24823215-2.24823215 6.63276785 6.633z" fill="#fff" fill-rule="evenodd" />
                </svg>
            </button>
            <sc-chatbot-stream *ngIf="selectedChat" #chatbotStream style="z-index: 1000;" theme="light" q delayInitialServerMessage="0" [showHeader]="true" [chat]="selectedChat">
            </sc-chatbot-stream>
        </div>
    </div>

</div>

<sc-chatbot-trigger-button #chatbotTrigger [class.d-none]="isActive" class="d-md-block" (click)="toggle()"></sc-chatbot-trigger-button>