import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate-base-reminder',
  templateUrl: './candidate-base-reminder.component.html',
  styleUrls: ['./candidate-base-reminder.component.scss']
})
export class CandidateBaseReminderComponent implements OnInit, OnChanges {

  @Input()
  reminder: any;

  isDue = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (!this.reminder) return;
    var now = new Date();
    var due = new Date(this.reminder.Due);
    this.isDue = due < now;
  }
}