import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-jobsite-salary-chart',
  templateUrl: './jobsite-salary-chart.component.html',
  styleUrls: ['./jobsite-salary-chart.component.scss']
})
export class JobsiteSalaryChartComponent implements OnInit {

  minDotLabel: string = 'relevante Erfahrung';
  minLabel: string = 'min. ca.';
  @Input() minValue: string = '80.000 €';

  maxDotLabel: string = 'ideales Profil';
  maxLabel: string = 'max. ca.';
  @Input() maxValue: string = '94.000 €';

  constructor() { }

  ngOnInit() {
  }
}
