<div class="row" [scAnchorTarget]="'Bewerber:innen'" *ngIf="project">

  <div class="col-12 section-title">
    <h3 class="mb-4">Bewerber</h3>
    
    <p *ngIf="!groups?.length">
      Es liegen noch keine Bewerber:innen vor
    </p>
  </div>

  

  <div class="col-12" *ngFor="let group of groups">
    
    <section class="candidate-group">  
      
      <h4 class="h6">{{ group.name }}</h4>
      
      <ul class="row list-unstyled">
        <li class="col-12 col-md-4" *ngFor="let a of group.items">          
          <a routerLink="/projects/{{project.Id}}/candidates/{{a.Candidate.Id}}">
            <app-candidate-card [class.has-cta]="notifications.countCandidateItems(a.Candidate.Id)" [candidateAssignment]="a"></app-candidate-card>
          </a>
        </li>
      </ul>
   
    </section>

    
  </div>
</div>
