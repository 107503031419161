import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'sc-chatbot-input-default',
  templateUrl: './input-default.component.html',
  styleUrls: ['./input-default.component.scss']
})
export class InputDefaultComponent {

  @Output() 
  submit: EventEmitter<any> = new EventEmitter();

  @ViewChild('multilineInput', { static: false })
  multilineInput : ElementRef;

  textInput : '';

  isValid = true;

  send() {
    var message = {
      Direction: "UserToBot",
      Type: 'Text',
      Payload: {      
        IsPassword:  false,
        Body: this.textInput
      }
    };

    this.textInput = '';  
    this.resizeTextArea();     
    this.submit.next(message);
  }

  resizeTextArea() {
    setTimeout(() => {
      var el = this.multilineInput.nativeElement;
      el.style.height = 'auto';
      el.style.height = el.scrollHeight + 'px';
    }, 5);
  }
}
