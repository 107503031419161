import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ScApiService, ScuiModalComponent } from '@sc-ui';
import { AppChatsService } from 'src/services/app-chats.service';

@Component({
  selector: 'app-notification-candidate',
  templateUrl: './notification-candidate.component.html',
  styleUrls: ['./notification-candidate.component.scss']
})
export class NotificationCandidateComponent implements OnInit {
  
  @Input()
  candidate : any;

  @Input()
  project : any;

  @Input()
  items : any;

  @ViewChild(ScuiModalComponent)
  modal : ScuiModalComponent;
  
  constructor(private api : ScApiService, private chatbot : AppChatsService) { }

  ngOnInit(): void {
  }

  startChat(name)
  { 
    this.chatbot.candidateChat(this.project.Id, this.candidate.Id, name);
  }

}
