import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-notification-project',
  templateUrl: './notification-project.component.html',
  styleUrls: ['./notification-project.component.scss']
})
export class NotificationProjectComponent implements OnInit {

  @Input()
  project : any;

  @Input()
  items : any;

  @ViewChild(ScuiModalComponent)
  modal : ScuiModalComponent;
  
  constructor(private router : Router) { }

  ngOnInit(): void {
  }

  navigateToCandidate(i)
  {
    this.modal.dismiss();
    this.router.navigateByUrl(`projects/${this.project.Id}/candidates/${i.Id}`);
  }

  launchJobsiteReview()
  {
    var uri = `/projects/${this.project.Id}/jobsite-review`;
    window.open(uri,'_blank');
  }

}
