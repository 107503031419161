import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService, ScApiService } from '@sc-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppChatsService } from 'src/services/app-chats.service';
import { fadeIn } from 'src/transitions';

@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.scss'],
  animations: [
    fadeIn
  ]
})
export class CandidateComponent implements OnInit, OnDestroy {

  onDestroy = new Subject<any>();
  
  state = 'out';

  notifications: any = [];

  id: any;

  projectId: any;

  candidate: any;

  project: any;

  poolId : any;

  pool : any;

  chatStarted = false;

  constructor(private api: ScApiService,
    private events: EventService,
    private route: ActivatedRoute,
    private chatbot: AppChatsService) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.onDestroy.next();
      this.id = params['id'];
      this.projectId = parseInt(params['projectId']);
      this.poolId = parseInt(params['poolId']);

      this.events
        .channel(`candidates/${this.id}`)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => this.load());

      this.load();
    });
  }

  load() {
    
    this.api.get(`candidates/${this.id}`).subscribe((d: any) => {
      this.candidate = d;

      if (!this.poolId && !this.projectId)
      {
        if (this.candidate.Assignments?.length)
        {
          this.projectId = this.candidate.Assignments[0].Project.Id;
          this.loadProject();
        }
        else if (this.candidate.PoolAssignments?.length)
        {
          this.poolId = this.candidate.PoolAssignments[0].Pool.Id;
          this.loadPool();
        }
      }

      setTimeout(() => this.state = 'in', 100);
      
    }, e => this.api.redirectAnonymousToLogin(e));

    if (this.projectId)
    {
      this.loadProject();
    }
    else if (this.poolId)
    {
      this.loadPool();
    }
  }

  loadProject()
  {
    if (this.projectId) {
      this.api.get(`projects/${this.projectId}`).subscribe(d => {
        this.project = d;
      });

      this.api.get(`projects/${this.projectId}/candidates/${this.id}/notifications`).subscribe(d => {
        this.notifications = d;
      });
    }
  }

  loadPool()
  {
    if (this.poolId) {
      this.api.get(`pools/${this.poolId}`).subscribe(d => {
        this.pool = d;
      });
    }
  }

  ngOnDestroy() {
    this.chatbot.cancelBotChat();
    this.onDestroy.next();
  }

  startChat(name) {
    this.chatbot.candidateChat(this.projectId, this.id, name);
  }

  execute(notification) {
    if (notification.Content == "#candidatedecide") {
      this.startChat('decide');
    }

    if (notification.Content == "#candidatefeedback") {
      this.startChat('feedback');
    }

    if (notification.Content == "#candidatestatus") {
      this.startChat('status');
    }
  }

}
