<sc-activity-item-simple [activity]="activity" *ngIf="condensed">
    hat <a routerLink="/pools/{{ activity.Pool?.Id }}/candidates/{{ activity.Candidate?.Id }}">
        <strong>{{ activity.Candidate?.FirstName }} {{ activity.Candidate?.LastName }}</strong>
    </a> zum Pool hinzugefügt
</sc-activity-item-simple>

<sc-activity-item-highlight icon="fa-sharp fa-solid fa-circle-check" [activity]="activity" *ngIf="!condensed">
    hat <a routerLink="/pools/{{ activity.Pool?.Id }}/candidates/{{ activity.Candidate?.Id }}">
        <strong>{{ activity.Candidate?.FirstName }} {{ activity.Candidate?.LastName }}</strong>
    </a> zum Pool hinzugefügt
</sc-activity-item-highlight>