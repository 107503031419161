import { Component, Input, OnChanges } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { NotificationsService } from 'src/services/notifications.service';

@Component({
  selector: 'app-project-candidates',
  templateUrl: './project-candidates.component.html',
  styleUrls: ['./project-candidates.component.scss']
})
export class ProjectCandidatesComponent implements OnChanges {

  @Input()
  project: any;

  candidates : any = [];

  groups: any = [];

  waitingCandidates: any = [];

  constructor(public notifications: NotificationsService, private api: ScApiService) { }

  ngOnChanges() {
    if (!this.project) return;

    this.api.get(`projects/${this.project.Id}/candidates`).subscribe((d: any) => {
      
      this.candidates = d;

      var assignments: any = d;
      if (!assignments?.length) return;

      var groups = [];

      var interested = assignments.filter(a => !a.IsAccepted && !a.IsRejected && !a.IsRevoked);
      if (interested.length > 0) {
        groups.push({
          name: 'Vorgestellt',
          items: interested
        });

        this.waitingCandidates = interested;
      }

      var accepted = assignments.filter(a => a.IsAccepted && !a.IsRejected && !a.IsHired && !a.IsRevoked);
      if (accepted.length > 0) {
        groups.push({
          name: 'In der Auswahl',
          items: accepted
        });
      }

      var hired = assignments.filter(a => a.IsHired);
      if (hired.length > 0) {
        groups.push({
          name: 'Eingestellt',
          items: hired
        });
      }

      var rejected = assignments.filter(a => (a.IsRejected || a.IsRevoked) && !a.IsHired);
      if (rejected.length > 0) {
        groups.push({
          name: 'Keine Einstellung',
          items: rejected
        });
      }

      this.groups = groups;

    });

  }
}
