<div class="chat-header">
    <div class="header-back" *ngIf="showBack" (click)="emitBack()">
        <div>
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-left" class="svg-inline--fa fa-chevron-left fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                <path fill="currentColor" d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"></path>
            </svg>
        </div>
    </div>
    <div class="header-content" [class.clickable]="isTitleClickable" (click)="onTitleClick()">
        <div class="header-avatar" *ngIf="chat?.Users.length && chat?.Users[0].Image">
            <sc-avatar [user]="chat?.Users[0]"></sc-avatar>
        </div>
        <div class="header-title" *ngIf="chat?.Users?.length">
            <div class="h3">{{ chat?.Users[0]?.DisplayName }}</div>
            <span class="subline text-muted">{{ chat?.Users[0]?.Description }}</span>
        </div>
        <div class="header-title" *ngIf="!chat?.Users?.length">
            <div class="h3">Unbekannter Chat</div>
            <span class="subline text-muted">Keine Informationen</span>
        </div>
    </div>

    <div class="header-close" *ngIf="showHide" (click)="emitHide()">
        <div>
          <i class="fa-sharp fa-regular fa-xmark"></i>
        </div>
    </div>

</div>