import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-project-base',
  templateUrl: './project-base.component.html',
  styleUrls: ['./project-base.component.scss']
})
export class ProjectBaseComponent implements OnInit {

  @Input()
  project : any;
  
  users : any = [];

  @ViewChild('editUsersModal')
  editUsersModal : TemplateRef<any>;
  
  constructor(private api : ScApiService, private changeRef : ChangeDetectorRef, public modalService: NgbModal) { }

  ngOnInit() {
  }

  ngOnChanges()
  {
    if (!this.project) return;

    this.api.get(`projects/${this.project.Id}/users`).subscribe(d => {
      this.users = d;
      this.users.forEach(u => {
        u.isAdded = true;
        u.canRemove = !u.IsManager && !u.IsDecider && !u.IsSelf;
      });
    })
  }

  getImageIndex()
  {
    return this.project.Id % 3 + 1; 
  }

  


  showUsersModal()
  {
    this.modalService.open(this.editUsersModal);
  }

}
