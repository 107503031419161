import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-action-banner',
  templateUrl: './action-banner.component.html',
  styleUrls: ['./action-banner.component.scss']
})
export class ActionBannerComponent implements OnInit {

  @Input()
  badge : string;

  @Input() 
  buttonLabel : string = "Jetzt";

  constructor() { }

  ngOnInit(): void {
  }

}
