<app-topbar></app-topbar>


<div class="content">

  <div class="container mb-5">
    <div class="row">
      <div class="col-12">
        <h1 class="heading-serif">Datenschutz&shy;erklärung</h1>
      </div>
    </div>
  </div>

  <div class="container">

    <section>

      <p>Mit dem Besuch dieser Webseite können Informationen über den Zugriff gespeichert werden. Diese Daten wie Uhrzeit, Datum und die betrachtete Seite gehören nicht zu den personenbezogenen Daten, sondern sind anonymisiert. Dies werden nur aus statistischen Zwecken erfasst. Eine Weitergabe an Dritte, zu kommerziellen oder nichtkommerziellen Zwecken, erfolgt nicht. Um dem Nutzer unsere Angebote individueller zur Verfügung stellen und auf seine Bedürfnisse anpassen zu können, kommen auf unserer Website Cookies zum Einsatz, über die ein Kunde für die Dauer seines Besuchs wiedererkannt werden kann. Ein Cookie ist eine kleine Textdatei, die von WWW-Servern beim Besuch einer Internetseite verschickt wird. Diese Cookies dienen dazu, das Online-Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Die Cookies enthalten keine personenbezogene Daten bzw. keinerlei persönliche Informationen, so dass eine Identifizierung des Nutzers allein mit den anhand der Cookies gewonnenen Informationen nicht möglich ist.</p>

      <p>Der Anbieter weist ausdrücklich darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen und nicht lückenlos vor dem Zugriff durch Dritte geschützt werden kann.</p>
  
      <p>Die Verwendung der Kontaktdaten des Impressums zur gewerblichen Werbung ist ausdrücklich nicht erwünscht, es sei denn der Anbieter hatte zuvor seine schriftliche Einwilligung erteilt oder es besteht bereits eine Geschäftsbeziehung. Der Anbieter und alle auf dieser Website genannten Personen widersprechen hiermit jeder kommerziellen Verwendung und Weitergabe ihrer Daten.</p>
  
      <p>Quelle: Disclaimer Vorlage powered by <a href="https://www.fachanwalt.de" target="_blank">fachanwalt.de</a></p> 

    </section>

  </div>

</div>
