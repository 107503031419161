import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalWizardComponent, ScApiService } from '@sc-ui';

@Component({
  selector: 'app-pool-group-candidates-add-modal',
  templateUrl: './pool-group-candidates-add-modal.component.html',
  styleUrls: ['./pool-group-candidates-add-modal.component.scss']
})
export class PoolGroupCandidatesAddModalComponent implements OnInit {

  @ViewChild(ModalWizardComponent)
  modal : ModalWizardComponent;

  @Input()
  group: any;

  @Output()
  userRemoved = new EventEmitter<any>();

  @Output()
  userAdded = new EventEmitter<any>();

  constructor(private api: ScApiService) { }

  ngOnInit(): void { }

  show()
  {
    this.modal.show();
  }

  q = '';

  hasMoreItems = false;

  isLoading = false;

  skip = 0;

  take = 10;

  candidates: any = [];  

  ngOnChanges() {
    this.load();
  }

  load() {
    this.candidates.length = 0;
    this.skip = 0;
    this.q = '';
    this.loadMore();
  }

  searchSoon() {
    if (this['_searchTimeout']) clearTimeout(this['_searchTimeout']);
    this['_searchTimeout'] = setTimeout(() => {
      this.candidates.length = 0;
      this.skip = 0;
      this.loadMore();
    }, 500);
  }

  loadMore() {
    if (!this.group) return;
    if (this.isLoading) return;

    this.isLoading = true;

    var uri = `pools/${this.group.PoolId}/candidates?skip=${this.skip}&take=${this.take}`;
    if (this.q) uri += `&q=${this.q}`;

    this.api.get(uri).subscribe(
      (d: any) => {

        d.Items.forEach(c => {
          c.canAdd = true;
          c.canRemove = true;
          c.isAdded = c.Groups.some(g => g.Group.Id == this.group.Id);
          console.log(c.isAdded);
        });

        this.skip += this.take;
        this.candidates.push.apply(this.candidates, d.Items);
        this.hasMoreItems = this.candidates.length < d.TotalCount;
        
      },
      () => { },
      () => { this.isLoading = false }
    );
  }

  removeUser(args : any)
  {    
    var user = args.user;
    user.isRemoving = true;

    this.api.delete( `pools/${this.group.PoolId}/groups/${this.group.Id}/candidates/${user.Id}`).subscribe(() => {
      user.isAdded = false;
      user.isRemoving = false;
      this.userRemoved.next(user);
      args.confirm();
    });
  }

  addUser(args : any)
  {
    var user = args.user;
    user.isAdding = true;

    this.api.post( `pools/${this.group.PoolId}/groups/${this.group.Id}/candidates`, { CandidateId : user.Id }).subscribe(() => {
      user.isAdded = true;
      user.isAdding = false;
      this.userAdded.next(user);
      args.confirm();
    });
  }

}
