import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EventService, ScApiService } from '@sc-ui';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  isLoading = false;

  isLoaded = false;

  projects : any = [];

  items: any = [];

  onModalShow = new Subject<any>();

  onModalClose = new Subject<any>();

  constructor(private router : Router, 
    private api : ScApiService,
    private events : EventService) {
    
    this.load();
    this.events.channel('users/' + this.api.userId + '/notifications').subscribe(() => this.load(true));
    
    this.api.loggedOut.subscribe(() => {
      this.projects = [];
      this.items = [];
      this.isLoaded = false;
      this.isLoading = false;
    });
  }

  load(force = false)
  {
    if (!force && (this.isLoaded || this.isLoading)) return;

    this.isLoading = true;

    this.api.get('notifications').subscribe((d : any) => { 
      
      this.items = d;

      var results = [];
      var projects = d.map(n => n.Project);

      projects.forEach(p => {
        if (!p) return;
        if (results.some(r => r.Id == p.Id)) return;
        p.Items = d.filter(i => i.Project?.Id == p.Id); 
        results.push(p);
      });

      this.projects = results;

      this.isLoaded = true;
      this.isLoading = false;    
    });
  }

  execute(i)
  {
    if (i.Content.indexOf('#project') == 0 && i.Project)
    {
      var uri = '/projects/' + i.Project.Id;

      if (i.Content == '#projectreviewjobsite' || i.Content == '#projectfeedbackjobsite')
      {
        uri += '/jobsite-review';
      }        

      this.router.navigateByUrl(uri); 
    }

    else if (i.Content.indexOf('#candidate') == 0 && i.Project && i.Candidate)
    {
      var uri = '/projects/' + i.Project.Id + '/candidates/' + i.Candidate.Id;
      this.router.navigateByUrl(uri);
      
    }

    else if (i.Content.indexOf('#appointment') == 0 && i.Payload.Appointment)
    {
      var uri = '/appointments/' + i.Payload.Appointment.Id;
      this.router.navigateByUrl(uri);
    }
  }

  showModal()
  {
    this.onModalShow.next();
  }

  closeModal()
  {
    this.onModalClose.next();
  }

  countAppointmentItems()
  {    
    if (!this.items?.length) return 0;
    return this.items.filter(i => i.Content.indexOf('#appointment') == 0).length;
  }

  countCandidateItems(candidateId)
  {
    if (!this.items?.length) return 0;
    return this.items.filter(i => i.Candidate?.Id == candidateId).length;
  }
}
