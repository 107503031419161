<div *ngIf="candidate">

  <div class="row align-items-center">
    <div class="col">
      <a class="link-arrow left" [scBackOnClick]>Zurück</a>
    </div>

    <div class="col-auto pl-0" *ngIf="candidate.AllowChat">
      <button class="btn btn-secondary btn-circle btn-sm btn-chats" (click)="startDirectChat()">
        <i class="me-1 size-1" class="fa-sharp fa-solid fa-comment"></i>
      </button>
    </div>

    <div class="col-auto pl-0">
      
      <button class="btn btn-secondary btn-sm btn-circle  btn-reminders" (click)="reminderModal.show()">
        <i class="me-1 size-1" class="fa-sharp fa-solid fa-alarm-clock"></i>

        <div class="badge" *ngIf="dueRemindersCount">
          {{ dueRemindersCount }}
        </div>

        <div class="badge badge-primary" *ngIf="!dueRemindersCount && reminders?.length">
          {{ reminders?.length }}
        </div>
      </button>
      
      <button class="btn btn-secondary btn-sm ms-3" *ngIf="!assignment?.IsRejected && assignment?.IsAccepted && !assignment?.IsRevoked" (click)="updateStatusChat()">
         Statusupdate
      </button>
    </div>

  </div>

  <div class="row align-items-start mt-6 mb-5">
    <div class="col-auto">
      <sc-avatar size="xxl" [user]="candidate"></sc-avatar>
    </div>

    <div class="col pl-4">
      <h1 class="d-inline-block mb-2">{{candidate.FirstName}} {{candidate.LastName}}</h1>
      <p class="mb-4">
        <span *ngIf="candidate.JobTitle">{{candidate.JobTitle}}</span>
        <span *ngIf="candidate.JobTitle && candidate.Company">, </span>
        <span *ngIf="candidate.Company">{{ candidate.Company}}</span>
        <span *ngIf="!candidate.JobTitle && !candidate.Company" class="text-muted">keine Angaben zur aktuellen Position</span>
      </p>

      <div class="mb-4">
        <div class="row">
          <div class="col-8" *ngFor="let a of candidate.Assignments">
            <app-candidate-base-project-item [assignment]="a"></app-candidate-base-project-item>
          </div>
          <div class="col-8" *ngFor="let a of candidate.PoolAssignments">
            <app-candidate-base-pool-item [assignment]="a"></app-candidate-base-pool-item>
          </div>
        </div>
      </div>

      <p class="mb-2" *ngIf="candidate.City">
        <i class="me-2 fa-sharp fa-solid fa-location-dot"></i>{{ candidate.City }}
      </p>

      <p class="mb-2" *ngIf="candidate.Phone">
        <i class="me-2 fa-sharp fa-solid fa-phone"></i>{{ candidate.Phone }}
      </p>
      <p class="mb-2" *ngIf="candidate.EMail">
        <a href="mailto:{{ candidate.EMail }}" class="text-primary">
          <i class="me-2 fa-sharp fa-solid fa-envelope"></i>{{ candidate.EMail }}
        </a>
      </p>
    </div>
  </div>

</div>

<app-candidate-base-reminder-modal [candidate]="candidate" [reminders]="reminders" #reminderModal></app-candidate-base-reminder-modal>
 