import { Injectable } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  me : any;

  meChange = new Subject<any>();

  constructor(private api : ScApiService) {
    
    this.load();

    this.api.loggedOut.subscribe(() => {
      this.me = {};
    });

    this.api.loggedIn.subscribe(() => {
      this.load();
    });
  }

  load()
  {
    this.api.get('me').subscribe((u: any) => {
      this.me = u;
      this.meChange.next(u);
    });
  }
}
