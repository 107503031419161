import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService, ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-pool-groups',
  templateUrl: './pool-groups.component.html',
  styleUrls: ['./pool-groups.component.scss']
})
export class PoolGroupsComponent implements OnInit {


  @ViewChild(ScuiModalComponent)
  modal : ScuiModalComponent;

  @Input()
  pool : any;


  groups : any = [];

  newGroup : any = {};

  q = '';

  hasMoreItems = false;

  isLoading = false;

  skip = 0;

  take = 25;
  
  constructor(private api : ScApiService, private router : Router) { }

  ngOnInit(): void {
  }


  ngOnChanges() {
    this.load();
  }

  load() {
    this.groups.length = 0;
    this.skip = 0;
    this.q = '';
    this.loadMore();
  }

  searchSoon() {
    if (this['_searchTimeout']) clearTimeout(this['_searchTimeout']);
    this['_searchTimeout'] = setTimeout(() => {
      this.groups.length = 0;
      this.skip = 0;
      this.loadMore();
    }, 500);
  }

  loadMore() {
    if (!this.pool) return;
    if (this.isLoading) return;

    this.isLoading = true;

    var uri = `pools/${this.pool.Id}/groups?skip=${this.skip}&take=${this.take}`;
    if (this.q) uri += `&q=${this.q}`;

    this.api.get(uri).subscribe(
      (d: any) => {
        this.skip += this.take;
        this.groups.push.apply(this.groups, d.Items);
        this.hasMoreItems = this.groups.length < d.TotalCount;
      },
      () => { },
      () => { this.isLoading = false }
    );
  }

  createGroup()
  {
    this.api.post(`pools/${this.pool.Id}/groups`, this.newGroup).subscribe((d : any) => {
      this.modal.dismiss();
      this.router.navigateByUrl(`pools/${this.pool.Id}/groups/${d.Id}`);
    });
  }


}
