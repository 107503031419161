import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'sc-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {

  @Output()
  closeClick = new EventEmitter<any>();

  @ViewChild('headerDiv', { static: true }) 
  headerDiv: ElementRef;
  
  constructor() { }

  ngOnInit(): void { }

}
