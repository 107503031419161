import { Pipe, PipeTransform } from '@angular/core';

declare var moment : any;

@Pipe({
  name: 'humanizedDateDiff'
})
export class HumanizedDateDiffPipe implements PipeTransform {

  transform(value: string, ref: string): unknown {
    if(typeof moment === "undefined") return value;
    moment.locale('de');
    moment.relativeTimeThreshold('y', 365);
    var valueMoment = value ? moment(value) : moment();
    var refMoment = ref ? moment(ref) : moment();
    return moment.duration(refMoment.diff(valueMoment)).humanize();
  }

}
