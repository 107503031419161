import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sc-chatbot-chat-list-header',
  templateUrl: './chat-list-header.component.html',
  styleUrls: ['./chat-list-header.component.scss']
})
export class ChatListHeaderComponent implements OnInit {

  @Input()
  shadow = false;

  @Input()
  showHide = true;

  @Output()
  hide = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  emitHide()
  {
    this.hide.emit();
  }
}
