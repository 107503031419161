<sc-modal-wizard #modal>
    <sc-modal-header>
        <h5 class="modal-title mb-0">
          Kandidat:in hinzufügen
        </h5>
     </sc-modal-header>

    <div class="modal-wizard-body">
        

        <sc-modal-wizard-page #addUserPage>
            <div class="modal-body">
                <input class="form-control form-control-search mb-4" [(ngModel)]="q" (ngModelChange)="searchSoon()" placeholder="Suchen" />
                <div class="user-list-container">
                    <sc-user-list [users]="candidates" (addUser)="addUser($event)" (removeUser)="removeUser($event)"></sc-user-list>
                </div>
                
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary btn-sm" (click)="modal.dismiss();">Fertig</button>
            </div>
        </sc-modal-wizard-page>

    </div>
</sc-modal-wizard>