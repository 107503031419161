import { Component, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(public location : Location, private renderer2: Renderer2) { }

  ngOnInit() {
    if (typeof document == undefined) return;
    this.renderer2.removeClass(document.body, 'loader--visible');
  }


}
