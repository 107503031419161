import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-item-file',
  templateUrl: './item-file.component.html',
  styleUrls: ['./item-file.component.scss']
})
export class ItemFileComponent implements OnInit {

  @Input()
  icon : any;

  @Input()
  name : any;

  @Input()
  uri : any;

  @Input()
  size : any;

  @Input()
  type : any;

  @Input()
  openOnClick = false;

  constructor() { }

  ngOnInit(): void {
  }

  open()
  {    
      window.open(this.uri, "_blank");
  }

  onClick()
  {
    if (this.openOnClick) this.open();
  }

}
