import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Directive({
  selector: '[showModal]'
})
export class ShowModalDirective {

  @Input('showModal')
  modal : any;

  @Input('modalSize')
  modalSize = 'md';

  @Output()
  modalDismissed = new EventEmitter<any>()

  @Output()
  modalCompleted = new EventEmitter<any>()

  @HostListener('click')
  showModal()
  {
    console.log(this.modal);

    this.modalService.open(this.modal, { size: this.modalSize }).result.then((result) => {
      this.modalCompleted.next(result);
    }, (reason) => {
      this.modalDismissed.emit(reason);
    });
  }

  constructor(private modalService: NgbModal) { }

}
