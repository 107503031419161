import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalWizardComponent, ModalWizardPageComponent, ScApiService } from '@sc-ui';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-candidate-base-reminder-modal',
  templateUrl: './candidate-base-reminder-modal.component.html',
  styleUrls: ['./candidate-base-reminder-modal.component.scss']
})
export class CandidateBaseReminderModalComponent implements OnInit {

  minDate: any = {};

  @Output()
  created = new EventEmitter<any>();

  @Output()
  deleted = new EventEmitter<any>();

  @Input()
  candidate: any;

  @Input()
  reminders: any = [];

  @ViewChild('modal')
  modal: ModalWizardComponent;

  @ViewChild('overviewPage')
  overviewPage: ModalWizardPageComponent;

  @ViewChild('editPage')
  editPage: ModalWizardPageComponent;

  selectedReminder: any = {};

  saveTimeout: any = null;

  constructor(private api: ScApiService, public userService: UserService) { }

  ngOnInit(): void {
    var date = new Date();
    this.minDate = date.toISOString();
  }

  show() {
    this.modal.show();
  }

  public new() {
    var reminder: any = {
      isNew: true,
      Users: [this.userService.me]
    };

    this.setDateInMonth(reminder, 0);
    this.selectedReminder = reminder;
    this.modal.navigateTo(this.editPage);
  }

  public select(reminder: any) {    
    reminder.Users.forEach(u => u.allowRemove = true);
    this.selectedReminder = reminder;
    this.modal.navigateTo(this.editPage);
  }  

  saveSoon() {
    if (this.saveTimeout) clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      this.save();
    }, 500);
  }

  save() {
    if (!this.selectedReminder) return;
    if (this.selectedReminder.isNew) return;
    this.api.put(`candidates/${this.candidate.Id}/reminders/${this.selectedReminder.Id}`, this.selectedReminder).subscribe((d: any) => {
      var userIds = this.selectedReminder.Users.map(u => u.Id);
      this.api.put(`candidates/${this.candidate.Id}/reminders/${this.selectedReminder.Id}/users`, userIds).subscribe();
    });
  }

  create() {

    if (!this.selectedReminder.isNew) return;

    this.api.post(`candidates/${this.candidate.Id}/reminders`, this.selectedReminder).subscribe((d: any) => {

      this.selectedReminder.Id = d.Id;
      var userIds = this.selectedReminder.Users.map(u => u.Id);
      this.api.put(`candidates/${this.candidate.Id}/reminders/${this.selectedReminder.Id}/users`, userIds).subscribe(() => {
        if (!this.reminders.length)
        {
          this.reminders.push(this.selectedReminder);
        }
        else
        {
          var firstLaterReminder = this.reminders.find(r => {
            var rDue = new Date(r.Due);
            var newReminderDue = new Date(this.selectedReminder.Due);
            return rDue < newReminderDue;
          });

          if (firstLaterReminder)
          {
            var insertionIndex = this.reminders.indexOf(firstLaterReminder);
            this.reminders.splice(insertionIndex, 0, this.selectedReminder);
          }
          else
          {
            this.reminders.push(this.selectedReminder);
          }
        }
        
        this.created.next(this.selectedReminder);
        this.modal.navigateTo(this.overviewPage);
        this.selectedReminder.isNew = false;
      });
    });
  }

  delete() {
    this.api.delete(`candidates/${this.candidate.Id}/reminders/${this.selectedReminder.Id}`).subscribe(() => {
      var i = this.reminders.indexOf(this.selectedReminder);
      this.reminders.splice(i, 1);
      this.deleted.next(this.selectedReminder);
      this.modal.navigateTo(this.overviewPage);
    })
  }

  addUser(args) {
    args.user.allowRemove = true;
    args.confirm();
    this.save();
  }

  removeUser(user) {
    var i = this.selectedReminder.Users.indexOf(user);
    this.selectedReminder.Users.splice(i, 1);
    this.save();
  }

  setDateInMonth(reminder, months) {
    var date = new Date();
    date.setMonth(date.getMonth() + months);
    reminder.Due = date.toISOString();
  }

  

}
