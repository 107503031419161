<div *ngIf="group">


<div class="row">

    <div class="col section-title">
        <h3 class="mb-4">Kandidat:innen</h3>        
    </div>

</div>

<div class="row align-items-center justify-content-lg-between mb-5">
    <div class="col col-lg-4">
        <input class="form-control form-control-search candidate-search form-control-sm" [(ngModel)]="q" (ngModelChange)="searchSoon()" placeholder="Suchen" />
    </div>
    <div class="col-auto">
        <button class="btn btn-sm btn-secondary" (click)="modal.show()">Kandidat:in hinzufügen</button>
    </div>
</div>

<div class="row" *ngIf="candidates != ''">

    <div class="col-12">
        <div class="card">
            <div class="list-group list-group-lg list-group-flush list">

                <a *ngFor="let candidate of candidates" class="list-group-item list-group-item-action candidate-list-item">

                    <div class="candidate-row clickable" routerLink="/candidates/{{candidate.Id}}">
                        <div class="avatar-col">
                            <sc-avatar [user]="candidate"></sc-avatar>
                        </div>
                        <div class="body-col">

                            <h5 class="mb-1 oneline">
                                {{candidate.FirstName}} {{ candidate.LastName }}
                            </h5>

                            <p class="card-text small mb-0 oneline">
                                <span *ngIf="candidate.JobTitle">{{candidate.JobTitle}}</span>
                                <span *ngIf="candidate.JobTitle && candidate.Company">, </span>
                                <span *ngIf="candidate.Company">{{ candidate.Company}}</span>
                                <span *ngIf="!candidate.JobTitle && !candidate.Company" class="text-muted">keine Angaben zur aktuellen Position</span>
                            </p>

                        </div>

                        <div class="status-col">
                            <span class="badge badge-status badge-pill badge-success-soft" *ngIf="candidate.Assignment.IsQualified">
                              <i class="fa-sharp fa-solid fa-circle-check"></i>
                                 Qualifiziert 
                            </span>

                            <span class="badge badge-status badge-pill badge-light" *ngIf="!candidate.Assignment.IsQualified">
                                <i class="fa-sharp fa-solid fa-circle-check"></i>
                                 Vorgestellt 
                            </span>
                        </div>

                        <div class="actions-col" (click)="$event.stopPropagation()">
                            <div ngbDropdown >
                                <div ngbDropdownToggle>
                                    <div class="file me-2 ms-2">
                                        <i class="fa-regular fa-ellipsis"></i>
                                    </div>
                                </div>
                                <div ngbDropdownMenu> 
                                    <a ngbDropdownItem (click)="removeUser(candidate)">
                                        Entfernen
                                    </a>                                   
                                </div>
                            </div>
                        </div>

                    </div>

                </a>

            </div>

            <div class="row d-flex justify-content-center mt-5 mb-5" *ngIf="hasMoreItems">
                <div class="col-auto">
                  <a class="btn btn-secondary btn-sm" [class.loading]="isLoading" (click)="loadMore()">Weitere laden</a>
                </div>
              </div>
        </div>
    </div>

</div>

</div>

<app-pool-group-candidates-add-modal [group]="group" (userAdded)="onUserAdded($event)" (userRemoved)="onUserRemoved($event)" #modal></app-pool-group-candidates-add-modal>