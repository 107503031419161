import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-register-send-link',
  templateUrl: './register-send-link.component.html',
  styleUrls: ['./register-send-link.component.scss']
})
export class RegisterSendLinkComponent implements OnInit {

  isSendingLink = false;

  eMail = '';

  sendLinkError = false;

  sendLinkSuccess = false;

  constructor(private api : ScApiService, private route : ActivatedRoute, private router : Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params : any) => {
      if (params.eMail) this.eMail = params.eMail;
    })
  }

  sendLink() {

    this.sendLinkError = false;
    this.sendLinkSuccess = false;
    this.isSendingLink = true;

    var arg = {
      EMail: this.eMail
    };

    this.api.post('account/send-registration-link', arg).subscribe(d => {
      this.sendLinkSuccess = true;
      this.isSendingLink = false;

    },
      e => {
        this.sendLinkError = true;
        this.isSendingLink = false;
      })
  }

  goToLogin() {
    this.router.navigateByUrl(`/login?eMail=${this.eMail}`);
  }

}
