<div class="sc-slider">
    <div #slider>
        <ng-content></ng-content>
    </div>

    <nav *ngIf="slideCount > 1">
        <span class="prev" (click)="prev()">
            <svg enable-background="new 0 0 28 28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"><g transform="matrix(-1 0 0 -1 28 28)"><ellipse clip-rule="evenodd" cx="14" cy="14" fill="#0062d6" fill-rule="evenodd" rx="14" ry="14"/><path d="m11.8 20 5.8-5.9-5.8-5.9" fill="none" stroke="#fff" stroke-width="2.3983"/></g></svg>
        </span>
        <span class="next" (click)="next()">
            <svg enable-background="new 0 0 28 28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"><g transform="matrix(-1 0 0 -1 28 28)"><ellipse clip-rule="evenodd" cx="14" cy="14" fill="#0062d6" fill-rule="evenodd" rx="14" ry="14"/><path d="m16.2 8-5.8 5.9 5.8 5.9" fill="none" stroke="#fff" stroke-width="2.3983"/></g></svg>
        </span>
    </nav>

    <div class="pagination" *ngIf="showPagination">
        <div class="row justify-content-{{paginationAlignment}}">
            <ul class="col-auto pagination-list list-unstyled">
                <li *ngFor="let i of indices" [class.is-active]="i == currentIndex" (click)="goto(i)"></li>
            </ul>
        </div>
    </div>
</div>