import { Component, Input, OnChanges, OnInit } from '@angular/core';

declare var moment : any;

@Component({
  selector: 'sc-activity-item-header',
  templateUrl: './activity-item-header.component.html',
  styleUrls: ['./activity-item-header.component.scss']
})
export class ActivityItemHeaderComponent implements OnInit, OnChanges {

  @Input()
  activity : any;

  @Input()
  showProjectName = false;

  humanizeDate = false;
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges()
  {
    if (!this.activity?.Timestamp) return;
    var now = new moment();
    var timestamp = new moment(this.activity.Timestamp);
    var diff = now.diff(timestamp, 'days');
    if (diff <= 7) this.humanizeDate = true;
  }

}
