<div [ngSwitch]="items[0].Content" *ngIf="items?.length == 1">   

    <app-notification *ngSwitchCase="'#candidatedecide'" [badge]="" ctaLabel="Zum Profil" (click)="navigateToCandidate(items[0].Candidate)">
        Bitte schaue dir das Profil von {{ items[0].Candidate.FirstName }} {{ items[0].Candidate.LastName }} an.
    </app-notification>

    <app-notification *ngSwitchCase="'#candidatestatus'" [badge]="" ctaLabel="Zum Profil" (click)="navigateToCandidate(items[0].Candidate)">
        Bitte geben Sie ein Statusupdate zu {{ items[0].Candidate.FirstName }} {{ items[0].Candidate.LastName }}.
    </app-notification>

    <app-notification *ngSwitchCase="'#candidatefeedback'" [badge]="" ctaLabel="Zum Profil" (click)="navigateToCandidate(items[0].Candidate)">
        Bitte geben Sie eine Einschätzung zu {{ items[0].Candidate.FirstName }} {{ items[0].Candidate.LastName }}.
    </app-notification>

    <app-notification *ngSwitchCase="'#projectreviewjobsite'" [badge]="" ctaLabel="Jetzt freigeben" (click)="launchJobsiteReview()">
        Bitte geben Sie die Jobsite frei
    </app-notification>

    <app-notification *ngSwitchCase="'#projectfeedbackjobsite'" [badge]="" ctaLabel="Jetzt feedbacken" (click)="launchJobsiteReview()">
        Bitte gib Feedback zur Jobsite
    </app-notification>

</div>

<div *ngIf="items?.length > 1">
    <app-notification [badge]="items.length" ctaLabel="Aufgaben ansehen" (click)="modal.show()">
        Ihr Einsatz ist gefragt!
    </app-notification>
</div>

<sc-modal #modal>
    <sc-modal-header>
      <div class="row align-items-center">
        <div class="col-auto me-n2">
          <span class="icon">
            <i class="fa-solid fa-sharp fa-bell"></i>
          </span>
        </div>
        <div class="col">
          <h5 class="modal-title mb-0">Aufgaben</h5>
        </div>
      </div>
    </sc-modal-header>
    <div class="modal-body">
        <section class="notification-group">
            <ul class="list-unstyled">
                <li *ngFor="let i of items" [ngSwitch]="i.Content">
                    
                    <app-notifications-modal-item *ngSwitchCase="'#candidatedecide'" (click)="navigateToCandidate(i.Candidate);">
                        Bitte schauen Sie sich das Profil von {{ i.Candidate.FirstName }} {{ i.Candidate.LastName }} an.
                    </app-notifications-modal-item>
                    <app-notifications-modal-item *ngSwitchCase="'#candidatestatus'" (click)="navigateToCandidate(i.Candidate);">
                        Bitte geben Sie ein Statusupdate zu {{ i.Candidate.FirstName }} {{ i.Candidate.LastName }}.
                    </app-notifications-modal-item>                
                    <app-notifications-modal-item *ngSwitchCase="'#candidatefeedback'" (click)="navigateToCandidate(i.Candidate);">
                        Bitte geben Sie eine Einschätzung zu {{ i.Candidate.FirstName }} {{ i.Candidate.LastName }}.
                    </app-notifications-modal-item>   
                    <app-notifications-modal-item *ngSwitchCase="'#projectreviewjobsite'" (click)="launchJobsiteReview();">
                        Bitte geben Sie die Jobsite frei
                    </app-notifications-modal-item>
                    <app-notification *ngSwitchCase="'#projectfeedbackjobsite'" (click)="launchJobsiteReview()">
                        Bitte geben Sie Feedback zur Jobsite
                    </app-notification>
                </li>
            </ul>
        </section>
    </div>
</sc-modal>