import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '../services/localization.service';


@Pipe({
  name: 'localize'
})
export class LocalizePipe implements PipeTransform {

  constructor(private localization : LocalizationService)
  {  }

  transform(value: any, args?: any): any {
    return this.localization.localize(value);
  }

}
