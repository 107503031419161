import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

declare var moment : any;

@Component({
  selector: 'sc-date-picker-inline',
  templateUrl: './date-picker-inline.component.html',
  styleUrls: ['./date-picker-inline.component.scss']
})
export class DatepickerInlineComponent implements OnInit {

  @Input()
  size: string;

  @Input()
  timeSelectable = false;

  @Input()
  rangeSelectable = false;
  
  @Input()
  date: string; 
  
  @Output()
  dateChange = new EventEmitter();

  @Input()
  end: string;

  @Input()
  endChange = new EventEmitter();  

  defaultHour = 12;

  defaultMinute = 0;

  hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

  minutes = [0, 15, 30, 45];

  constructor() { }

  ngOnInit() {
  }

  setStartHours(hours)
  {
    var dateValue = new Date(this.date);
    dateValue.setHours(hours);
    this.date = dateValue.toISOString();
    this.dateChange.next(this.date);
  }

  setStartMinutes(minutes)
  {
    var dateValue = new Date(this.date);
    dateValue.setMinutes(minutes);
    this.date = dateValue.toISOString();
    this.dateChange.next(this.date);
  }

  setEndHours(hours)
  {
    var dateValue = new Date(this.end);
    dateValue.setHours(hours);
    this.end = dateValue.toISOString();
    this.endChange.next(this.end);
  }

  setEndMinutes(minutes)
  {
    var dateValue = new Date(this.end);
    dateValue.setMinutes(minutes);
    this.end = dateValue.toISOString();
    this.endChange.next(this.end);
  }

  setDate(value)
  {    
    var dateValue = new Date(value);
    var currentHour = this.date ? new Date(this.date).getHours() : 0;
    var currentMinute = this.date ? new Date(this.date).getMinutes() : 0;

    dateValue.setHours(currentHour);
    dateValue.setMinutes(currentMinute);

    this.date = dateValue.toISOString();
    this.dateChange.next(this.date);
  }

  

  

  
}