import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate-pool-status-badge',
  templateUrl: './candidate-pool-status-badge.component.html',
  styleUrls: ['./candidate-pool-status-badge.component.scss']
})
export class CandidatePoolStatusBadgeComponent implements OnInit {

  @Input()
  status: any;

  _status : any;

  stati = {
    Added: {
      label: 'Vorgestellt',
      color: 'purple',
      icon: 'fa-solid fa-sharp fa-question-circle'
    },
    Qualified: {
      label: 'Qualifiziert',
      color: 'lime',
      icon: 'fa-solid fa-sharp fa-user'
    },
    Removed: {
      label: 'Abgelehnt',
      color: 'red',
      icon: 'fa-solid fa-sharp fa-times'
    },
    Resigned: {
      label: 'Ausgetreten',
      color: 'red',
      icon: 'fa-solid fa-sharp fa-times'
    },
    Hired: {
      label: 'Eingestellt',
      color: 'green',
      icon: 'fa-solid fa-sharp fa-check'
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges()
  {    
    if (!this.status) return;
    this._status = this.stati[this.status];
  }

}
