import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'sc-chatbot-chat-date',
  templateUrl: './chat-date.component.html',
  styleUrls: ['./chat-date.component.scss']
})
export class ChatDateComponent implements OnInit, OnChanges {

  @Input()
  date : string;

  displayDate = 'Gestern';

  constructor() { }

  ngOnInit() {
    setInterval(() => {
      this.updateDisplayDate();
    }, 15 * 60 * 1000)
  }

  ngOnChanges()
  {
    this.updateDisplayDate();
  }

  updateDisplayDate()
  {
    var isoDate = this.date;

    if (!isoDate) {
      this.displayDate = '';
      return;
    }

    var date = new Date(isoDate);
    var now = new Date();

    if (this.isSameDay(date, now)) {
      var MM = String(date.getMinutes()).padStart(2, '0');
      var HH = String(date.getHours()).padStart(2, '0');
      this.displayDate = `${HH}:${MM}`;
      return;
    }

    var yesterdaySameTime = new Date();
    yesterdaySameTime.setDate(yesterdaySameTime.getDate() - 1);

    if (this.isSameDay(date, yesterdaySameTime)) {
      this.displayDate = 'Gestern';
      return;
    }

    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); 
    var yy = String(date.getFullYear() - 2000).padStart(2, '0');;

    this.displayDate =  `${dd}.${mm}.${yy}`;
  }

  isSameDay(date1 : Date, date2 : Date) : boolean
  {
    return date1.getDate() == date2.getDate() &&
      date1.getMonth() == date2.getMonth() &&
      date1.getFullYear() == date2.getFullYear();
  }

}
