<div class="row justify-content-center align-items-end">

  <div class="auth-card col-auto">

    <div class="auth-blob">
      <img src="assets/blobs/blob-1.svg" alt="skillconomy Blob" class="img-fluid">
    </div>

    <div class="card card-xl">

      <div class="card-body">

        <div class="logo">
          <img src="assets/logo/skillconomy-logo-blau.svg" alt="skillconomy Logo" class="img-fluid">
        </div>

        <h2 class="text-center mb-5">Passwort vergessen</h2>


        <form>

          <div class="form-group">

            <label class="form-label">E-Mail</label>
            <input name="eMail" [(ngModel)]="eMail" type="text" class="form-control" placeholder="E-Mail">
          
          </div>

          <div class="row align-items-center" *ngIf="resetError">
            <div class="col">
              <div class="alert alert-error mt-4" role="alert">
                <p class="alert-heading mb-0">
                  Das Passwort konnte nicht zurückgesetzt werden.
                </p>
              </div>
            </div>
          </div>   

          <div class="d-flex justify-content-center">
            <div class="row align-items-center" *ngIf="resetSuccess">
              <div class="col">
                <div class="alert alert-success mt-4" role="alert">
                  <p class="alert-heading mb-0">
                    <span class="icon me-3">
                      <i class="fa-solid fa-check"></i>
                    </span>    
                    Das Passwort wurde Ihnen zugesendet. <a (click)="goToLogin()">Zurück zur Anmeldung</a>
                  </p>
                </div>
              </div>
            </div>    

            <button class="btn btn-primary mt-4" *ngIf="!resetSuccess" [disabled]="isResetting" (click)="reset()">
              <span *ngIf="isResetting" class="spinner-border spinner-border-sm"></span>
              Passwort zurücksetzen
            </button>

          </div>
          
        </form>

      </div>

      <div class="card-footer text-center">
        <small>Sie haben bereits einen Account? <a (click)="goToLogin()">Anmelden</a></small>
      </div>

    </div>

  </div>
  
  <div class="auth-illu col-auto">
    <img src="assets/illus/skillconomy-bot-waving.svg" alt="skillconomy Illu Bot" class="img-fluid">
  </div>

</div>