import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate-skills',
  templateUrl: './candidate-skills.component.html',
  styleUrls: ['./candidate-skills.component.scss']
})
export class CandidateSkillsComponent implements OnInit {

  @Input()
  candidate : any;

  constructor() { }

  ngOnInit(): void {
  }

}
