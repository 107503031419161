import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sc-avatar-list',
  templateUrl: './avatar-list.component.html',
  styleUrls: ['./avatar-list.component.scss']
})
export class AvatarListComponent implements OnInit {


  @Input()
  users: any;

  @Input()
  size : string = "sm";

  itemsToShow = 5;

  constructor() { }

  ngOnInit() {
  }

}
