import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { LabelType, Options } from "@angular-slider/ngx-slider";

@Component({
  selector: 'sc-chatbot-input-range',
  templateUrl: './input-range.component.html',
  styleUrls: ['./input-range.component.scss']
})
export class InputRangeComponent implements OnChanges {

  @Input()
  message: any;

  @Output()
  submit: EventEmitter<any> = new EventEmitter();

  selectedMin: number = 10;

  selectedMax: number = 90;

  selectionType = 'MinMax';
  
  options: Options = {};

  ngOnChanges() {

    if (this.message?.Payload)
    {
      this.selectedMin = this.message.Payload.SelectedMin;
      this.selectedMax = this.message.Payload.SelectedMax;
      this.selectionType = this.message.Payload.SelectionType;

      this.options = {
        floor: this.message.Payload.Min,
        ceil: this.message.Payload.Max,
        step: this.message.Payload.Step,
        showTicks: this.message.Payload.ShowTicks,
        hideLimitLabels: true,
        translate: (value: number, label: LabelType): string => {
          if (!this.message.Payload.Template)
            return value.toString();

          return this.message.Payload.Template.replace('{}', value.toString())
        }
      };
    }

  }

  send() {

    console.log('sending text');

    var label = this.message.Payload.Template.replace('{}', this.selectedMin.toString());

    if (this.selectionType == 'MinMax')
    {
      label += ' bis ' + this.message.Payload.Template.replace('{}', this.selectedMax.toString());
    }

    var message = {
      Direction: "UserToBot",
      Type: 'Range',
      Payload: {      
        SelectedMin:  this.selectedMin,
        SelectedMax: this.selectedMax,
        SelectionLabel: label
      }
    }; 

    this.submit.next(message);
  }


}
