<ng-template [appTopbarTemplate]>
    <div class="row align-items-center no-gutters" *ngIf="pool">
        <div class="col d-none d-md-block">
            <h4 class="mb-0 pool-title oneline">{{ pool?.Name }}</h4>
        </div>
        <div class="col-auto mt-md-1">  
            <app-nav-tabs></app-nav-tabs>
        </div>
    </div>
  </ng-template>
  
  <div class="scroll-dummy" *ngIf="!pool"></div>
  
  <div class="content" [@fadeIn]="state">  
  
    <div class="container-xl">
      <div class="row">
        <div class="col-12">
          <div class="card card-xl pool-content">

            <div class="card-body">
              <app-pool-base [pool]="pool"></app-pool-base>
              <app-pool-candidates [pool]="pool"></app-pool-candidates>
              <app-pool-groups [pool]="pool"></app-pool-groups>
            </div>

          </div>
        </div>
        <div class="col-12 mt-5">
          <div class="card card-xl pool-content">
            <div class="card-body">
              <app-pool-activities [pool]="pool"></app-pool-activities>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!--<app-footer-blob></app-footer-blob>-->
  