import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserListSelectionComponent } from '@sc-ui';

@Component({
  selector: 'sc-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  @Input()
  users : any = [];

  @Output()
  removeUser = new EventEmitter<any>();

  @Output()
  addUser = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit(): void {
  }

  remove(user)
  {
    user.isRemoving = true;

    var onSuccess = () => {
      user.isRemoving = false;
      user.isRemoved = true;
    }

    var onError = () => {
      user.isRemoving = false;
    }

    var args = {
      user: user,
      confirm: onSuccess,
      error: onError
    }
    
    this.removeUser.next(args);
  }

  add(user)
  {
    user.isAdding = true;

    var onSuccess = () => {
      user.isAdding = false;
      user.isAdded = true;
    }

    var onError = () => {
      user.isAdding = false;
    }

    var args = {
      user: user,
      confirm: onSuccess,
      error: onError
    }
    
    this.addUser.next(args);
  }

}
