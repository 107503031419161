<div class="scroll-dummy" *ngIf="state == 'out'"></div>

<div class="content" [@fadeIn]="state">

    <div class="container mb-5">
        <div class="row">
            <div class="col">
                <h1 class="heading-serif">Pools</h1>
            </div>
        </div>
    </div>

    <div class="container">

        <div class="row">
            <div class="col-12">

                <div class="card">

                    <div class="card-header">

                        <form>
                            <div class="input-group input-group-flush input-group-merge">
                                <input type="search" name="q" [(ngModel)]="q" (ngModelChange)="searchSoon()" class="form-control form-control-prepended search" placeholder="Suchen">
                                <div class="input-group-prepend me-3">
                                    <div class="input-group-text">
                                      <i class="fa-sharp fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>

                    <div class="list-group list-group-flush">

                        <a *ngFor="let item of pools" routerLink="/pools/{{item.Id}}" class="list-group-item list-group-item-action">

                            <div class="item-row align-items-center">

                                <div class="image-col">
                                     <div class="image-container">
                                        <img *ngIf="item.Image" [src]="item.Image" />
                                        <img *ngIf="!item.Image" src="/assets/project-hero-placeholder/hero-placeholder-{{item.Id % 3 + 1}}.jpg" class="card-img-top" />
                                    </div>
                                </div>                               

                                <div class="body-col">
                                    <h4 class="h5 mb-0">
                                        {{item.Name}}
                                    </h4>
                                    <p class="card-text text-muted small mb-0 oneline">
                                        {{item.Description }}
                                    </p>
                                </div>

                                <!--<div class="actions-col">
                                    <span class="arrow right"></span>
                                </div>-->

                            </div>

                        </a>

                    </div>

                    <div class="row d-flex justify-content-center mt-5 mb-5" *ngIf="hasMoreItems">
                        <div class="col-auto">
                            <a class="btn btn-secondary btn-sm" [class.loading]="isLoading" (click)="loadMore()">Weitere laden</a>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>

</div>

<!--<app-footer-blob></app-footer-blob>-->