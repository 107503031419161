import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(public router: Router, @Inject(PLATFORM_ID) private platformId: string) {



    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        if (!isPlatformBrowser(this.platformId)) return;
        if (gtag == undefined) return;

        gtag('config', 'G-ZWELNG3L6Z',
          {
            'page_path': event.urlAfterRedirects
          }
        );

        gtag('consent', 'default', {
          'ad_storage': 'denied',
          'ad_user_data': 'denied',
          'ad_personalization': 'denied',
          'analytics_storage': 'granted'
        });
      }
    }
    )
  }

  public track(
    eventName: string,
    eventCategory: string = null,
    eventAction: string = null,
    eventLabel: string = null,
    eventValue: number = null) {

    if (!isPlatformBrowser(this.platformId)) return;
    if (gtag == undefined) return;



    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue
    })
  }

}
