import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sc-chatbot-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss']
})
export class ChatHeaderComponent implements OnInit {

  @Input()
  chat : any;

  @Output()
  titleClick = new EventEmitter<any>();
  
  @Input()
  isTitleClickable = false;

  @Input()
  showBack = true;

  @Input()
  showHide = true;

  @Output()
  hide = new EventEmitter<any>();

  @Output()
  back = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onTitleClick()
  {
    if (!this.isTitleClickable) return;
    this.titleClick.next();
  }

  emitHide()
  {
    this.hide.emit();
  }

  emitBack()
  {    
    this.back.emit();
  }

}
