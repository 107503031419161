import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScApiService, ScuiModalComponent } from '@sc-ui';

@Component({
  selector: 'app-pool-group-base',
  templateUrl: './pool-group-base.component.html',
  styleUrls: ['./pool-group-base.component.scss']
})
export class PoolGroupBaseComponent implements OnInit {

  @ViewChild('deleteModal')
  deleteModal : ScuiModalComponent;

  @Input()
  group: any;

  isDeleting = false;



  constructor(private api: ScApiService, private router: Router) { }

  ngOnInit(): void {
  }

  saveSoon() {
    if (this['_saveTimeout']) clearTimeout(this['_saveTimeout']);
    this['_saveTimeout'] = setTimeout(() => {
      this.save();
    }, 500);
  }

  save() {
    this.api.put(`pools/${this.group.PoolId}/groups/${this.group.Id}`, this.group).subscribe(() => { });
  }

  delete() {
    this.isDeleting = true;

    this.api.delete(`pools/${this.group.PoolId}/groups/${this.group.Id}`).subscribe(() => {
      this.isDeleting = false;
      this.deleteModal.dismiss();
      this.router.navigateByUrl(`/pools/${this.group.PoolId}`);
    });
  }

}
