import { isPlatformServer } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { ImageRevealComponent } from 'refs/sc-ui/components/image-reveal/image-reveal.component';

@Component({
  selector: 'app-jobsite-header',
  templateUrl: './jobsite-header.component.html',
  styleUrls: ['./jobsite-header.component.scss']
})
export class JobsiteHeaderComponent  implements AfterViewInit {  

  @ViewChild('header') 
  headerElement: ElementRef;

  @ViewChild('banner')
  banner : ImageRevealComponent;

  @ViewChild('headlineReveal')
  headlineReveal : ImageRevealComponent;

  @ViewChild('logoReveal')
  logoReveal : ImageRevealComponent;

  @Input('job') 
  job : any;

  headerImg: any = { 'src': 'assets/img/company/hero-img-l.jpg', 'srcSmall': 'assets/img/company/hero-img-s.jpg', 'alt': 'alt-text' };

  jobTitleSuffix: string = 'm|w|d';

  constructor(
    private renderer2: Renderer2, 
    @Inject(PLATFORM_ID) private platformId: string) { }

  ngAfterViewInit() {

    console.log('header!')
    console.log(this.job)

    if (isPlatformServer(this.platformId)) return;

    setTimeout( () => {
      this.renderer2.addClass(this.headerElement.nativeElement, 'shadow-in');
    }, 1000);

  }

  scrollToMap() {
    if (isPlatformServer(this.platformId)) return;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async reveal()
  {
    await this.delay(1000);
    this.banner.revealAnimated();
    await this.delay(1000);
    this.headlineReveal.revealAnimated();
    await this.delay(1000);
    this.logoReveal.revealAnimated();
    await this.delay(1000);
  }

  async revealImmediate()
  {
    
    this.headlineReveal.revealImmediate();
    this.logoReveal.revealImmediate();
    await this.delay(500);
    this.banner.revealAnimated();
  }

}
