import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ScUploadService } from '../../services/upload.service';

@Component({
  selector: 'sc-image-select',
  templateUrl: './image-select.component.html',
  styleUrls: ['./image-select.component.css']
})
export class ImageSelectComponent implements OnInit {

  @Input()
  image : string;

  @Output()
  imageChange = new EventEmitter<any>();

  constructor(private uploadApi : ScUploadService) { }

  ngOnInit() {
  }

  upload(files) {
    this.uploadApi.upload(files, (d: any) => {
      this.image = d.Uri;
      this.imageChange.emit(d.Uri);
    });
  }

}