import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-activity-item-candidate-added-to-pool',
  templateUrl: './activity-item-candidate-added-to-pool.component.html',
  styleUrls: ['./activity-item-candidate-added-to-pool.component.scss']
})
export class ActivityItemCandidateAddedToPoolComponent implements OnInit {

  @Input()
  activity : any;

  @Input()
  condensed = false;
  
  constructor() { }

  ngOnInit(): void {
  }
}
