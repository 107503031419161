<div class="card shadow clickable mb-3" *ngIf="assignment" (click)="dropdown.toggle()">
    <div class="card-body">
        <div class="item-row">
            <div class="image-col">
                <div class="image-container">
                    <img *ngIf="assignment.Pool.Image" [src]="assignment.Pool.Image | imageSize : 80 : 50" />
                    <img *ngIf="!assignment.Pool.Image" src="/assets/project-hero-placeholder/hero-placeholder-{{assignment.Pool.Id % 3 + 1}}.jpg" class="card-img-top" />
                </div>
            </div>

            <div class="body-col">
                <h5 class="mb-0 oneline">{{ assignment.Pool.Name}}</h5>
                <div class="status-badge">
                    <app-candidate-pool-status-badge class="mt-4" [status]="assignment.Status"></app-candidate-pool-status-badge>
                </div>
            </div>
            <div class="actions-col">
                <div ngbDropdown>
                    <div ngbDropdownAnchor>
                        <div class="file me-2 ms-2">
                            <i class="fa-regular fa-ellipsis"></i>
                        </div>
                    </div>
                    <div ngbDropdownMenu>
                        <a ngbDropdownItem (click)="updateStatus('Added')">
                            Vorgestellt
                        </a>
                        <a ngbDropdownItem (click)="updateStatus('Qualified')">
                            Qualifiziert
                        </a>
                        <a ngbDropdownItem (click)="updateStatus('Removed')">
                            Entfernt
                        </a>
                        <a ngbDropdownItem (click)="updateStatus('Hired')">
                            Eingestellt
                        </a>
                        <hr class="my-1">
                        <a ngbDropdownItem routerLink="/pools/{{assignment.Pool.Id}}">
                          <i class="fa-sharp fa-solid fa-arrow-up-right-from-square me-2"></i> Zum Pool
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>