<div class="message-control-label label-visible">

    <div class="row">
        <div class="col-1 pe-2">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file-upload" class="svg-inline--fa fa-file-upload fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M369.83 97.98L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V131.97c0-12.69-5.17-24.99-14.17-33.99zM255.95 51.99l76.09 76.08h-76.09V51.99zM336 464.01H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v287.95zM182.98 227.79l-72.31 71.77c-7.6 7.54-2.26 20.52 8.45 20.52H168v84c0 6.63 5.37 12 12 12h24c6.63 0 12-5.37 12-12v-84h48.88c10.71 0 16.05-12.97 8.45-20.52l-72.31-71.77c-4.99-4.95-13.05-4.95-18.04 0z"></path></svg>
        </div>
        <div class="col-11 filename">
            <span>{{ message.Payload.FileName }}</span>
        </div>
    </div>

</div>