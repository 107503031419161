<ng-template #modal size="sm">    
    <ng-content></ng-content>
</ng-template>

<!-- <div class="modal-backdrop fade" [class.show]="isShown" (click)="dismiss()" *ngIf="isOpen">
</div>

<div class="d-block modal modal-window fade" [class.show]="isShown" (click)="dismiss()" *ngIf="isOpen">
  <div class="modal-dialog modal-dialog-centered modal-{{size}}">
    <div class="modal-content" (click)="$event.stopPropagation();">
      <ng-content></ng-content>
    </div>
  </div>
</div> -->
