import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ScApiService) { }

  requestedUserId: number;

  eMail: string;

  password: string;

  successMessage: string = "";

  loginError: string;

  resetError: string;

  eMailError: string;

  passwordError: string;

  showPasswordReset: boolean;

  target: string = '/dashboard';

  secret: string;

  isSending: boolean = false;

  ngOnInit() {

    this.api.get('me').subscribe((d: any) => {
      if (d.IsLoggedIn && (!this.requestedUserId || this.requestedUserId == d.Id)) {
        this.navigate();
      }
    });

    this.route.queryParams
      .subscribe(params => {
        if (params.target) {
          this.target = params.target;
        }

        if (params.secret) {
          this.secret = params.secret;
        }

        if (params.eMail) {
          this.eMail = params.eMail;
        }

        if (params.u) {
          this.requestedUserId = params.u;
        }
      });
  }

  login() {

    this.loginError = "";
    this.resetError = "";
    this.eMailError = "";
    this.passwordError = "";
    this.successMessage = "";


    if (!this.password && !this.eMail) {
      this.loginError = "Bitte geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein";
      return;
    }

    if (!this.password) {
      this.loginError = "Bitte geben Sie Ihr Passwort ein";
      return;
    }

    if (!this.eMail) {
      this.loginError = "Bitte geben Sie Ihre E-Mail-Adresse ein";
      return;
    }

    this.isSending = true;

    this.api.login(this.eMail, this.password).subscribe(
      (d: any) => {
        
        this.isSending = false;

        if (d.Succeeded) {

          if (!this.secret) {
            this.navigate();
          }
          else {
            var arg = {
              Secret: this.secret
            };

            this.api.post("account/merge", arg).subscribe(
              d => {
                this.navigate();
              },
              error => {
                this.loginError = "Die Einladung konnte nicht zur Shortlist umgeschrieben werden. Bitte wenden Sie sich an skillconomy.";
              })
          }
        }

        else {
          if (d.IsRegistered == false && d.CanRegister)
          {
            this.router.navigateByUrl(`/register-send-link?eMail=${this.eMail}`);
          }
          if (d.IsRegistered == false && d.CanRegister == false)
          {
            this.router.navigateByUrl(`/register-forbidden?eMail=${this.eMail}`);
          }
          this.loginError = d.Error;
        }
      },
      error => {
        console.log(error);
        this.isSending = false;
        if (error.message)
          this.loginError = error.message;
        else
          this.loginError = "Ein Fehler ist aufgetreten";
      });
  }

  navigate() {
    this.router.navigateByUrl(this.target);
  }

  forgotPassword() {
    this.router.navigateByUrl(`/forgot-pw?eMail=${this.eMail}`);
  }


}
