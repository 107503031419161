<div class="card mb-3" *ngIf="reminder">

    <div class="card-body">

        <div class="row">
            <div class="col-auto d-flex align-items-center icon-col">
                <div class="icon me-2 ms-2">
                    <i class="fa-sharp fa-solid fa-alarm-clock"></i>
                    <div *ngIf="isDue" class="badge"></div>
                </div>
            </div>

            <div class="col-9">
                <div class="date-name me-2">
                    <h4 class="h5 mb-0 oneline">{{ reminder.Description}}</h4>
                    <p *ngIf="reminder.Due" class="mb-0 small text-muted">{{ reminder.Due | date}}</p>
                    <p *ngIf="!reminder.Due" class="mb-0 small text-muted">Kein Datum gesetzt</p>
                </div>
            </div>

            <!--<div class="col-1 d-flex align-items-center">
                <span class="arrow right"></span>
            </div>-->
        </div>
    </div>
</div>