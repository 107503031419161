import { Component, OnInit } from '@angular/core';
import { EventService, ScApiService } from '@sc-ui';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppChatsService } from 'src/services/app-chats.service';
import { UserService } from 'src/services/user.service';
import { fadeIn } from 'src/transitions';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  animations: [ fadeIn ]
})
export class ProjectsComponent implements OnInit {

  state = 'out';

  show = 'latest';

  hasMoreItems = false;

  isLoading = false;

  page = 1;

  q = '';

  projects : any = [];

  totalCount = 0;

  constructor(private api : ScApiService, private chatbot : AppChatsService, private events : EventService, public user : UserService) { }

  ngOnInit() {
    
    this.search();

    this.events.channel(`users/${this.api.userId}/dashboard`)
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.search();
      }
    );
  } 

  newProject()
  {    
    this.chatbot.newProjectChat();
  }

  searchTimeout = null;

  searchSoon()
  {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      this.search();
    }, 500);

  }

  search()
  {
    this.page = 0;
    this.projects = [];
    this.loadMore();
  }

  loadMore()
  {    
    this.page++;
    // this.isLoading = true;

    this.api.get(`projects/search?q=${this.q}&page=${this.page}`).subscribe((d : any) => {
      this.projects.push.apply(this.projects, d.Projects);
      this.totalCount = d.TotalCount;
      this.hasMoreItems = this.projects.length < d.TotalCount;
      this.isLoading = false;
      this.state = 'in';
    }, e => this.api.redirectAnonymousToLogin(e));
  }

}
