<ng-template [appTopbarTemplate]>
  <div class="row align-items-center no-gutters" *ngIf="project">
      <div class="col d-none d-md-block">
          <h4 class="mb-0 project-title oneline">{{ project?.Name }} <span class="small fw-normal">m|w|d</span></h4>
      </div>
      <div class="col-auto mt-md-1">
          <app-nav-tabs></app-nav-tabs>
      </div>
  </div>
</ng-template>

<div class="scroll-dummy" *ngIf="!project"></div>

<div class="content" [@fadeIn]="state">

  <div class="container" *ngIf="notifications">
    <div class="row">
      <div class="col-12">
        <app-notification-project [items]="notifications" [project]="project"></app-notification-project>
      </div>
    </div>
  </div>

  <div class="container-xl" *ngIf="project">
    <div class="row">
      <div class="col-12">
        <div class="card card-xl project-content">
          <div class="card-body">
            <app-project-base [project]="project"></app-project-base>
            <app-project-candidates [project]="project"></app-project-candidates>
            <app-project-briefing #briefing [project]="project"></app-project-briefing>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5">
        <div class="card card-xl project-content">
          <div class="card-body">
            <app-project-activities [project]="project"></app-project-activities>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<app-footer-blob></app-footer-blob>-->
